import { defineStore } from 'pinia';
import { NOTE, NOTE_DTO } from 'src/@types/note';
import { rocumentsApi } from 'src/boot/axios';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { i18n } from 'src/boot/i18n';
import { useCoreStore } from './core-store';

export const useNotesStore = defineStore('notes', () => {
  const route = useRoute();

  const notes = ref<NOTE[]>([]);
  const coreStore = useCoreStore();
  // currently use to distinguish on and offline
  const isConnected = computed(() => {
    return coreStore.isNetworkConnected;
  });
  const getNotes = async (documentId: string, topicId: string) => {
    if (route.meta.protected) {
      try {
        if (isConnected.value) {
          const { data } = await rocumentsApi.get<NOTE[]>(
            `/notes/${documentId}/${topicId}`
          );
          notes.value = data;
        }
      } catch (error) {
        apiErrorHandler(
          error,
          true,
          i18n.global.t('errors.failedToLoadArg', [
            i18n.global.t('entityTypes.notes'),
          ])
        );
      }
    }
  };

  const createNote = async (newNote: NOTE_DTO) => {
    try {
      const { status, data } = await rocumentsApi.post<NOTE>('/notes', newNote);
      if (status === 201) await getNotes(data.document, data.topic);
      else throw new Error('Could not create note');
    } catch (error) {
      apiErrorHandler(
        error,
        true,
        i18n.global.t('errors.failedToCreateArg', [
          i18n.global.t('entityTypes.note'),
        ])
      );
    }
  };

  const updateNote = async ({
    noteId,
    content,
  }: {
    noteId: string;
    content: string;
  }) => {
    try {
      const { data } = await rocumentsApi.patch<NOTE>(`/notes/${noteId}`, {
        content,
      });

      await getNotes(data.document, data.topic);
    } catch (error) {
      apiErrorHandler(
        error,
        true,
        i18n.global.t('errors.failedToUpdateArg', [
          i18n.global.t('entityTypes.note'),
        ])
      );
    }
  };
  const deleteNote = async (noteId: string) => {
    try {
      await rocumentsApi.delete(`/notes/${noteId}`);
      const noteIndex = notes.value.findIndex((note) => note._id === noteId);

      if (noteIndex > -1) {
        notes.value.splice(noteIndex, 1);
      }
    } catch (error) {
      apiErrorHandler(
        error,
        true,
        i18n.global.t('errors.failedToDeleteArg', [
          i18n.global.t('entityTypes.note'),
        ])
      );
    }
  };

  return {
    notes,
    getNotes,
    createNote,
    updateNote,
    deleteNote,
  };
});
