<template>
  <q-banner class="bg-accent text-white" inline-actions>
    {{ t('users.verifyEmail.emailNotVerified') }}
    <template #action>
      <q-btn
        :label="t('users.verifyEmail.resendLabel')"
        flat
        @click="onConfirm"
      />
    </template>
  </q-banner>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { popDialog } from 'src/utils/dialogs';

import { useUsersStore } from 'src/stores/users';
const { resendVerification } = useUsersStore();

const { t } = useI18n();
const onConfirm = async () =>
  popDialog({
    title: t('buttons.verifyEmail'),
    message: t('buttons.verifyEmailConfirm'),
    submit: t('buttons.ok'),
    cancel: '',
  }).onOk(async () => {
    await resendVerification();
  });
</script>
