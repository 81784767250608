import { BaseEntity, Column, Entity, PrimaryColumn } from 'typeorm';

@Entity()
export class Metadata extends BaseEntity {
  @PrimaryColumn({
    type: 'text',
    unique: true,
    nullable: false,
  })
  _id: string;

  @Column({ type: 'text', nullable: true })
  systemTitle: string;

  @Column({ type: 'text', nullable: true })
  title: string;

  @Column({ type: 'text', nullable: true })
  icon: string;

  @Column({ type: 'text', nullable: true })
  colour: string;

  @Column({ type: 'text', nullable: true })
  active: string;

  @Column({ type: 'text', nullable: true })
  avatar: string;

  @Column({ type: 'text', nullable: true })
  useImage: string;

  @Column({ type: 'text', nullable: true })
  createdAt: string;

  @Column({ type: 'text', nullable: true })
  updatedAt?: string;
}
