/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/en-GB.js'

import iconSet from 'quasar/icon-set/material-icons.js'



import {QList,LoadingBar,Notify,Dialog,LocalStorage,SessionStorage,Meta,Loading,Cookies,Dark} from 'quasar'



export default { config: {"brand":{"primary":"#02020a","secondary":"#2a2a2a","accent":"#cd192d","positive":"#21BA45","negative":"#C10015","info":"#31ccec","warning":"#F2C037"},"loadingBar":{"color":"accent"},"dark":false},lang,iconSet,components: {QList},plugins: {LoadingBar,Notify,Dialog,LocalStorage,SessionStorage,Meta,Loading,Cookies,Dark} }

