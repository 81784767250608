<template>
  <component :is="isMobileApp ? 'div' : QFooter">
    <div class="row q-col-gutter-md q-pa-md justify-center">
      <div class="col-xs-12 col-md-auto text-center">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        v{{ version }}
        {{
          t('copyright.label', [
            new Date().getFullYear(),
            company,
            'Coding Ketchup',
          ])
        }}
      </div>

      <div class="col-xs-auto col-md order-md-first">
        <router-link to="/contact">
          {{ t('contact.title') }}
        </router-link>
        <router-link class="q-ml-lg" to="/faq">
          {{ t('faq.featureTitle') }}
        </router-link>
      </div>

      <div
        :class="{ 'text-right': quasar.screen.gt.sm }"
        class="col-xs-auto col-md q-gutter-x-md"
      >
        <router-link v-if="terms" to="/terms">
          {{ t('terms.title') }}
        </router-link>
        <router-link v-if="privacyPolicy" to="/privacy-policy">
          {{ t('privacy.title') }}
        </router-link>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { version } from '../../package.json';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { QFooter, useQuasar } from 'quasar';
import { Theme } from 'src/boot/theme';
import { useAdminSettingsStore } from 'src/stores/admin-settings';
import { storeToRefs } from 'pinia';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';

const adminSettingsStore = useAdminSettingsStore();

const quasar = useQuasar();
const { t } = useI18n();
const { privacyPolicy, terms } = storeToRefs(adminSettingsStore);
const theme = inject<Theme>('$theme');
const company = theme?.style.branding.COMPANY_NAME;
const isMobileApp = checkIsMobileApp();
</script>
