import { AxiosError } from 'axios';
import { popToast } from 'src/utils/toasts';
import { i18n } from 'src/boot/i18n';

/**
 * Handles Axios errors and performs optional toast notifications.
 *
 * @param error - The error object to handle.
 * @param shouldToast - Optional flag indicating whether to display a toast notification. Default is false.
 * @param toastMessage - Optional message to display in the toast notification. Default is 'failed'.
 * @throws Throws the error object if it is not an instance of AxiosError.
 */
const apiErrorHandler = (
  error: unknown,
  shouldToast?: boolean,
  toastMessage?: string
) => {
  if (shouldToast) {
    popToast({
      type: 'negative',
      message: toastMessage || i18n.global.t('failed'),
    });
  }

  if (error instanceof AxiosError) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(`API Error ${error.response.status}`, {
        message: error.message,
        data: process.env.DEBUGGING && error.response.data,
        status: process.env.DEBUGGING && error.response.status,
        headers: process.env.DEBUGGING && error.response.headers,
      });
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error('API Error', {
        message: error.message,
        request: process.env.DEBUGGING && error.request,
      });
    } else if (error.code == '503') {
      console.error('Axios Offline');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw error;
    }
  } else {
    throw error;
  }
};

export default apiErrorHandler;
