import { defineStore } from 'pinia';
import { rocumentsApi } from 'src/boot/axios';
import { ref } from 'vue';
import apiErrorHandler from '../utils/exceptions/api-error-handler';
import { TERMS } from 'src/@types/terms';
import { CONTACT } from 'src/@types/contact';
import { COOKIE_POLICY } from 'src/@types/cookie-policy';
import { POLICY } from 'src/@types/policy';
import { popToast } from 'src/utils/toasts';
import { useI18n } from 'vue-i18n';
import { LANGUAGE_CODE } from 'src/@types/languages';
import { CURRENCY_CODE } from 'src/@types/currency-codes';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';

export type ExchangeRates = Partial<Record<CURRENCY_CODE, string>>;

export const useAdminSettingsStore = defineStore('adminSettings', () => {
  const router = useRouter();

  const terms = ref<TERMS | null>(null);
  const contact = ref<CONTACT | null>(null);
  const cookiePolicy = ref<COOKIE_POLICY | null>(null);
  const privacyPolicy = ref<POLICY | null>(null);
  const { t } = useI18n();

  const getTerms = async () => {
    try {
      const { data, status } = await rocumentsApi.get<TERMS>('/admin/terms');
      terms.value = data;
      return status;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const putTerms = async (payload: Partial<TERMS['content']>) => {
    try {
      await rocumentsApi.put('/admin/terms', {
        content: payload,
      });
      popToast('positive');
      return await getTerms();
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const getContact = async () => {
    try {
      const { data, status } = await rocumentsApi.get<CONTACT>(
        '/admin/contact-us'
      );
      contact.value = data;
      return status;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const putContact = async (payload: Partial<CONTACT['content']>) => {
    try {
      await rocumentsApi.put('/admin/contact-us', { content: payload });
      popToast('positive');
      return await getContact();
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const getCookiePolicy = async () => {
    try {
      const { data, status } = await rocumentsApi.get<COOKIE_POLICY>(
        '/admin/cookie-policy'
      );
      cookiePolicy.value = data;
      return status;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const putCookiePolicy = async (
    payload: Partial<COOKIE_POLICY['content']>
  ) => {
    try {
      await rocumentsApi.put<COOKIE_POLICY>('/admin/cookie-policy', {
        content: payload,
      });
      popToast('positive');
      return await getCookiePolicy();
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const getPrivacyPolicy = async () => {
    try {
      const { data, status } = await rocumentsApi.get<POLICY>(
        '/admin/privacy-policy'
      );
      privacyPolicy.value = data;
      return status;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const putPrivacyPolicy = async (payload: Partial<POLICY['content']>) => {
    try {
      await rocumentsApi.put<POLICY>('/admin/privacy-policy', {
        content: payload,
      });
      popToast('positive');
      return getPrivacyPolicy();
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.adminSettings')])
      );
    }
  };

  const languages = ref<LANGUAGE_CODE[]>([]);
  const getLanguages = async () => {
    try {
      const { data } = await rocumentsApi.get<LANGUAGE_CODE[]>(
        '/admin/setting-languages'
      );
      languages.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };
  const addLanguage = async (payload: { languages: LANGUAGE_CODE[] }) => {
    try {
      await rocumentsApi.patch('/admin/update-settings', payload);
      popToast('positive');
      return getLanguages();
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const currencies = ref<CURRENCY_CODE[]>([]);
  const getCurrencies = async () => {
    try {
      const { data } = await rocumentsApi.get<CURRENCY_CODE[]>(
        '/admin/setting-currencies'
      );
      currencies.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const defaultCurrency = ref<CURRENCY_CODE>();
  const getDefaultCurrency = async () => {
    try {
      const { data } = await rocumentsApi.get<CURRENCY_CODE>(
        '/admin/setting-default-currency'
      );
      defaultCurrency.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const addNewCurrency = async (payload: {
    currencies?: CURRENCY_CODE[];
    exchangeRates?: ExchangeRates;
    defaultCurrency?: CURRENCY_CODE;
  }) => {
    try {
      await rocumentsApi.patch('/admin/update-settings', payload);
      popToast('positive');
      await getCurrencies();
      await getDefaultCurrency();
      await getExchangeRates();
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const exchangeRates = ref<Partial<Record<CURRENCY_CODE, string>>>({});
  const getExchangeRates = async () => {
    try {
      const { data } = await rocumentsApi.get<
        Partial<Record<CURRENCY_CODE, string>>
      >('/admin/exchange-rates');
      exchangeRates.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };
  const updateExchangeRates = async (updates: ExchangeRates) => {
    try {
      await rocumentsApi.patch('/admin/update-settings', {
        exchangeRates: updates,
      });
      popToast('positive');
      return getExchangeRates();
    } catch (error) {
      apiErrorHandler(error, true);
    }
  };

  const dateFormat = ref<string>();
  const getDateFormat = async () => {
    try {
      const { data } = await rocumentsApi.get<string>('/admin/setting-date');
      dateFormat.value = data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          router.push('/maintenance');
        }
      }
    }
  };

  return {
    terms,
    contact,
    cookiePolicy,
    privacyPolicy,
    getTerms,
    putTerms,
    getContact,
    putContact,
    getCookiePolicy,
    putCookiePolicy,
    getPrivacyPolicy,
    putPrivacyPolicy,
    languages,
    getLanguages,
    currencies,
    getCurrencies,
    defaultCurrency,
    getDefaultCurrency,
    exchangeRates,
    getExchangeRates,
    updateExchangeRates,
    addNewCurrency,
    addLanguage,
    dateFormat,
    getDateFormat,
  };
});
