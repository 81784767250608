import { defineStore } from 'pinia';
import { PRODUCT } from 'src/@types/product';
import { PRODUCT_DETAIL } from 'src/@types/product-detail';
import { rocumentsApi } from 'src/boot/axios';
import { logger } from 'src/boot/logger';
import { Router } from 'vue-router';

export interface State {
  documents: any[];
  document: any;
  products: any[];
  product: PRODUCT;
  productDetail: PRODUCT_DETAIL;
  productOptions: Record<string, any[]>;
  wvta: any;
  modelSearchModelNames: any[];
  modelSearchModelYears: any[];
  modelSearchProductTypes: any[];
  modelSearchProductRanges: any[];
  documentTypes: any[];
  documentType: any;
  modelCodes: any[];
  features: any;
  productCount: 0;
  bulletins: any[];
  productDetailClashes: any[];
  documentTabs: any[];
  markets: any[];
  serialSearch: string | null;
  lang: string;
  market: string | null;
}

export const useHandbooksStore = defineStore('handbooks', {
  state: (): State => ({
    documents: [],
    document: {},
    products: [],
    product: {} as PRODUCT,
    productDetail: {} as PRODUCT_DETAIL,
    productOptions: {},
    wvta: {},
    modelSearchModelNames: [],
    modelSearchModelYears: [],
    modelSearchProductTypes: [],
    modelSearchProductRanges: [],
    documentTypes: [],
    documentType: {},
    modelCodes: [],
    features: {},
    productCount: 0,
    bulletins: [],
    productDetailClashes: [],
    documentTabs: [],
    markets: [],
    serialSearch: null,
    lang: '',
    market: null,
  }),
  actions: {
    getHandbookProductBySerial(payload: string) {
      return rocumentsApi
        .get(`/handbooks/products/search/${payload}`)
        .then((response) => {
          this.productDetail = response.data;
          return response;
        })
        .catch((err) => err.response);
    },
    async getSingleDetails(params: {
      modelCode: string;
      modelYear: string;
      serial?: string;
      serialNumber?: string;
      engineNo?: string;
      state?: string;
      onlyValid?: boolean;
      market?: string;
      lubricants?: string;
    }) {
      const { data } = await rocumentsApi.get(
        '/handbooks/single-product-details',
        {
          params,
        }
      );
      this.productDetail = data;
      return data;
    },
    getHandbookSingleProductDetail(query: string) {
      return rocumentsApi
        .get(`/handbooks/product-details/${query}`)
        .then((response) => {
          this.productDetail = response.data;
          return response.data;
        });
    },
    getHandbookDocumentTypes() {
      return rocumentsApi
        .get('/handbooks/document-types')
        .then((response) => {
          if (response.status === 200) {
            this.documentTypes = response.data;
            return response.data;
          }
        })
        .catch((err) => {
          logger('error', err);
        });
    },
    getHandbookSingleProduct({ router, id }: { id: string; router: Router }) {
      if (!id) return;
      return rocumentsApi
        .get(`/handbooks/products/${id}`)
        .then((response) => {
          this.product = response.data;
        })
        .catch((err) => {
          if (err.response.status === 404) {
            router.push('/404');
          }
        });
    },
    getHandbookWvta(featureCode: string) {
      return rocumentsApi
        .get('/handbooks/feature-codes/type-approval', {
          params: {
            featureCode,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.wvta = response.data;
          }
        });
    },
    getHandbookFeature(code: string) {
      return rocumentsApi
        .get('/handbooks/feature-codes', {
          params: {
            code,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.features[response.data.type] = response.data;
          }
        });
    },
    async getHandbookDocuments(params: {
      modelCode?: string;
      modelYear?: string;
      serial?: string;
      serialNumber?: string;
      engineNo?: string;
      state?: string;
      onlyValid?: boolean;
      market?: string;
      lubricants?: boolean;
      handbook?: boolean;
      tyres?: boolean;
    }) {
      const response = await rocumentsApi.get('/handbooks/documents', {
        params,
      });
      this.documents = response.data;
    },
    getProductOptions() {
      return rocumentsApi
        .get('/handbooks/system/product-options')
        .then((response) => {
          if (response.status === 200) {
            this.productOptions = response.data;
          }
        });
    },
    getModelNames({
      brand,
      productType,
      productRange,
    }: {
      brand?: string;
      productType?: string;
      productRange?: string;
    }) {
      return rocumentsApi
        .get('/handbooks/products/model-names', {
          params: {
            brand,
            productType,
            productRange,
          },
        })
        .then((response) => {
          this.modelSearchModelNames = response.data.filter(Boolean);
          return response;
        });
    },
    getModelYears(modelName: string) {
      return rocumentsApi
        .get('/handbooks/products/model-years', {
          params: {
            modelName,
          },
        })
        .then((response) => {
          this.modelSearchModelYears = response.data;
          return response;
        })
        .catch((err) => {
          logger('error', err);
        });
    },
    getProductTypes(brand: string | null) {
      return rocumentsApi
        .get('/handbooks/products/product-types', {
          params: {
            brand,
          },
        })
        .then((response) => {
          this.modelSearchProductTypes = response.data.filter(Boolean);
          return response;
        });
    },
    getProductRanges(brand?: string, productType?: string) {
      return rocumentsApi
        .get('/handbooks/products/product-ranges', {
          params: {
            brand,
            productType,
          },
        })
        .then((response) => {
          this.modelSearchProductRanges = response.data || null;
          return response;
        });
    },
  },
});
