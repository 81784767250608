<template>
  <q-layout :view="quasar.screen.lt.sm ? 'hHh Lpr fff' : 'hHh Lpr fFf'">
    <q-header elevated class="print-hide" :reveal="checkIsMobileApp()">
      <HeaderMenuHandbooks />
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
    <Footer />
  </q-layout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import HeaderMenuHandbooks from 'components/ui/HeaderMenuHandbook.vue';
import { useHandbooksStore } from 'src/stores/handbooks';
import { useQuasar } from 'quasar';
import Footer from './MainFooter.vue';
import checkIsMobileApp from '../utils/checkIsMobileApp';

const handbooksStore = useHandbooksStore();
const quasar = useQuasar();

onMounted(async () => {
  await handbooksStore.getProductOptions();
});
</script>
