<template>
  <q-layout :view="$q.screen.lt.sm ? 'hHh Lpr fff' : 'hHh Lpr fFf'">
    <q-header elevated class="print-hide" :reveal="checkIsMobileApp()">
      <HeaderMenuHandbooks />
      <TopicNav
        v-if="
          currentDocument?.format === 'application/json' &&
          route.name &&
          ['tyresDocViewer', 'fluidsDocViewer'].includes(route.name.toString())
        "
      />
    </q-header>
    <TocSidebar
      v-if="
        currentDocument?.format === 'application/json' &&
        route.name &&
        ['tyresDocViewer', 'fluidsDocViewer'].includes(route.name.toString())
      "
      class="print-hide"
      @toggle-sidebar="onToggleSideBar"
    />

    <q-page-container>
      <router-view />
    </q-page-container>
    <Footer />
  </q-layout>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import HeaderMenuHandbooks from 'components/ui/HeaderMenuHandbook.vue';
import { useHandbooksStore } from 'src/stores/handbooks';
import Footer from './MainFooter.vue';
import TopicNav from 'src/components/ui/TopicNav.vue';
import TocSidebar from 'src/components/document/TocSidebar.vue';
import { useRoute } from 'vue-router';
import { useDocumentViewerStore } from 'src/stores/document-viewer';
import { storeToRefs } from 'pinia';
import checkIsMobileApp from '../utils/checkIsMobileApp';

const handbookStore = useHandbooksStore();
const documentViewerStore = useDocumentViewerStore();
const { currentDocument } = storeToRefs(documentViewerStore);
const route = useRoute();

onMounted(async () => {
  await handbookStore.getProductOptions();
});
const showSidebar = ref<boolean>(false);

const onToggleSideBar = () => {
  showSidebar.value = !showSidebar.value;
};
</script>
