import { cloneDeep, intersection } from 'lodash';
import { TocItem } from '../@types/document';
import { PRODUCT } from '../@types/product';
import { PRODUCT_DETAIL } from '../@types/product-detail';
import { useMarketsStore } from 'src/stores/markets';
import { storeToRefs } from 'pinia';
import { ComputedRef, Ref, ref, toValue } from 'vue';
import { filterDeep } from 'deepdash-es/standalone';
import { CONTENT_NODE } from 'src/@types/topic';
import { watchEffect } from 'vue';
const metaMapping: Pick<
  PRODUCT,
  'engineNo' | 'serialNumberStub' | 'modelCode' | 'marketSpec' | 'modelYear'
> = {
  engineNo: process.env.META_ENGINE_RANGE || '',
  serialNumberStub: process.env.META_SERIAL_RANGE || '',
  modelCode: process.env.META_PRODUCTS || '',
  marketSpec: process.env.META_MARKETS || '',
  modelYear: process.env.META_MODEL_YEARS || '',
};

const allKey = process.env.IGNORE_META_VALS;
const rangeRegEx = /^[A-Za-z0-9\s]*-[A-Za-z0-9\s]*$/;

type treeToFilter =
  | Ref<TocItem[] | undefined>
  | Ref<CONTENT_NODE[] | undefined>
  | CONTENT_NODE[]
  | ComputedRef<TocItem[] | undefined>
  | ComputedRef<CONTENT_NODE[] | undefined>
  | undefined;

export function useTocFilter(
  tree?: treeToFilter,

  productContext?:
    | ComputedRef<(PRODUCT & PRODUCT_DETAIL) | undefined>
    | Ref<(PRODUCT & PRODUCT_DETAIL) | undefined>
    | (PRODUCT & PRODUCT_DETAIL)
    | undefined,
  childKey = 'children'
) {
  const marketsStore = useMarketsStore();
  const { markets } = storeToRefs(marketsStore);

  const isRange = (value: string) => rangeRegEx.test(value);

  const inRange = (val: string | number, range: string) => {
    if (!val) return true;
    const parts = range.split('-');
    const openVals = ['', ' '];
    const openStartVal = typeof val === 'string' ? '000000' : 0;
    const openEndVal = typeof val === 'string' ? 'zzzzzz' : Infinity;
    const start = openVals.includes(parts[0]) ? openStartVal : parts[0];
    const end = openVals.includes(parts[1]) ? openEndVal : parts[1];

    if (
      typeof val === 'string' &&
      typeof start === 'string' &&
      typeof end === 'string'
    ) {
      return (
        val.localeCompare(start, 'en', { sensitivity: 'base' }) >= 0 &&
        val.localeCompare(end, 'en', { sensitivity: 'base' }) <= 0
      );
    } else {
      return val >= start && val <= end;
    }
  };

  const checkRangeMeta = (
    tocItem: TocItem | CONTENT_NODE,
    metaKey: string,
    value?: string | number
  ) => {
    if (!value) return true;
    // If item does not have metadata then it should be included
    const meta = tocItem.metadata?.[metaKey];
    if (!meta) return true;

    // Split the metadata string into an array of possible ranges
    const matchesArray = meta.split('; ').map((x: string) => x.trim());

    // Check each match to see if there is a match
    for (const match of matchesArray) {
      if (isRange(match)) {
        return inRange(value.toString(), match);
      } else if (match === allKey) {
        return true;
      } else if (matchesArray.includes(value)) {
        return true;
      }
      return false;
    }
  };

  const checkSimpleMeta = (
    tocItem: TocItem | CONTENT_NODE,
    metaKey: string,
    value?: string | number
  ) => {
    if (!value) return true;
    const metaVal = tocItem.metadata?.[metaKey];
    if (!metaVal) return true;
    const metaArray = metaVal.split('; ').map((x: string) => x.trim());
    if (metaArray.includes(allKey)) {
      return true;
    }
    if (metaArray.includes(value.toString())) {
      return true;
    }
    return false;
  };

  const checkMarket = (tocItem: TocItem | CONTENT_NODE, market?: string) => {
    if (!market) return true;
    if (!tocItem.metadata?.[metaMapping.marketSpec]) return true;
    const marketsArray = tocItem.metadata[metaMapping.marketSpec]
      .split('; ')
      .map((x: string) => x.trim());
    // If content is specifically marked as "All" then it should be included
    if (marketsArray.includes(allKey)) {
      return true;
    }

    // check that node markets includes the product markets
    const productDocumentMarkets = markets.value?.find(
      (x) => x.name === market
    )?.subMarkets;

    if (intersection(marketsArray, productDocumentMarkets).length) {
      return true;
    }
    return false;
  };
  const checkEngineNo = (
    tocItem: TocItem | CONTENT_NODE,
    engineNo?: string | number
  ) => {
    return checkRangeMeta(tocItem, metaMapping.engineNo, engineNo);
  };
  const checkSerial = (
    tocItem: TocItem | CONTENT_NODE,
    serial?: string | number
  ) => {
    return checkRangeMeta(tocItem, metaMapping.serialNumberStub, serial);
  };
  const checkModelCode = (
    tocItem: TocItem | CONTENT_NODE,
    modelCode?: string
  ) => {
    return checkSimpleMeta(tocItem, metaMapping.modelCode, modelCode);
  };
  const checkModelYear = (
    tocItem: TocItem | CONTENT_NODE,
    modelYear?: string | number
  ) => {
    return checkRangeMeta(tocItem, metaMapping.modelYear, modelYear);
  };

  const filteredTree = ref<TocItem[]>([]);

  const doTraversal = (
    structureCopy: TocItem[] | CONTENT_NODE[] | undefined
  ) => {
    const result = filterDeep(
      structureCopy,
      (value) => {
        if (!value.metadata) return true;

        const validKeys = [];
        for (const [metaKey, _mappedValue] of Object.entries(metaMapping)) {
          // TODO Make more generic for any kind of metadata - possible apart from markets currently
          // iterate over possible metadata and proceed if the item has that meta
          if (!value.metadata?.hasOwnProperty(_mappedValue)) continue;
          if (!toValue(productContext)?.hasOwnProperty(metaKey)) continue;
          switch (metaKey) {
            case 'marketSpec':
              validKeys.push(
                // @ts-expect-error : because typescript doesn't hand toValue properly
                checkMarket(value, toValue(productContext)[metaKey])
              );
              break;
            case 'engineNo':
              validKeys.push(
                checkEngineNo(value, toValue(productContext)?.[metaKey])
              );
              break;

            case 'serialNumberStub':
              validKeys.push(
                checkSerial(value, toValue(productContext)?.[metaKey])
              );
              break;

            case 'modelCode':
              validKeys.push(
                checkModelCode(value, toValue(productContext)?.[metaKey])
              );
              break;

            case 'modelYear':
              validKeys.push(
                checkModelYear(value, toValue(productContext)?.[metaKey])
              );
              break;
          }
        }
        if (!validKeys.length) return true;
        if (!validKeys.every((x) => x === true)) {
          //check the product has a valid option and filter out if not
          return false;
        }
        return true;
      },
      { leavesOnly: false, childrenPath: [childKey] }
    );

    filteredTree.value = result;
  };

  watchEffect(() => {
    const treeValue = toValue<CONTENT_NODE[] | TocItem[] | undefined>(tree);
    if (!treeValue) return;
    const structureCopy = cloneDeep(treeValue);
    doTraversal(structureCopy);
  });

  return {
    filteredTree,
    checkMarket,
    checkEngineNo,
    checkSerial,
    checkModelCode,
    checkModelYear,
  };
}
