import { DataSource } from 'typeorm';
import {
  CapacitorSQLite,
  SQLiteConnection,
} from '../../src-capacitor/node_modules/@capacitor-community/sqlite';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
import migrations from './migrations';
import { ISQLiteEntity, entitiesArr, entitiesObj } from './entities';

export let dataSource: DataSource;
export interface IDbObject {
  dataSource: DataSource | null;
  entities: {
    [key: string]: ISQLiteEntity;
  };
}

export const connectDb = async () => {
  let db: null | IDbObject = null;

  if (!checkIsMobileApp()) {
    return db;
  }

  db = {
    dataSource: null,
    entities: {},
  };

  const sqliteConnection = new SQLiteConnection(CapacitorSQLite);
  await sqliteConnection.checkConnectionsConsistency();

  db.dataSource = new DataSource({
    name: 'rocuments-data-source',
    type: 'capacitor',
    driver: sqliteConnection,
    database: 'rocuments',
    entities: entitiesArr,
    // runs by the order of timestamps
    migrations,
    logging: false,
    migrationsRun: true,
  });

  db.entities = entitiesObj;

  await db.dataSource.initialize();

  return db;
};
