import { defineStore, storeToRefs } from 'pinia';
import { rocumentsApi } from 'src/boot/axios';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { i18n } from 'src/boot/i18n';
import { DOCUMENT } from 'src/@types/document';
import { DOCUMENT_TYPE } from 'src/@types/document-type';
import { AxiosError } from 'axios';
import { popToast } from 'src/utils/toasts';
import { ref, watch } from 'vue';
import { IMAGE } from 'src/@types/image';
import { TOPIC } from 'src/@types/topic';
import { useOfflineDocumentActions } from 'src/sqlite-database/use-offline-document-actions';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
import { useOfflineProductActions } from 'src/sqlite-database/offline-product-actions';
import { PRODUCT } from 'src/@types/product';
import { PRODUCT_DETAIL } from 'src/@types/product-detail';
import { useDocumentTypesStore } from './document-types';
import { useDocumentTabsStore } from 'src/stores/document-tabs';
import * as zip from '@zip.js/zip.js';

const { t } = i18n.global;

export interface IDocumentDownloadQueueItem {
  id: string;
  title: string;
  productId: string;
  inProgress?: boolean;
  isUpdate?: boolean;
  cancelRequest: () => void;
}

export const useDocumentStore = defineStore('documents', () => {
  const documents = ref<DOCUMENT[]>([]);
  const offlineDocuments = ref<DOCUMENT[]>([]);
  const activeProduct = ref<PRODUCT | PRODUCT_DETAIL>();
  const currentDoc = ref<DOCUMENT | null>(null);
  const docsByType = ref<{ [key: string]: DOCUMENT[] }>({});
  const bulkDocuments = ref<DOCUMENT[]>([]);
  const docsByTypeSearch = ref<DOCUMENT[]>([]);
  const documentsInDownloadQueue = ref<Array<IDocumentDownloadQueueItem>>([]);
  const documentTypesStore = useDocumentTypesStore();
  const { documentTypes } = storeToRefs(documentTypesStore);
  const offlineDocumentActions = useOfflineDocumentActions();
  const documentTabsStore = useDocumentTabsStore();
  const { documentTabs } = storeToRefs(documentTabsStore);
  const offlineProductActions = useOfflineProductActions();

  const removeDocFromDownloadQueue = (id: string) => {
    documentsInDownloadQueue.value = documentsInDownloadQueue.value.filter(
      (doc) => doc.id !== id
    );
  };

  const addDocToDownloadQueue = async (
    documentId: string,
    documentTitle: string,
    productId: string,
    isUpdate?: boolean
  ) => {
    const isDocumentInDownloadQueue = documentsInDownloadQueue.value.find(
      (doc) => doc.id === documentId
    );

    if (isDocumentInDownloadQueue) {
      popToast({
        type: 'negative',
        message: i18n.global.t('errors.downloadAlreadyInProgress'),
      });

      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const cancelRequestFallback = () => {};

    // we replace the array so watcher could recognize changes
    documentsInDownloadQueue.value = [
      ...documentsInDownloadQueue.value,
      {
        id: documentId,
        title: documentTitle,
        productId,
        isUpdate,
        cancelRequest: cancelRequestFallback,
      },
    ];
  };

  const downloadDocument = async (
    productId: string,
    documentId: string,
    isUpdate: boolean | undefined,
    requestHoistCallback: (cancelRequestCallback: () => void) => void
  ) => {
    try {
      const axiosController = new AbortController();

      requestHoistCallback(() => {
        axiosController.abort();
      });

      const downloadRes = await rocumentsApi.get(
        `/documents/download/offline/${productId}/${documentId}`,
        { signal: axiosController.signal, responseType: 'blob' }
      );

      const dataRaw = downloadRes?.data;

      const reader = new zip.ZipReader(new zip.BlobReader(dataRaw));
      const entries = await reader.getEntries();

      const data: {
        documents: DOCUMENT[];
        topics: TOPIC[];
        images: IMAGE[];
        product?: PRODUCT;
        productDetails?: PRODUCT_DETAIL;
        downloadedAt: string;
      } = {
        documents: [],
        topics: [],
        images: [],
        product: undefined,
        productDetails: undefined,
        downloadedAt: '',
      };

      for (const entry of entries) {
        if (entry?.filename) {
          if (typeof entry.getData === 'function') {
            if (entry.filename.includes('document')) {
              const temp = await entry.getData(new zip.TextWriter());
              data.documents = JSON.parse(temp);
            } else if (entry.filename.includes('topics')) {
              const temp = await entry.getData(new zip.TextWriter());
              data.topics.push(JSON.parse(temp));
            } else if (entry.filename.includes('images')) {
              const temp = await entry.getData(new zip.TextWriter());
              data.images.push(JSON.parse(temp));
            } else if (entry.filename.includes('product')) {
              const temp = (await entry.getData(new zip.TextWriter())) || '';
              data.product = JSON.parse(temp);
            } else if (entry.filename.includes('productDetails')) {
              const temp = (await entry.getData(new zip.TextWriter())) || '';
              data.productDetails = JSON.parse(temp);
            } else if (entry.filename.includes('downloadedAt')) {
              const temp = await entry.getData(new zip.TextWriter());
              data.downloadedAt = JSON.parse(temp);
            }
          } else {
            console.error('getData is not a function on entry', entry);
          }
        }
      }

      if (!data?.product && !data?.productDetails) {
        throw new Error(
          'No product or product details found for downloaded document. Aborting'
        );
      }

      await Promise.all(
        data.documents.map((doc: DOCUMENT) => {
          const docPayload: {
            document: DOCUMENT; // DEV extend the type
            images: IMAGE[];
            topics: TOPIC[];
          } = {
            document: {
              ...doc,
              lastSyncedAt: new Date().toISOString(),
            },
            images: [],
            topics: [],
          };

          if (doc._id === documentId) {
            docPayload.images = data.images as unknown as IMAGE[];
            docPayload.topics = data.topics as unknown as TOPIC[];
          }

          return offlineDocumentActions.upsertOfflineDocumentData(docPayload);
        })
      );

      // we need to explicitly load them here since documents
      // can be redownloaded (updated) from elsewhere, not just
      // from product page which normally loads all this data
      await documentTabsStore.loadDocumentTabs();
      await documentTypesStore.loadDocumentTypes();

      await offlineProductActions.upsertOfflineProduct(
        data.product,
        data.productDetails,
        documents.value,
        documentTypes.value,
        documentTabs.value
      );

      await reader.close();
    } catch (err) {
      removeDocFromDownloadQueue(documentId);

      // we let users update documents and cancel download,
      // while retaining the original document
      if (!isUpdate) {
        // TODO delete product as well if no downloaded documents exist for it
        try {
          await offlineDocumentActions.deleteOfflineDocumentDataById(
            documentId
          );
        } catch (cleanupError) {
          popToast({
            type: 'negative',
            message: t('errors.downloadCleanupFailed'),
          });
        }
      }

      if ((err as AxiosError)?.code === 'ERR_CANCELED') {
        popToast({
          type: 'positive',
          message: t('downloadCanceledSuccessfully'),
        });
      } else {
        popToast({
          type: 'negative',
          message: t('errors.downloadFailed'),
        });
      }
    }

    removeDocFromDownloadQueue(documentId);
  };

  watch(
    () => documentsInDownloadQueue.value,
    () => {
      documentsInDownloadQueue.value.forEach((doc) => {
        if (!doc.inProgress) {
          doc.inProgress = true;
          downloadDocument(
            doc.productId,
            doc.id,
            doc.isUpdate,
            (cancelRequestCallback: () => void) => {
              doc.cancelRequest = cancelRequestCallback;
            }
          );
        }
      });
    }
  );

  const getAllDocuments = async (): Promise<boolean | undefined> => {
    try {
      const { data } = await rocumentsApi.get('/documents');
      documents.value = data;
      return true;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.documents')])
      );
    }
  };

  const loadProductDocuments = async (payload: {
    modelCode?: string;
    modelYear?: string | number;
    serial?: string;
    engineNo?: string;
    state: string;
    onlyValid: boolean;
    market: string;
  }) => {
    try {
      const { data } = await rocumentsApi.get('/documents', {
        params: payload,
      });
      documents.value = data;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.documents')])
      );
    }
  };

  // const loadActiveProductOfflineDocumentStubs = () => {};

  const loadActiveProductOfflineDocuments = async () => {
    try {
      if (!activeProduct?.value?._id) {
        offlineDocuments.value = [];
        return;
      }

      offlineDocuments.value = (
        await offlineDocumentActions.getOfflineDocuments()
      ).filter((doc) => true && doc);
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.offlineDocuments', [t('entityTypes.documents')])
      );
    }
  };

  const getSingleDocument = async ({
    _id,
  }: {
    _id: string;
  }): Promise<boolean | undefined> => {
    try {
      const { data } = await rocumentsApi.get(`/documents/${_id}`);
      currentDoc.value = data;
      return true;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.document')])
      );
    }
  };

  const getDocumentsByType = async (
    docType: string
  ): Promise<boolean | undefined> => {
    try {
      const { data } = await rocumentsApi.get(`/documents/type/${docType}`, {
        params: {
          group: 'metadata.reference',
        },
      });

      docsByType.value = {
        ...docsByType.value,
        [docType]: data,
      };

      return true;
    } catch (err: unknown | AxiosError) {
      if ((err as AxiosError)?.response?.status === 404) {
        return;
      }

      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.documents')])
      );
    }
  };

  const getDocumentsByTypeAndRef = async (payload: {
    type: string;
    ref: string;
  }): Promise<DOCUMENT[] | undefined> => {
    try {
      const { data } = await rocumentsApi.get(
        `/documents/type/${payload.type}`,
        {
          params: {
            group: 'metadata.reference',
            ref: payload.ref,
          },
        }
      );

      docsByType.value = {
        ...docsByType.value,
        [payload.type]: data,
      };

      return data;
    } catch (err: unknown | AxiosError) {
      if ((err as AxiosError)?.status === 404) {
        return;
      }

      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.documents')])
      );
    }
  };

  const createDocument = async ({
    _id,
  }: {
    _id: string;
  }): Promise<boolean | undefined> => {
    try {
      const { data } = await rocumentsApi.get(`/documents/${_id}`);
      currentDoc.value = data;
      return true;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.document')])
      );
    }
  };

  const updateDocument = async (payload: {
    _id: string;
    externalId: string;
    format: string;
    language: string;
    state: string;
    title: string;
    metadata: any;
  }): Promise<boolean | undefined> => {
    try {
      await rocumentsApi.patch(`/documents/${payload._id}`, payload);
      await getAllDocuments();
      return true;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToUpdateArg', [t('entityTypes.document')])
      );
    }
  };

  const updateBulkDocuments = async (
    docs: Partial<DOCUMENT>[]
  ): Promise<boolean | undefined> => {
    try {
      Promise.all(
        docs.map(
          async (doc) => await rocumentsApi.patch(`/documents/${doc._id}`, doc)
        )
      );

      return true;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToUpdateArg', [t('entityTypes.documents')])
      );
    }
  };

  const deleteDocument = async (
    documentId: string
  ): Promise<boolean | undefined> => {
    try {
      await rocumentsApi.delete(`/documents/${documentId}`);
      await getAllDocuments();
      return true;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToDeleteArg', [t('entityTypes.document')])
      );
    }
  };

  const searchDocumentsByType = async ({
    query,
    docType,
  }: {
    query: string;
    docType?: DOCUMENT_TYPE[] | null;
  }) => {
    try {
      const { data } = await rocumentsApi.get('/documents/topics/search-type', {
        params: {
          type: docType,
          query,
        },
      });

      docsByTypeSearch.value = data;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.documents')])
      );
    }
  };

  // update active product offline documents
  watch(
    () => activeProduct?.value,
    () => {
      if (checkIsMobileApp()) {
        loadActiveProductOfflineDocuments();
      }
    }
  );

  return {
    documents,
    document: currentDoc,
    docsByType,
    bulkDocuments,
    docsByTypeSearch,
    documentsInDownloadQueue,
    offlineDocuments,
    activeProduct,
    removeDocFromDownloadQueue,
    addDocToDownloadQueue,
    downloadDocument,
    getAllDocuments,
    loadProductDocuments,
    loadActiveProductOfflineDocuments,
    getSingleDocument,
    getDocumentsByType,
    getDocumentsByTypeAndRef,
    createDocument,
    updateDocument,
    updateBulkDocuments,
    deleteDocument,
    searchDocumentsByType,
  };
});
