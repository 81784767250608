<!-- eslint-disable vue/no-v-html -->
<template>
  <q-dialog v-model="showing" seamless position="bottom" full-width>
    <q-card class="bg-accent">
      <q-card-section
        class="row items-center justify-around q-col-gutter-md q-mx-md-xl"
      >
        <div class="col-xs-12 col-md">
          <div class="text-weight-bold">
            {{ t('cookieNotice.title') }}
          </div>
          <div class="text-white">
            <span v-html="t('cookieNotice.body[0]')" />
            <span v-html="t('cookieNotice.body[1]', [t('buttons.accept')])" />
            <span v-html="t('cookieNotice.body[2]')" />
          </div>
        </div>

        <div class="col-xs-12 col-md-auto">
          <q-btn
            :label="t('buttons.accept')"
            unelevated
            color="positive"
            @click="onClick"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const showing = ref(false);

const onClick = () => {
  showing.value = false;
  localStorage.setItem('ROCUMENTS.cookieConsent', 'true');
};

onMounted(() => {
  const cookieExists = localStorage.getItem('ROCUMENTS.cookieConsent');

  if (!cookieExists) {
    showing.value = true;
  }
});
</script>
