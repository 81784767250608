<template>
  <div
    class="bg-black text-white text-weight-thin flex flex-center"
    style="width: 100px; height: 100px"
  >
    <div>
      <img :src="getThemedAssetPath('loading.gif')" class="block q-mb-sm" />
      {{ quasar.lang.table.loading }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQuasar } from 'quasar';
import { getThemedAssetPath } from 'src/themes/index';

const quasar = useQuasar();
</script>
