import { defineStore } from 'pinia';
import { DOCUMENT } from 'src/@types/document';
import { rocumentsApi } from 'src/boot/axios';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { ref } from 'vue';
import { i18n } from 'src/boot/i18n';

export interface HIT {
  highlight: {
    'parentDocument._id': string[];
    search: string[];
  };
  hit: {
    id: string;
    parentDocument: Partial<DOCUMENT>;
    title: string;
    metadata?: Record<string, string>;
  };
  score: number;
}

export const useSearchStore = defineStore('search', () => {
  const documentHits = ref<HIT[]>([]);
  const libraryHits = ref<HIT[]>([]);
  const searchQuery = ref('');

  const searchDocument = async (query: string, documentId: string) => {
    try {
      const { data } = await rocumentsApi.get<HIT[]>(
        `/documents/topics/${documentId}/search`,
        {
          params: { query },
        }
      );

      documentHits.value = data;
    } catch (error) {
      apiErrorHandler(error, true, i18n.global.t('errors.searchFailed'));
    }
  };

  const searchLibrary = async (query: string) => {
    try {
      const { data } = await rocumentsApi.get<HIT[]>(
        '/documents/topics/search-all',
        {
          params: { query },
        }
      );

      libraryHits.value = data;
    } catch (error) {
      apiErrorHandler(error, true, i18n.global.t('errors.librarySearchFailed'));
    }
  };

  return {
    documentHits,
    libraryHits,
    searchQuery,
    searchDocument,
    searchLibrary,
  };
});
