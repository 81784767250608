<template>
  <q-drawer
    v-model="showing"
    :breakpoint="500"
    side="left"
    bordered
    show-if-above
  >
    <q-tree
      v-if="props.tree.length"
      ref="tocTree"
      v-model:selected="selected"
      v-model:expanded="expanded"
      :nodes="props.tree"
      node-key="dest"
      label-key="title"
      selected-color="accent"
      children-key="items"
      @update:selected="onTopicSelected"
    />
  </q-drawer>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, inject, watch } from 'vue';
import { useQuasar, QTreeNode, EventBus } from 'quasar';
import { useRoute } from 'vue-router';

const $q = useQuasar();
const eventBus: EventBus | undefined = inject('event-bus');
const route = useRoute();
const props = defineProps<{
  tree: QTreeNode[];
}>();

const showing = ref(false);
const selected = ref<string[] | string>([]);
const expanded = ref([]);

const emit = defineEmits(['select']);

const toggleDrawer = () => {
  showing.value = !showing.value;
};

const onTopicSelected = (node: QTreeNode) => {
  if ($q.screen.lt.sm) {
    showing.value = false;
  }
  emit('select', node);
};

onMounted(() => {
  eventBus?.on('toggleAdminSideBar', toggleDrawer);
});

onBeforeUnmount(() => {
  eventBus?.off('toggleAdminSideBar');
});

watch(
  () => route,
  (newRoute) => {
    if (newRoute.query.pdfDest) {
      const dest = newRoute.query.pdfDest as string;
      selected.value = dest;
    }
  },
  {
    deep: true,
  }
);
</script>
