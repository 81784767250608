<template>
  <q-banner
    v-if="props.userType === 'independent_operator'"
    class="bg-accent text-white"
  >
    {{ daysLeftComp }}
  </q-banner>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { ORG_TYPE_TYPE } from 'src/@types/organisation-type';

const props = withDefaults(
  defineProps<{
    userType: ORG_TYPE_TYPE | null;
    expSubscTime: number;
  }>(),
  {
    userType: ORG_TYPE_TYPE.IO,
    expSubscTime: 800,
  }
);

const i18n = useI18n();

const daysLeftComp = computed(() => {
  const number = props.expSubscTime;

  if (number === 100) {
    return `${number / 100} ${i18n.t('subscriptions.expireTime.day')}`;
  } else if (number > 100 && number < 700) {
    return `${number / 100} ${i18n.t('subscriptions.expireTime.days')}`;
  } else if (number > 0 && number < 100) {
    return i18n.t('subscriptions.expireTime.lessThenaDay');
  } else {
    return i18n.t('subscriptions.expireTime.noSubs');
  }
});
</script>
