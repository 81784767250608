<template>
  <div class="relative-position">
    <q-input
      ref="inputComponent"
      v-model="searchQuery"
      :label="t('buttons.search')"
      :bg-color="bgColor"
      type="search"
      dense
      clearable
      :class="disabled ? 'dimmed disabled' : ''"
      :disable="disabled"
      @keypress.enter="onSearch(searchQuery)"
      @clear="onClear"
    >
      <template #prepend>
        <q-spinner-gears v-if="loading" color="primary" />
        <q-icon v-else name="fad fa-search" />
      </template>
      <SearchResultsDialog :open="dialogOpen" @close="dialogOpen = false" />
    </q-input>
  </div>
</template>

<script setup lang="ts">
import { QInput } from 'quasar';
import { ref } from 'vue';
import SearchResultsDialog from 'src/components/document/SearchResultsDialog.vue';
import { useI18n } from 'vue-i18n';
import { useSearchStore } from 'src/stores/search';
import { storeToRefs } from 'pinia';

const { t } = useI18n();

const props = defineProps<{
  bgColor: string;
  parentDoc: string;
  disabled?: boolean;
}>();

const loading = ref(false);
const dialogOpen = ref(false);
const inputComponent = ref<QInput | null>(null);

const searchStore = useSearchStore();
const { searchQuery } = storeToRefs(searchStore);
const { searchDocument } = searchStore;

const onClear = () =>
  searchStore.$patch({
    searchQuery: '',
  });

const onSearch = async (queryString: string) => {
  loading.value = true;
  await searchDocument(queryString, props.parentDoc);
  dialogOpen.value = true;
  loading.value = false;
  inputComponent.value?.blur();
};
</script>
