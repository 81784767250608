<template>
  <router-view />
  <CookiePrompt v-if="!checkIsMobileApp()" />
</template>

<script setup lang="ts">
import { inject, onMounted, provide } from 'vue';
import { useCoreStore } from 'src/stores/core-store';
import toggleLoading from 'src/utils/toggleLoading';
import { useMeta, useQuasar } from 'quasar';
import { useBrowserLangFormat } from 'src/composables/useBrowserLangFormat';
import { Theme } from './boot/theme';
import CookiePrompt from './components/ui/CookiePrompt.vue';
import { LoadingBar } from 'quasar';
import { storeToRefs } from 'pinia';
import { useAdminSettingsStore } from 'src/stores/admin-settings';
import getServerUrl from 'src/utils/getServerUrl';
import checkIsMobileApp from './utils/checkIsMobileApp';
import { StatusBar } from '@capacitor/status-bar';

const adminSettingsStore = useAdminSettingsStore();

const theme = inject<Theme>('$theme');

if (checkIsMobileApp()) {
  theme &&
    StatusBar.setBackgroundColor({ color: theme.style.colors.BRAND_PRIMARY });
}

useMeta({ title: theme?.style.branding.PRODUCT_TITLE });

const coreStore = useCoreStore();
const { auth } = storeToRefs(coreStore);
const quasar = useQuasar();
const { onSelectLanguage } = useBrowserLangFormat();
provide('auth', auth);

const loadCoreData = async () => {
  await Promise.all([
    adminSettingsStore.getDateFormat(),
    adminSettingsStore.getTerms(),
    adminSettingsStore.getContact(),
    adminSettingsStore.getCookiePolicy(),
    adminSettingsStore.getPrivacyPolicy(),
    adminSettingsStore.getLanguages(),
    adminSettingsStore.getCurrencies(),
    adminSettingsStore.getDefaultCurrency(),
    adminSettingsStore.getExchangeRates(),
    coreStore.getVersion(),
  ]);
};

onMounted(async () => {
  // 12-Sep-2024: Added to clean up mobile auth issue on web
  if (!checkIsMobileApp()) {
    quasar.localStorage.remove('CapacitorStorage.announcements-announcements');
    quasar.localStorage.remove('CapacitorStorage.localUsers');
    quasar.localStorage.remove('CapacitorStorage.notifications-notifications');
    quasar.localStorage.remove('CapacitorStorage.faqs-faqs');
    quasar.localStorage.remove('CapacitorStorage.cachedEndpoints');
  }

  await toggleLoading(true);
  await loadCoreData();
  await toggleLoading(false);

  const lang = quasar.cookies.get('ROCUMENTS.langPref');

  if (lang) {
    return onSelectLanguage(lang);
  }
});

LoadingBar.setDefaults({
  // return a Boolean which has the meaning of
  // "does this URL should trigger LoadingBar?"
  hijackFilter(url) {
    // Ignore Auth fetches for AJAX BAR
    const urlRegEx = new RegExp(
      `${getServerUrl().replace(
        '/',
        '\/'
      )}\/(?!auth|notifications|tracking|test-network)`
    );
    return urlRegEx.test(url);
  },
});
</script>
