import { PRODUCT } from 'src/@types/product';
import { Product } from './entities/product';
import { PRODUCT_DETAIL } from 'src/@types/product-detail';
import { DOCUMENT } from 'src/@types/document';
import { DOCUMENT_TYPE } from 'src/@types/document-type';
import { DOCUMENT_TAB } from 'src/@types/document-tab';
import { useOfflineDocumentActions } from './use-offline-document-actions';

export interface IOfflineProductData {
  product: PRODUCT;
  productDetails: PRODUCT_DETAIL;
  documents: (DOCUMENT & { isAvailableOffline?: boolean })[];
  documentTypes: DOCUMENT_TYPE[];
  documentTabs: DOCUMENT_TAB[];
}

export const useOfflineProductActions = () => {
  const offlineDocumentActions = useOfflineDocumentActions();

  const upsertOfflineProduct = async (
    product: PRODUCT | undefined,
    productDetails: PRODUCT_DETAIL | undefined,
    documents: DOCUMENT[],
    documentTypes: DOCUMENT_TYPE[],
    documentTabs: DOCUMENT_TAB[]
  ) => {
    let res = {};

    const upsertDoc = {
      _id: productDetails?._id || product?._id,
      productId: product?._id,
      productDetailId: productDetails?._id,
      product: JSON.stringify(product),
      productDetails: JSON.stringify(productDetails),
      documents: JSON.stringify(documents),
      documentTypes: JSON.stringify(documentTypes),
      documentTabs: JSON.stringify(documentTabs),
    };

    res = await Product.upsert(upsertDoc, {
      conflictPaths: ['_id'],
    });

    return res;
  };

  const getAllOfflineProductData = async (): Promise<IOfflineProductData[]> => {
    const res = await Product.find();

    return res.map((item) => {
      return {
        documents: JSON.parse(item.documents),
        product: JSON.parse(item.product),
        productDetails: JSON.parse(item.productDetails),
        documentTypes: JSON.parse(item.documentTypes),
        documentTabs: JSON.parse(item.documentTabs),
      };
    });
  };

  const deleteProductDataById = async (id: string) => {
    const productData: IOfflineProductData | null = await getOfflineProductById(
      id
    );

    if (!productData) {
      return;
    }

    await Promise.all(
      productData.documents.map((doc) =>
        offlineDocumentActions.deleteOfflineDocumentById(doc._id)
      )
    );

    await Product.delete({ _id: id });
  };

  const getOfflineProductById = async (
    id: string
  ): Promise<null | IOfflineProductData> => {
    const res = await Product.findOne({
      where: [
        {
          productId: id,
        },
        {
          productDetailId: id,
        },
      ],
    });

    if (res) {
      const ret = {
        product: JSON.parse(res.product),
        productDetails: JSON.parse(res.productDetails),
        documents: JSON.parse(res.documents),
        documentTypes: JSON.parse(res.documentTypes),
        documentTabs: JSON.parse(res.documentTabs),
      };

      return ret;
    }

    return null;
  };

  const getOfflineProductDetailsById = async (
    id: string
  ): Promise<PRODUCT_DETAIL | undefined> => {
    const res = await Product.findOne({
      where: {
        productDetailId: id,
      },
    });

    if (res) {
      return JSON.parse(res.productDetails);
    }
  };

  return {
    upsertOfflineProduct,
    getAllOfflineProductData,
    deleteProductDataById,
    getOfflineProductById,
    getOfflineProductDetailsById,
  };
};
