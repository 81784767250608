<template>
  <q-toolbar class="bg-secondary text-white">
    <q-toolbar-title shrink> {{ t('analytics.title') }} </q-toolbar-title>
    <q-tabs
      v-model="tab"
      inline-label
      stretch
      active-bg-color="accent"
      @update:model-value="onChangeTab"
    >
      <q-tab name="users" :label="t('analytics.users')" icon="fad fa-users" />
      <q-tab
        name="documents"
        :label="t('analytics.documents')"
        icon="fad fa-books"
      />
      <q-tab
        name="feedback"
        :label="t('analytics.feedback')"
        icon="fad fa-star-half"
      />
      <q-tab
        name="subscriptions"
        :label="t('analytics.subscriptions')"
        icon="fad fa-hourglass-half"
      />
      <q-tab
        name="products"
        :label="t('analytics.products')"
        icon="fad fa-database"
      />
    </q-tabs>
    <q-space />
    {{ t('forms.date') }}:
    <q-btn :label="dateSelectLabel" flat stretch>
      <q-menu ref="dateMenu">
        <q-date
          v-model="dateRange"
          color="accent"
          range
          flat
          square
          minimal
          @range-end="onRangeEnd"
        />
      </q-menu>
    </q-btn>
  </q-toolbar>
</template>
<script setup lang="ts">
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import { QMenu } from 'quasar';
import { ANALYTICS_CATEGORY, useAnalyticsStore } from 'src/stores/analytics';
import { onMounted } from 'vue';
import { watchEffect } from 'vue';
import { ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const analyticsStore = useAnalyticsStore();
const { tab, dateRange, rangeAsDates } = storeToRefs(analyticsStore);

const dateMenu = ref<typeof QMenu | null>(null);
const router = useRouter();

const onChangeTab = (selectedTab: string) => {
  router.push({ query: { tab: selectedTab } });
};

const onRangeEnd = () => {
  if (rangeAsDates.value?.from && rangeAsDates.value?.to) {
    dateMenu.value?.hide();
  }
};

watchEffect(() => {
  if (router.currentRoute.value.query.tab) {
    tab.value =
      router.currentRoute.value.query.tab.toString() as ANALYTICS_CATEGORY;
  } else {
    router.replace({ query: { tab: tab.value } });
  }
});

const dateRangeCopy = ref();

const dateSelectLabel = computed(() =>
  rangeAsDates.value
    ? `${rangeAsDates.value?.from.toLocaleDateString()} - ${rangeAsDates.value?.to.toLocaleDateString()}`
    : `${dateRangeCopy.value?.from.toLocaleDateString()} - ${dateRangeCopy.value?.to.toLocaleDateString()}`
);

onMounted(() => {
  dateRangeCopy.value = cloneDeep(rangeAsDates.value);
});
</script>
