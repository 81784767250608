<template>
  <q-layout :class="theme?.bgColor" view="hHh lpR fff">
    <PreAuthHeader :hide-logo="isMobileApp" />
    <q-page-container>
      <router-view />
    </q-page-container>
    <FooterComponent />
    <MobileDevFab />
  </q-layout>
</template>

<script setup lang="ts">
import FooterComponent from 'src/components/FooterComponent.vue';
import PreAuthHeader from 'src/components/ui/PreAuthHeader.vue';
import { inject, computed } from 'vue';
import { Theme } from 'src/boot/theme';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
import MobileDevFab from '../components/developer-toolbar/MobileDevFab.vue';

const theme = inject<Theme>('$theme');
const isMobileApp = computed(checkIsMobileApp);
</script>
