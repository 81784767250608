import { AxiosError } from 'axios';
import { difference, union } from 'lodash';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { DOCUMENT } from 'src/@types/document';
import { VIEW_MODE } from 'src/@types/organisation-type';
import { PRODUCT } from 'src/@types/product';
import { PRODUCT_DETAIL } from 'src/@types/product-detail';
import { POPULATED_TOPIC } from 'src/@types/topic';
import { rocumentsApi } from 'src/boot/axios';
import { useTocFilter } from 'src/composables/useTocFilter';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { toValue, watch, computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { i18n } from 'src/boot/i18n';
import { TocItem as ITocItem } from 'src/@types/document';
import { useOfflineDocumentActions } from 'src/sqlite-database/use-offline-document-actions';
import { useCoreStore } from './core-store';
import { getOfflineTopicById } from 'src/sqlite-database/offline-topic-actions';

export const useDocumentViewerStore = defineStore(
  'documentViewer',
  () => {
    const router = useRouter();
    const route = useRoute();
    const offlineDocumentActions = useOfflineDocumentActions();

    const $reset = () => {
      topicTabs.value = [];
      tabScrollPositions.value = {};
      activeTab.value = 0;
      currentDocument.value = undefined;
      tocSelection.value = undefined;
      tocExpansion.value = [];
      isOfflineDocument.value = undefined;
    };

    const coreStore = useCoreStore();

    /* -------------------------------------------------------------------------- */
    /*                                    state                                   */
    /* -------------------------------------------------------------------------- */
    const currentDocument = ref<DOCUMENT | undefined>(undefined);
    const topicTabs = ref<POPULATED_TOPIC[]>([]);
    const tabScrollPositions = ref<Record<string, { x: number; y: number }>>(
      {}
    );
    const activeTab = ref<number>(0);
    const productContext = ref<(PRODUCT & PRODUCT_DETAIL) | undefined>(
      undefined
    );
    const tocSelection = ref<string | undefined>(undefined);
    const tocExpansion = ref<string[]>([]);
    const contentFontSize = ref<number>(16);
    const selectedViewMode = ref<VIEW_MODE>(VIEW_MODE.Standard);
    const trackedSession = ref<string | undefined>(undefined);
    const isOfflineDocument = ref<boolean | undefined>(undefined);
    /* -------------------------------------------------------------------------- */
    /*                                   Getters                                  */
    /* -------------------------------------------------------------------------- */

    const isConnected = computed(() => {
      return coreStore.isNetworkConnected;
    });

    const productContextType = computed<'model' | 'product'>(() =>
      toValue(productContext)?.hasOwnProperty('serialNumber')
        ? 'product'
        : 'model'
    );
    const topicTabIds = computed<string[]>(() =>
      toValue(topicTabs).map((tab) => tab.id)
    );

    const currentTopic = computed<POPULATED_TOPIC | undefined>(() => {
      if (toValue(topicTabs).length === 0) return undefined;
      return toValue(topicTabs)[toValue(activeTab)];
    });

    watch(
      currentTopic,
      (val, oldVal) => {
        if (val === oldVal) return;
        tocSelection.value = val?.id;
        tocExpansion.value = union(tocExpansion.value, val?.ancestors);
      },
      { immediate: true }
    );

    const toc = computed<undefined | ITocItem[]>(
      () => currentDocument.value?.toc
    );
    const { filteredTree } = useTocFilter(toc, productContext);
    const filteredToc = computed(() => filteredTree.value);
    const rootTopic = computed(() => filteredTree.value[0]);

    /* -------------------------------------------------------------------------- */
    /*                                   Actions                                  */
    /* -------------------------------------------------------------------------- */

    const documentRedirectHandler = async (
      dataGetter: () => Promise<DOCUMENT | null>
    ) => {
      try {
        const doc = await dataGetter();

        if (!doc) {
          return router.push('/404');
        }

        // TODO Move this logic to BE
        // Fluids Portal
        if (route.name === 'fluidsDocViewer') {
          if (!doc?.metadata.docType?.showInFluidsPortal) {
            router.push('/403');
          }
        }

        // Tyres Portal
        if (route.name === 'tyresDocViewer') {
          if (!doc.metadata.docType?.showInTyrePortal) {
            router.push('/403');
          }
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            return router.push('/401');
          } else if (error.response?.status === 403) {
            return router.push('/403');
          } else if (error.response?.status === 404) {
            return router.push('/404');
          }
        }

        apiErrorHandler(
          error,
          true,
          i18n.global.t('errors.failedToLoadArg', [
            i18n.global.t('entityTypes.document'),
          ])
        );
      }
    };

    const loadDocument = async (documentId: string) =>
      documentRedirectHandler(async () => {
        const { data } = await rocumentsApi.get<DOCUMENT>(
          `/documents/${documentId}`
        );

        currentDocument.value = data;
        isOfflineDocument.value = false;
        return data;
      });

    const loadOfflineDocument = async (documentId: string) =>
      documentRedirectHandler(async () => {
        const doc = await offlineDocumentActions.getOfflineDocumentById(
          documentId
        );

        if (!doc) {
          return null;
        }

        currentDocument.value = doc as DOCUMENT;
        isOfflineDocument.value = true;
        return doc;
      });

    /**
     * Retrieves a topic by its ID for a given document.
     * @param documentId The ID of the document containing the topic.
     * @param topicId The ID of the topic to retrieve.
     * @returns The retrieved topic.
     * @throws An error if the topic ID is not provided, or if there was an error fetching the topic.
     */
    const getTopic = async (documentId: string, topicId: string) => {
      if (!topicId) throw new Error('Topic ID is required');

      const topicIndex = toValue(topicTabIds).indexOf(topicId);
      if (topicIndex >= 0) return toValue(topicTabs)[topicIndex];

      try {
        if (isConnected.value) {
          const { status, data: topic } =
            await rocumentsApi.get<POPULATED_TOPIC>(
              `/documents/${documentId}/${topicId}`
            );
          if (status !== 200)
            throw new Error(`Error fetching topic: ${documentId}/${topicId}`);
          if (!topic)
            throw new Error(`Topic not found: ${documentId}/${topicId}`);
          return topic;
        } else {
          const offlineTopic = await getOfflineTopicById(documentId, topicId);
          return offlineTopic;
        }
      } catch (error) {
        apiErrorHandler(
          error,
          true,
          i18n.global.t('errors.failedToLoadArg', [
            i18n.global.t('entityTypes.topic'),
          ])
        );
      }
    };

    /**
     * Adds a new tab to the topicTabs array and sets it as the active tab.
     * @param documentId - The ID of the document to which the topic belongs.
     * @param topicId - The ID of the topic to add as a new tab.
     */
    const addNewTab = async (documentId: string, topicId: string) => {
      const topicIndex = toValue(topicTabIds).indexOf(topicId);
      if (topicIndex >= 0) return selectTab(topicId);

      // Get the topic if needed
      const topic = await getTopic(documentId, topicId);
      if (topic) {
        topicTabs.value.push(topic as POPULATED_TOPIC); // Add the topic to the topicTabs array
        activeTab.value = toValue(topicTabs).length - 1;
        // update the URL
        writeTabsToUrl();
      }
    };

    /**
     * Removes a tab from the topicTabs array and updates the activeTab index accordingly.
     * @param index - The index of the tab to remove.
     */
    const removeTab = (index: number) => {
      const isTabActiveTab = index === toValue(activeTab);
      if (!isTabActiveTab) {
        const isBeforeActiveTab = index < toValue(activeTab);
        topicTabs.value.splice(index, 1);
        if (isBeforeActiveTab) {
          activeTab.value = toValue(activeTab) - 1;
        }
        writeTabsToUrl();
        return;
      }
      const isLastTab = index === toValue(topicTabs).length - 1;
      const isFirstTab = index === 0;

      // removes the topic from the topicTabs array
      topicTabs.value.splice(index, 1);

      // sets the currentTopic to the correct index
      if (isLastTab) activeTab.value = toValue(topicTabs).length - 1;
      if (isFirstTab) activeTab.value = 0;
      if (!isFirstTab && !isLastTab) activeTab.value = index - 1;

      writeTabsToUrl();
    };

    /**
     * Selects a topic by its ID. If the topic is already open, it becomes the active tab.
     * Otherwise, a new tab is added for the topic.
     * @param topicId - The ID of the topic to select.
     */
    const selectTab = (topicId: string) => {
      const topicIndex = toValue(topicTabIds).indexOf(topicId);
      if (topicIndex >= 0) {
        activeTab.value = topicIndex;
        writeTabsToUrl();
      } else {
        addNewTab(route.params.id.toString(), topicId);
      }
    };

    const replaceCurrentTab = async (topicId: string) => {
      // Check if replacement tab exists then switch to it
      if (topicTabIds.value.includes(topicId)) {
        selectTab(topicId);
      } else {
        const topic = await getTopic(route.params.id.toString(), topicId);
        if (topic) {
          topicTabs.value.splice(activeTab.value, 1, topic as POPULATED_TOPIC);
          writeTabsToUrl();
        }
      }
    };

    /**
     * Writes the current tab state to the URL using Vue Router.
     * @throws {Error} If called outside of the document viewer pages.
     */
    const writeTabsToUrl = () => {
      if (!route.name) {
        throw new Error('Only use this on the document viewer pages');
      }

      const config = {
        name: route.name,
        query: {
          ...route.query,
          topics: toValue(topicTabIds).join(','),
          active: toValue(activeTab).toString(),
        },
      };

      route.query.topics ? router.push(config) : router.replace(config);
    };

    const initTabsFromUrl = async () => {
      const tabs = route.query.topics?.toString().split(',');
      if (!tabs) return;

      if (
        topicTabIds.value.length !== tabs.length ||
        difference(topicTabIds.value, tabs).length
      ) {
        const fetchedTabs = await Promise.all(
          tabs.map(
            async (tab) => await getTopic(route.params.id.toString(), tab)
          )
        );
        topicTabs.value = fetchedTabs.filter(Boolean) as POPULATED_TOPIC[];
      }
      activeTab.value = Number(route.query.active?.toString());
    };

    return {
      // State
      currentDocument,
      topicTabs,
      tabScrollPositions,
      activeTab,
      productContext,
      tocSelection,
      tocExpansion,
      contentFontSize,
      selectedViewMode,
      trackedSession,
      isOfflineDocument,
      // Getters
      productContextType,
      topicTabIds,
      currentTopic,
      toc,
      filteredToc,
      rootTopic,
      // Actions
      loadDocument,
      loadOfflineDocument,
      getTopic,
      addNewTab,
      removeTab,
      selectTab,
      replaceCurrentTab,
      writeTabsToUrl,
      initTabsFromUrl,
      // Special
      $reset,
    };
  },
  {
    persist: {
      key: 'rocuments.documentViewer.pinia',
      paths: ['productContext', 'tocSelection', 'tocExpansion'],
    },
  }
);
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDocumentViewerStore, import.meta.hot)
  );
}
