import { RocumentsDocument } from './entities/rocuments-document';
import { DocumentImage } from './entities/document-image';
import { Topic } from './entities/topic';
import { DOCUMENT } from 'src/@types/document';
import { IMAGE } from 'src/@types/image';
import { TOPIC } from 'src/@types/topic';
import * as TypeOrm from 'typeorm';
import { useDocumentStore } from 'src/stores/documents';

export const useOfflineDocumentActions = () => {
  const documentStore = useDocumentStore();

  interface IProcessedDocument {
    _id: string;
    stringifiedContent: string;
  }

  const processDoc = (doc: DOCUMENT): IProcessedDocument => {
    const pDoc = {
      _id: doc._id,
      stringifiedContent: JSON.stringify(doc),
    };

    return pDoc;
  };

  const unprocessDoc = (docProcessed: RocumentsDocument | null) => {
    if (!docProcessed) {
      return null;
    }

    const doc = JSON.parse(docProcessed.stringifiedContent);

    return doc as DOCUMENT;
  };

  interface IProcessedImage {
    _id: string;
    parentDocument: string;
    stringifiedContent: string;
  }

  const processImage = (
    image: IMAGE,
    parentDocumentId: string
  ): IProcessedImage => {
    // this filters out all other parent document ids
    const normalizedImage = { ...image };
    normalizedImage.parentDocument = normalizedImage.parentDocument.filter(
      (id) => id === parentDocumentId
    );

    const pImage = {
      _id: normalizedImage._id,
      parentDocument: normalizedImage.parentDocument[0], // takes current parent document id
      stringifiedContent: JSON.stringify(normalizedImage),
    };

    return pImage;
  };

  // const unprocessImage = (processedImage: DocumentImage): IMAGE => {
  //   const image = JSON.parse(processedImage.stringifiedContent);

  //   return image;
  // };

  interface IProcessedTopic {
    _id: string;
    id: string;
    parentDocument: string;
    stringifiedContent: string;
  }

  const processTopic = (topic: TOPIC): IProcessedTopic => {
    const pTopic = {
      _id: topic._id,
      id: topic.id,
      parentDocument: topic.parentDocument,
      stringifiedContent: JSON.stringify(topic),
    };

    return pTopic;
  };

  // const unprocessTopic = (processedTopic: Topic): TOPIC => {
  //   const topic = JSON.parse(processedTopic.stringifiedContent);

  //   return topic;
  // };

  const getOfflineDocuments = async (): Promise<DOCUMENT[]> => {
    const res = await RocumentsDocument.find();
    return res.map(unprocessDoc).filter((d) => d && d !== null) as DOCUMENT[];
  };

  const getOfflineDocumentsByIds = async (ids: string[]) => {
    const res = await RocumentsDocument.find({
      where: {
        _id: TypeOrm.In(ids),
      },
    });

    return res.map((doc) => unprocessDoc(doc));
  };

  const getOfflineDocumentById = async (id: string) => {
    const res = await RocumentsDocument.findOneBy({ _id: id });
    return unprocessDoc(res);
  };

  const upsertOfflineDocumentData = async ({
    document: rocumentsDocument,
    images,
    topics,
  }: {
    document: DOCUMENT;
    images: IMAGE[];
    topics: TOPIC[];
  }) => {
    await RocumentsDocument.upsert(processDoc(rocumentsDocument), {
      conflictPaths: ['_id'],
    });

    await Promise.all(
      images.map((image) =>
        DocumentImage.upsert(processImage(image, rocumentsDocument._id), {
          conflictPaths: ['_id'],
        })
      )
    );

    // TODO this should use upsertTopic from Topic actions
    await Promise.all(
      topics.map((topic) =>
        Topic.upsert(processTopic(topic), {
          conflictPaths: ['_id'],
        })
      )
    );

    await documentStore.loadActiveProductOfflineDocuments();
  };

  const deleteOfflineDocumentDataById = async (id: string) => {
    // deletes document and all related data (topics + images)
    await RocumentsDocument.delete({ _id: id });

    await DocumentImage.delete({ parentDocument: id });

    await Topic.delete({ parentDocument: id });
    // TODO delete product data if this is the last document

    await documentStore.loadActiveProductOfflineDocuments();
  };

  const deleteOfflineDocumentById = async (id: string) => {
    const res = await RocumentsDocument.delete({ _id: id });
    await documentStore.loadActiveProductOfflineDocuments();
    return res;
  };

  return {
    getOfflineDocuments,
    getOfflineDocumentsByIds,
    getOfflineDocumentById,
    upsertOfflineDocumentData,
    deleteOfflineDocumentDataById,
    deleteOfflineDocumentById,
  };
};
