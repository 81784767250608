import arSA from './ar-sa/ar-sa.json';
import csCZ from './cs-cz/cs-cz.json';
import deDE from './de-de/de-de.json';
import enGB from './en-gb/en-gb.json';
import enUS from './en-us/en-us.json';
import esES from './es-es/es-es.json';
import fiFI from './fi-fi/fi-fi.json';
import frFR from './fr-fr/fr-fr.json';
import itIT from './it-it/it-it.json';
import jaJP from './ja-jp/ja-jp.json';
import nlNL from './nl-nl/nl-nl.json';
import ptBR from './pt-br/pt-br.json';
import ptPT from './pt-pt/pt-pt.json';
import svSE from './sv-se/sv-se.json';
import thTH from './th-th/th-th.json';
import zhCN from './zh-cn/zh-cn.json';

export default {
  'ar-sa': arSA,
  'cs-cz': csCZ,
  'de-de': deDE,
  'en-gb': enGB,
  'en-us': enUS,
  'es-es': esES,
  'fi-fi': fiFI,
  'fr-fr': frFR,
  'it-it': itIT,
  'ja-jp': jaJP,
  'nl-nl': nlNL,
  'pt-br': ptBR,
  'pt-pt': ptPT,
  'sv-se': svSE,
  'th-th': thTH,
  'zh-cn': zhCN,
};
