import { colors, colorsRgba, getCssVar, BrandColor } from 'quasar';
const { luminosity } = colors;
export function useTextColor(backGroundColour: string | colorsRgba) {
  let colour: string | null | colorsRgba = backGroundColour;

  if (
    [
      'primary',
      'secondary',
      'accent',
      'dark',
      'positive',
      'negative',
      'info',
      'warning',
    ].includes(colour as BrandColor)
  ) {
    colour = getCssVar(backGroundColour as BrandColor);
  }

  return colour ? (luminosity(colour) >= 0.5 ? 'black' : 'white') : 'black';
}
