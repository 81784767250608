import { defineStore, storeToRefs } from 'pinia';
import { rocumentsApi } from 'src/boot/axios';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { computed, Ref, ref, watch } from 'vue';
import {
  auditChange,
  KNOWLEDGE_BASE_SETTINGS,
  KNOWLEDGE_BASE_SETTINGS_POPULATED,
} from 'src/@types/customer/trm/knowledge-base';
import { KNOWLEDGE_BASE } from 'src/@types/knowledge-base';
import { ROLE } from 'src/@types/user';
import { useCoreStore } from 'src/stores/core-store';
import { PRODUCT } from 'src/@types/product';
import { useDocumentStore } from 'src/stores/documents';
import { useI18n } from 'vue-i18n';

export const useKnowledgeBaseStore = defineStore('knowledge-base', () => {
  const coreStore = useCoreStore();
  const { auth } = storeToRefs(coreStore);
  const documentStore = useDocumentStore();
  const { activeProduct } = storeToRefs(documentStore);
  const { t } = useI18n();

  const kbArticles = ref<(KNOWLEDGE_BASE & { history: auditChange[] })[]>([]);
  const currentKnowledgeHistory: Ref<auditChange[]> = ref([]);

  const loadAndGetKnowledgeBaseHistoryById = async (id: string) => {
    try {
      const { data, status } = await rocumentsApi.get(
        `knowledge-base/knowledge-base-history/${id}`
      );

      if (status === 200 || status === 201) {
        // currentKnowledgeHistory.value = getDifferences(d);
        currentKnowledgeHistory.value = data.map(
          (res: { changeHistory: any[] }) => ({
            ...res,
            changeHistory: res.changeHistory.filter(
              (r: any) => r.val !== 'comments'
            ),
          })
        );
        return currentKnowledgeHistory.value;
      }
    } catch (error) {
      apiErrorHandler(error);
    }
  };

  const settingsPopulated = ref<KNOWLEDGE_BASE_SETTINGS_POPULATED>();
  const settings = computed<KNOWLEDGE_BASE_SETTINGS>(() => ({
    users: settingsPopulated.value?.users.map((x) => x._id) || [],
    orgTypes: settingsPopulated.value?.orgTypes.map((x) => x._id) || [],
  }));

  const getKnowledgeBaseSettings = async () => {
    try {
      const response = await rocumentsApi.get('knowledge-base-settings');

      if (response.data) {
        settingsPopulated.value = response.data;
      }
    } catch (error) {
      apiErrorHandler(error);
    }
  };

  const userHasKBRight = ref(false);

  const userHasKBAccess = computed(
    () =>
      userHasKBRight.value ||
      (auth?.value?.user?.role &&
        [ROLE.admin, ROLE.document_admin].includes(auth.value.user.role))
  );

  const isAdminLevelPermissions = computed(
    () =>
      auth?.value?.user?.role &&
      [ROLE.admin, ROLE.document_admin].includes(auth.value.user.role)
  );

  const getAccessPermissions = async () => {
    const { data } = await rocumentsApi.get(
      '/knowledge-base-settings/users/current'
    );
    userHasKBRight.value = data;
  };

  const loadKBArticles = async () => {
    await getAccessPermissions();

    try {
      if (!activeProduct.value || !userHasKBAccess.value) {
        kbArticles.value = [];
        return;
      }

      const { data } = await rocumentsApi.get('/knowledge-base/product-kbs', {
        params: {
          modelCode: (activeProduct.value as Partial<PRODUCT>).modelCode,
          modelYear: (activeProduct.value as Partial<PRODUCT>).modelYear,
          serial: (activeProduct.value as Partial<PRODUCT>).serialNumberStub,
          engineNo: (activeProduct.value as Partial<PRODUCT>).engineNo,
          market: (activeProduct.value as Partial<PRODUCT>).marketSpec || '',
        },
      });

      const articles = await Promise.all(
        data.map(async (article: KNOWLEDGE_BASE) => ({
          ...article,
          history: isAdminLevelPermissions.value
            ? await loadAndGetKnowledgeBaseHistoryById(article._id)
            : null,
        }))
      );
      kbArticles.value = articles;
      return true;
    } catch (error) {
      apiErrorHandler(
        error,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.knowledgeBase')])
      );
    }
  };

  watch(() => activeProduct.value, loadKBArticles);

  return {
    currentKnowledgeHistory,
    loadAndGetKnowledgeBaseHistoryById,
    settings,
    settingsPopulated,
    getKnowledgeBaseSettings,
    userHasKBAccess,
    userHasKBRight,
    getAccessPermissions,
    kbArticles,
    loadKBArticles,
  };
});
