import { Dialog, DialogChainObject } from 'quasar';

export interface POP_DIALOG_OPTIONS {
  title: string;
  message: string;
  submit: string;
  cancel: string;
}

export function popDialog({
  title,
  message,
  submit,
  cancel,
}: POP_DIALOG_OPTIONS): DialogChainObject {
  return Dialog.create({
    title: title,
    message: message,
    persistent: true,
    ok: { color: 'primary', label: submit },
    cancel: {
      flat: true,
      label: cancel,
    },
  });

  // .onOk(async () => {
  //   cb();
  // });
}
