import { defineStore } from 'pinia';
import { DOCUMENT_TAB } from 'src/@types/document-tab';
import { rocumentsApi } from 'src/boot/axios';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useDocumentTabsStore = defineStore('document-tabs', () => {
  const { t } = useI18n();

  const documentTabs = ref<DOCUMENT_TAB[]>([]);

  const loadDocumentTabs = async () => {
    try {
      const { data } = await rocumentsApi.get<DOCUMENT_TAB[]>('/doc-tabs');
      documentTabs.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const createDocumentTab = async (docTab: Partial<DOCUMENT_TAB>) => {
    try {
      const { data } = await rocumentsApi.post<DOCUMENT_TAB>(
        '/doc-tabs',
        docTab
      );
      documentTabs.value.push(data);
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const updateDocumentTabs = async (docTabs: Partial<DOCUMENT_TAB>[]) => {
    try {
      await rocumentsApi.patch('/doc-tabs', { updates: docTabs });
      await loadDocumentTabs();
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const deleteDocumentTab = async (id: string) => {
    try {
      await rocumentsApi.delete(`/doc-tabs/${id}`);
      await loadDocumentTabs();
    } catch (error) {
      apiErrorHandler(error, true, t('errors.deleteFailed'));
    }
  };

  return {
    documentTabs,
    loadDocumentTabs,
    createDocumentTab,
    updateDocumentTabs,
    deleteDocumentTab,
  };
});
