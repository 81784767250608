import { Platform, useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';
// const langList = import.meta.glob('../../node_modules/quasar/lang/*.mjs');
const langList = import.meta.glob('../../node_modules/quasar/lang/*.js');

export function useBrowserLangFormat() {
  const i18n = useI18n();
  const quasar = useQuasar();

  const onSelectLanguage = (lang: string) => {
    quasar.cookies.set('ROCUMENTS.langPref', lang);
    i18n.locale.value = lang;

    const quasarLangs: Record<string, string> = {
      'ar-sa': 'ar',
      'cs-cz': 'cs',
      'de-de': 'de',
      'en-gb': 'en-GB',
      'en-us': 'en-US',
      'es-es': 'es',
      'fi-fi': 'fi',
      'fr-fr': 'fr',
      'it-it': 'it',
      'ja-jp': 'ja',
      'nl-nl': 'nl',
      'pt-br': 'pt-BR',
      'pt-pt': 'pt',
      'sv-se': 'sv',
      'th-th': 'th',
      'zh-cn': 'zh-CN',
    };

    try {
      langList[`../../node_modules/quasar/lang/${quasarLangs[lang]}.js`]().then(
        (quasarLang) => {
          quasar.lang.set(quasarLang.default);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getBrowserLang = (lang: string) => {
    const {
      chrome: chromeBrowser,
      edge,
      firefox,
      edgeChromium,
      opera,
      safari,
      versionNumber,
    } = Platform.is;

    if (
      (chromeBrowser && versionNumber && versionNumber >= 81) ||
      (edge && versionNumber && versionNumber >= 81) ||
      (edgeChromium && versionNumber && versionNumber >= 81) ||
      (firefox && versionNumber && versionNumber >= 86) ||
      (opera && versionNumber && versionNumber >= 68) ||
      (safari && versionNumber && versionNumber >= 14.1)
    ) {
      const langName = new Intl.DisplayNames([i18n.locale.value], {
        type: 'language',
      });

      return langName.of(lang) || '';
    }

    return lang;
  };

  const formatLang = (lang: string) => {
    const browserLang = getBrowserLang(lang);
    return browserLang;
  };

  const getLangTranslation = (lang: string, withNative = true): string => {
    return withNative
      ? `${i18n.t(`languages.${lang}`)} [${i18n.t(
          `languages.${lang}`,
          undefined,
          {
            locale: lang,
          }
        )}]`
      : `${i18n.t(`languages.${lang}`)}`;
  };

  return { formatLang, getLangTranslation, onSelectLanguage };
}
