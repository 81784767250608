<template v-slot="default">
  <q-fab-action
    :disable="!isMobileApp"
    label="Drop DB"
    icon="fal fa-trash-alt"
    color="primary"
    external-label
    label-position="left"
    @click="actionDropDb"
  />

  <q-fab-action
    :disable="!isMobileApp"
    label="Init DB"
    color="primary"
    icon="fal fa-database"
    external-label
    label-position="left"
    @click="actionInitDb"
  />

  <q-fab-action
    :disable="!isMobileApp"
    label="Log documents in db"
    color="primary"
    external-label
    label-position="left"
    icon="fal fa-copy"
    @click="getAllDocs"
  />

  <!-- <button @click="customAction">Load all products</button>
      <button @click="customAction2">Auth call</button> -->
</template>
<!-- eslint-disable no-console -->
<script setup lang="ts">
import { IDbObject } from 'src/sqlite-database/connectDb';
import { inject, computed } from 'vue';
import { connectDb } from 'src/sqlite-database/connectDb';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
// custom actions that should be cleaned afterwards to bare function
import { useOfflineProductActions } from 'src/sqlite-database/offline-product-actions';
import { useOfflineDocumentActions } from 'src/sqlite-database/use-offline-document-actions';
import { getAllOfflineTopic } from 'src/sqlite-database/offline-topic-actions';

const dbObject: IDbObject | undefined | null = inject('sqlite-db');
const isMobileApp = computed(checkIsMobileApp);

const actionDropDb = async () => {
  console.log('dbObject: ', dbObject);
  console.log('Drop DB');
  console.warn(
    'DB drop sometimes takes time and sometimes it requires a second attempt after reload'
  );
  await dbObject?.dataSource?.dropDatabase();
  console.log('Drop DB SUCCESS');
};

const actionInitDb = async () => {
  console.log('Init DB');
  const db = await connectDb();

  // provide newly connected database to the rest of the app
  if (dbObject?.dataSource && db?.dataSource && db?.entities) {
    dbObject.dataSource = db.dataSource;
    dbObject.entities = db.entities;
    console.log('Set dataSource SUCCESS');
  }

  console.log('Init DB SUCCESS');
};

const offlineProductActions = useOfflineProductActions();

const offlineDocumentActions = useOfflineDocumentActions();

const getAllDocs = async () => {
  try {
    const docs = await offlineDocumentActions.getOfflineDocuments();
    const prods = await offlineProductActions.getAllOfflineProductData();
    const topics = await getAllOfflineTopic();
    console.log('all documents in sqLite', docs);
    console.log('all products in sqLite', prods);
    console.log('all topics in sqLite', topics);
  } catch (err) {
    console.error(err);
  }
};
</script>
