<template>
  <q-toolbar class="bg-secondary print-invisible">
    <q-btn
      v-if="$q.screen.lt.sm"
      :icon="isMobileApp ? 'fal fa-list-alt' : 'menu'"
      flat
      stretch
      :dense="isMobileApp"
      @click="toggleToc"
    />
    <q-toolbar-title class="text-subtitle1" shrink>
      <div class="ellipsis">
        {{ currentDocument?.title }}
        <span v-if="currentDocument?.metadata.docType?.systemTitle">
          ({{ currentDocument?.metadata.docType?.systemTitle }})
        </span>
      </div>
      <div
        v-if="
          !route.path.includes('document-preview') &&
          !!productContext?.modelName
        "
      >
        <router-link
          :to="
            productContextType === 'product'
              ? `/products/${productContext._id}`
              : `/products/search/${productContext._id}`
          "
          class="col-xs-12 col-md hover-underline"
        >
          <small class="text-caption ellipsis">
            <span v-if="productContext.modelName">{{
              productContext.displayName || productContext.modelName
            }}</span>
            <span v-if="productContext.modelYear">
              - {{ productContext.modelYear }}</span
            >
            <span v-if="productContext.serialNumber">
              - {{ productContext.serialNumber }}</span
            >
          </small>
        </router-link>
      </div>
    </q-toolbar-title>
    <q-space />

    <template v-if="route.meta?.protected">
      <q-btn 
        v-if="auth?.role === 'admin' || auth?.role === 'document_admin'"
        :disable="contentFontSize <= 6"
        icon="fad fa-link"
        flat
        stretch
        :dense="isMobileApp"
        @click="currentDocument?._id && onGoToDocument(currentDocument._id)">
        <q-tooltip>
          {{ t('buttons.link', [currentDocument?.title]) }}
        </q-tooltip>
      </q-btn>
      <BookmarkButton
        :bookmark="{ topic: currentTopic, document: currentDocument }"
      />
      <!-- START: Text Size -->
      <q-btn
        :disable="contentFontSize <= 6"
        flat
        stretch
        :dense="isMobileApp"
        @click="decreaseFontSize"
      >
        <span class="fa-stack fa-2x">
          <q-icon class="fad fa-text-size fa-stack-1x" />
          <q-icon class="fas fa-minus fa-stack-1x" />
        </span>
      </q-btn>
      <q-btn
        :disable="contentFontSize >= 26"
        :dense="isMobileApp"
        flat
        stretch
        @click="increaseFontSize"
      >
        <span class="fa-stack fa-2x">
          <q-icon class="fad fa-text-size fa-stack-1x" />
          <q-icon class="fas fa-plus fa-stack-1x" />
        </span>
      </q-btn>
      <!-- END: Text Size -->
      <!-- START: Print -->
      <q-btn
        v-if="$q.screen.gt.sm && userCanPrint"
        icon="fad fa-print"
        flat
        stretch
        :dense="isMobileApp"
        @click="onClickPrint"
      >
        <q-tooltip content-class="print-hide" class="print-hide">
          {{ t('buttons.printPage') }}
        </q-tooltip>
      </q-btn>
      <!-- END: Print -->
      <!-- START: Feedback -->
      <q-btn
        v-if="$q.screen.gt.sm"
        icon="fad fa-comment-alt"
        flat
        stretch
        :dense="isMobileApp"
        @click="onClickFeedback"
      >
        <q-tooltip>
          {{ t('documents.feedback') }}
        </q-tooltip>
      </q-btn>
      <!-- END: Feedback -->
      <!-- START: Notes -->

      <q-btn
        v-if="$q.screen.gt.sm"
        icon="fad fa-sticky-note"
        flat
        stretch
        @click="onClickNotes"
      >
        <q-badge
          v-if="notes.length"
          :label="notes.length"
          color="red"
          align="top"
          floating
        />
        <q-tooltip>
          {{ t('documents.notes.featureName') }}
        </q-tooltip>
      </q-btn>
      <!-- END: Notes -->
      <!-- START: Download -->
      <q-btn
        v-if="$q.screen.gt.sm && userCanDownload && downloadEnabled"
        :href="downloadLink"
        tag="a"
        icon="fad fa-download"
        flat
        stretch
      >
        <q-tooltip>
          {{ t('buttons.downloadOffline') }}
        </q-tooltip>
      </q-btn>
      <!-- END: Download -->
      <!-- START: View mode -->
      <q-tabs
        v-if="$q.screen.gt.sm"
        v-model="selectedViewMode"
        stretch
        class="q-mx-sm"
        shrink
      >
        <q-tab
          v-for="option of detailOptions"
          :key="option.value"
          :disable="!hasProcedure"
          :name="option.value"
          :label="option.label"
        />
      </q-tabs>
      <!-- END: View mode -->
      <!-- START: Search -->
      <SearchInput
        v-if="$q.screen.gt.sm && currentTopic?.parentDocument?._id"
        :parent-doc="currentTopic?.parentDocument?._id"
        bg-color="white"
        :disabled="!isNetworkConnected"
      />
      <!-- END: Search -->
      <!-- START: Mobile Menu -->
      <q-btn
        v-if="$q.screen.lt.md"
        icon="fal fa-ellipsis-v"
        stretch
        flat
        :dense="isMobileApp"
      >
        <q-menu>
          <SearchInput
            v-if="currentTopic?.parentDocument._id"
            :parent-doc="currentTopic?.parentDocument._id"
            bg-color="white"
            :disabled="!isNetworkConnected"
          />
          <q-list style="min-width: 100px">
            <q-item
              v-if="userCanPrint"
              v-close-popup
              clickable
              @click="onClickPrint"
            >
              <q-item-section>{{ t('buttons.printPage') }}</q-item-section>
            </q-item>
            <q-separator />
            <q-item v-close-popup clickable @click="onClickFeedback">
              <q-item-section>{{ t('documents.feedback') }}</q-item-section>
            </q-item>
            <q-separator />
            <q-item v-close-popup clickable @click="onClickNotes">
              <q-item-section>
                <q-item-label>
                  {{ t('documents.notes.featureName') }}
                  <q-badge :label="notes.length" color="red" />
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item>
              <q-btn-toggle
                v-model="selectedViewMode"
                :disable="!hasProcedure"
                :options="detailOptions"
                flat
                stretch
                toggle-color="grey"
                toggle-text-color="accent"
              />
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </template>

    <!-- END:Mobile Menu -->
  </q-toolbar>

  <q-toolbar
    v-if="topicTabs.length > 1"
    class="bg-grey-8 q-px-none"
    style="min-height: 30px"
  >
    <q-tabs
      v-if="!isScreenContracted"
      :model-value="activeTab"
      active-bg-color="accent"
      shrink
      stretch
      dense
      align="left"
      :breakpoint="0"
    >
      <q-tab
        v-for="(tab, index) in topicTabs"
        :key="index"
        :name="index"
        style="
          font-size: 0.9em;
          border-right: 0.5px rgba(255, 255, 255, 0.2) solid;
          border-left: 0.5px rgba(255, 255, 255, 0.2) solid;
          border-collapse: collapse;
        "
        @click="selectTab(topicTabs[index].id)"
      >
        <span>
          {{ tab.title }}
          <q-icon size="xs" name="cancel" @click.stop="removeTab(index)" />
          <q-separator vertical dark />
        </span>
      </q-tab>
    </q-tabs>
    <div v-else class="col-auto self-end">
      <q-btn
        color="primary"
        flat
        stretch
        icon="more_vert"
        :label="t('documents.openTabs')"
      >
        <q-menu>
          <q-list separator dense>
            <q-item-label header>
              {{ t('documents.openTabs') }}
            </q-item-label>
            <q-item
              v-for="(tab, index) in topicTabs"
              :key="index"
              v-close-popup
              clickable
              :name="index"
              :active="index === activeTab"
              active-class="bg-accent text-white"
              @click="selectTab(topicTabs[index].id)"
            >
              <q-item-section>
                <q-item-label>{{ tab.title }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn
                  class="q-ml-xs"
                  flat
                  dense
                  size="sm"
                  round
                  color="secondary"
                  icon="close"
                  @click.stop="removeTab(index)"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
  </q-toolbar>

  <q-slide-transition>
    <div
      v-show="isOfflineDocument"
      class="bg-accent text-white text-weight-bold q-pa-xs q-ma-auto row no-wrap justify-center"
    >
      <q-icon name="fal fa-book-open" class="q-pr-sm" size="1.4em" />
      {{ t('viewingOfflineVersion') }}
    </div>
  </q-slide-transition>
</template>

<script setup lang="ts">
import { computed, watch, inject, toValue, ComputedRef } from 'vue';
import { EventBus, useQuasar } from 'quasar';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import SearchInput from 'src/components/document/SearchInput.vue';
import BookmarkButton from 'src/components/document/BookmarkButton.vue';
import { useDocumentViewerStore } from 'src/stores/document-viewer';
import { storeToRefs } from 'pinia';
import { VIEW_MODE } from 'src/@types/organisation-type';
import { useNotesStore } from 'src/stores/notes';
import { AUTH } from 'src/@types/auth';
import { useCoreStore } from 'src/stores/core-store';
import getServerUrl from 'src/utils/getServerUrl';
import checkIsMobileApp from '../../utils/checkIsMobileApp';

const i18n = useI18n();
const { t } = i18n;
const $q = useQuasar();
const route = useRoute();
const router = useRouter();
const eventBus: EventBus | undefined = inject('event-bus');

const documentViewerStore = useDocumentViewerStore();
const { selectTab, removeTab } = documentViewerStore;
const { contentFontSize } = storeToRefs(documentViewerStore);
const {
  currentDocument,
  productContext,
  productContextType,
  currentTopic,
  topicTabs,
  activeTab,
  selectedViewMode,
  isOfflineDocument,
} = storeToRefs(documentViewerStore);

const notesStore = useNotesStore();
const { notes } = storeToRefs(notesStore);

const coreStore = useCoreStore();
const { isNetworkConnected } = storeToRefs(coreStore);

const auth = inject<ComputedRef<AUTH | undefined>>('auth');
const isMobileApp = computed(checkIsMobileApp);

const downloadEnabled = computed(() => process.env.downloadEnabled);
const isScreenContracted = computed(() => $q.screen.lt.sm);

const downloadLink = computed(
  () => `${getServerUrl()}/documents/${currentDocument.value?._id}/download`
);

const hasProcedure = computed(
  () =>
    Object.keys(toValue(currentTopic) || {}).length &&
    JSON.stringify(toValue(currentTopic)?.content).includes(
      'procedural-instructions'
    )
);

const detailOptions = computed(() => {
  const orgTypeOptions = auth?.value?.organisationType.enabledViewModes;
  const availableOptions = Object.keys(orgTypeOptions || {}).filter(
    (key) => orgTypeOptions?.[key as VIEW_MODE]
  );

  const allOptions = [
    {
      value: VIEW_MODE.Standard,
      label: i18n.t('profile.preference.options.standard'),
    },
    {
      value: VIEW_MODE.Detailed,
      label: i18n.t('profile.preference.options.detailed'),
    },
    {
      value: VIEW_MODE.Overview,
      label: i18n.t('profile.preference.options.overview'),
    },
  ];

  return allOptions.filter((x) => availableOptions.includes(x.value));
});

const userCanPrint = computed(
  () =>
    auth?.value?.organisationType &&
    currentDocument.value?.metadata.docType?.print &&
    currentDocument.value?.metadata.docType?.rights?.print.includes(
      auth?.value?.organisationType._id
    )
);

const userCanDownload = computed(
  () =>
    auth?.value?.organisationType &&
    currentDocument.value?.metadata.docType?.download &&
    currentDocument.value?.metadata.docType?.rights?.download.includes(
      auth?.value?.organisationType._id
    )
);

watch(
  route,
  () => {
    if (hasProcedure.value) {
      selectedViewMode.value =
        auth?.value?.user.preferences?.detail || VIEW_MODE.Standard;
    }
    selectedViewMode.value = VIEW_MODE.Standard;
  },
  { immediate: true }
);

const onGoToDocument = (id: string) => router.push(`/admin/documents/${id}`);

const onClickNotes = () => {
  eventBus?.emit('openNotes');
};

const onClickFeedback = () => {
  eventBus?.emit('openFeedback');
};

const toggleToc = () => {
  eventBus?.emit('toggleToc');
};

const onClickPrint = async () => {
  const element = document.getElementById('topic-content');

  if (!element) {
    console.error('Failed to print. No topic element');
    return;
  }

  element.classList.remove('print-hide');
  window.print();
  element.classList.add('print-hide');
};

const increaseFontSize = () => {
  if (contentFontSize.value < 26) {
    contentFontSize.value++;
  }
};

const decreaseFontSize = () => {
  if (contentFontSize.value > 12) {
    contentFontSize.value--;
  }
};
</script>

<style lang="scss" scoped>
.hover-underline:hover {
  text-decoration: underline;
}

.fa-stack {
  font-size: 1em;
}

q-icon {
  vertical-align: top;
}

.fas {
  font-size: 0.8em;
  left: 18px;
  top: 15px;
}

.dragArea {
  display: flex;
  width: 100%;
  height: 100%;
}

.ghost {
  opacity: 0.1;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-family: din-2014, sans-serif;
  font-size: 16px;
}
</style>
