import { boot } from 'quasar/wrappers';
import * as Sentry from '@sentry/vue';
import getServerUrl from 'src/utils/getServerUrl';

const logger = (
  type: 'warn' | 'error' | 'log' | 'info' | 'debug',
  data: any
) => {
  // eslint-disable-next-line no-console
  console[type](data);
};
export { logger };
// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(async ({ app, router }) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  Sentry.init({
    app,
    dsn: 'https://3b39285b2502c50ef52a1e38cf196bec@o1044900.ingest.sentry.io/4506320791207936',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', getServerUrl()],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: process.env.DEBUGGING ? 0 : 0.1, // Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: process.env.DEBUGGING ? 0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: process.env.DEBUGGING ? 0 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Ignore false positive errors
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
});
