import { acceptHMRUpdate, defineStore } from 'pinia';
import { rocumentsApi } from 'src/boot/axios';
import apiErrorHandler from '../utils/exceptions/api-error-handler';
import { i18n } from 'src/boot/i18n';
import {
  BlankOrganisation as IBlankOrganisation,
  BlankUser as IBlankUser,
} from 'src/utils/blankObjects';
import { USER } from 'src/@types/user';
import { PRODUCT } from 'src/@types/product';
import { popToast } from 'src/utils/toasts';
import { isAxiosError } from 'axios';

export interface USER_REGISTER {
  user: IBlankUser;
  organisation: IBlankOrganisation;
  captchaToken?: string;
}

export interface USERS_STATE {
  users: USER[];
  editUser: Partial<USER>;
  userExistsByEmail: boolean;
  currentUser: Partial<USER>;
  userNotificationPreferences: USER['preferences'][];
  userHistory: {
    productHistory: HISTORY_ENTRY[];
    topicHistory: HISTORY_ENTRY[];
  };
}

export interface HISTORY_ENTRY {
  date: string;
  product: PRODUCT;
  _id?: string;
}

export type USER_OWN_MEMBER = Omit<Partial<USER>, 'password'>;

export interface CHANGE_PASSWORD {
  newPassword: string;
  oldPassword: string;
}

export interface USER_HISTORY_UPDATE {
  historyType: string;
  modelCode: string;
  modelYear: string;
  serialNumber?: string;
  _id: string;
}

export interface USER_CHANGE_EMAIL {
  email: string;
  oldEmail: string;
  timeNow: number;
  token: string;
  url?: string;
}

const errorResultInfo = i18n.global.t('errors.failedToLoadArg', [
  i18n.global.t('entityTypes.users'),
]);

export const useUsersStore = defineStore('users', {
  state: (): USERS_STATE => ({
    users: [],
    editUser: {} as Partial<USER>,
    userExistsByEmail: false,
    currentUser: {} as Partial<USER>,
    userNotificationPreferences: [],
    userHistory: {} as {
      productHistory: HISTORY_ENTRY[];
      topicHistory: HISTORY_ENTRY[];
    },
  }),
  actions: {
    async register(payload: USER_REGISTER) {
      try {
        const { status } = await rocumentsApi.post('/users/register', payload);
        return status;
      } catch (err: any) {
        if (
          isAxiosError(err) &&
          err.response?.data.message === 'Captcha verification failed'
        ) {
          return i18n.global.t('errors.failedAutoCaptcha');
        } else {
          apiErrorHandler(err, true, errorResultInfo);
        }
      }
    },

    async createUser(payload: USER) {
      try {
        const { status } = await rocumentsApi.post('/users/create', payload);
        popToast('positive');
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async verifyEmail(payload: { token: string }) {
      try {
        const { status } = await rocumentsApi.post(
          '/users/verify-email',
          payload
        );
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async resendVerification() {
      try {
        const { status } = await rocumentsApi.post(
          '/users/resend-verification'
        );
        popToast('positive');
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async getSingleUser(id: string) {
      try {
        const { data, status } = await rocumentsApi.get(`/users/${id}`);
        this.editUser = data;
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async checkEmailInUse(email: string) {
      try {
        const { data } = await rocumentsApi.get(
          `/users/email-in-use?email=${encodeURIComponent(email)}`
        );
        this.userExistsByEmail = data;
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async createOwnMembers(payload: USER_OWN_MEMBER) {
      try {
        const { data } = await rocumentsApi.post('/users/own-members', payload);
        popToast('positive');
        return data._id;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async getCurrentUser(populate: boolean) {
      try {
        const { data, status } = await rocumentsApi.get(
          `/users/current${populate ? '?populate=true' : ''}`
        );
        this.currentUser = data;
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async updateCurrentUser(update: Partial<USER>) {
      try {
        const { data } = await rocumentsApi.patch('/users/current', update);
        this.editUser = data;
        popToast('positive');
        return await this.getCurrentUser(true);
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async changeUserPassword(payload: CHANGE_PASSWORD) {
      try {
        const { data } = await rocumentsApi.post(
          '/users/change-password',
          payload
        );
        popToast('positive');
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async resetUserPassword(email: string) {
      try {
        const { data } = await rocumentsApi.post('/users/reset-password', {
          email,
        });
        popToast('positive');
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async requestEmailChange(payload: Partial<USER_CHANGE_EMAIL>) {
      try {
        const { data } = await rocumentsApi.post(
          '/users/change-email-request',
          payload
        );
        popToast('positive');
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async changeUserEmail(payload: USER_CHANGE_EMAIL) {
      try {
        const { data } = await rocumentsApi.post('/users/change-email', {
          ...payload,
        });
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async updateUser(payload: Partial<USER>) {
      const { _id, ...updates } = payload;
      try {
        const { data } = await rocumentsApi.patch(`/users/${_id}`, updates);
        popToast('positive');
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async deleteUser(id: string) {
      try {
        const { data } = await rocumentsApi.delete(`/users/${id}`);
        popToast('positive');
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async deleteOwnUser() {
      try {
        const { status } = await rocumentsApi.delete('/users/current');
        popToast('positive');
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    // Not In Use Frontend - END Point on BE exists
    async getUserGDPR({ user, format }: { user: USER; format: string }) {
      try {
        const { data } = await rocumentsApi.get(`/users/current/gdpr/${user}`, {
          params: { format },
        });
        popToast('positive');
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async getSearchedUsers(search: string) {
      if (search.trim() !== '') {
        try {
          const { data } = await rocumentsApi.get(
            `/users/searched?search=${search}`
          );
          this.users = data;
          return data;
        } catch (err) {
          apiErrorHandler(err, true, errorResultInfo);
        }
      }
    },

    async updateUserHistory(payload: USER_HISTORY_UPDATE) {
      try {
        const { data } = await rocumentsApi.post(
          '/users/update-history',
          payload
        );
        return data;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async updateUserNotificationPreferences(payload: {
      _id: USER['_id'];
      preferences: USER['preferences'];
    }) {
      try {
        const { data, status } = await rocumentsApi.patch(
          `/users/notifications/${payload._id}`,
          payload.preferences
        );
        this.userNotificationPreferences = data;
        popToast('positive');
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },

    async getHistory() {
      try {
        const { data, status } = await rocumentsApi.get(
          '/users/current/history'
        );
        this.userHistory = data;
        return status;
      } catch (err) {
        apiErrorHandler(err, true, errorResultInfo);
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersStore, import.meta.hot));
}
