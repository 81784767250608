<template>
  <q-footer>
    <div class="row q-col-gutter-md q-pa-md justify-center">
      <!-- <div class="col-xs-12 col-md order-md-first">
          <router-link to="/contact">
            {{ t('contact.title') }}
          </router-link>
        </div> -->
      <div class="col-xs-12 col-md-auto text-center">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        v{{ state.version }}
        {{
          t('copyright.label', [
            new Date().getFullYear(),
            state.company,
            'Coding Ketchup',
          ])
        }}
      </div>
      <div class="col-xs-auto col-md order-md-first">
        <router-link to="/contact">
          {{ t('contact.title') }}
        </router-link>

        <router-link class="q-ml-lg" to="/faq">
          {{ t('faq.featureTitle') }}
        </router-link>
      </div>
      <div
        :class="{ 'text-right': $q.screen.gt.sm }"
        class="col-xs-auto col-md q-gutter-x-md"
      >
        <router-link v-if="terms" to="/terms">
          {{ t('terms.title') }}
        </router-link>
        <router-link v-if="privacyPolicy" to="/privacy-policy">
          {{ t('privacy.title') }}
        </router-link>
      </div>
    </div>
  </q-footer>
</template>

<script setup lang="ts">
import { version } from '../../package.json';
import { computed, inject, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuasar } from 'quasar';
import { Theme } from 'src/boot/theme';
const theme = inject<Theme>('$theme');

import { useAdminSettingsStore } from 'src/stores/admin-settings';
const adminSettingsStore = useAdminSettingsStore();

const state = reactive({
  company: theme?.style.branding.COMPANY_NAME,
  version: version,
});

const { t } = useI18n();
const $q = useQuasar();
const privacyPolicy = computed(() => adminSettingsStore.privacyPolicy);
const terms = computed(() => adminSettingsStore.terms);
</script>
