<template>
  <q-drawer
    v-model="model"
    :width="300"
    :breakpoint="500"
    content-class="bg-grey-3"
  >
    <q-scroll-area
      style="
        height: calc(100% - 150px);
        margin-top: 150px;
        border-right: 1px solid #ddd;
      "
    >
      <q-list>
        <template v-for="(navItem, index) in userNavItems" :key="index">
          <q-separator v-if="navItem.separator" :key="'sep' + index" />

          <q-item
            clickable
            :to="navItem.link || undefined"
            :active="!!navItem.link && route.path.includes(navItem.link)"
            :exact="navItem.exact"
            :active-class="'bg-accent text-white'"
            @click="navItem.action && navItem.action()"
          >
            <q-item-section avatar>
              <q-icon :name="navItem.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ navItem.label }}
                <q-badge
                  v-if="navItem.badge"
                  rounded
                  color="red"
                  :label="navItem.badge"
                />
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-scroll-area>
    <div
      class="absolute-top bg-secondary q-pa-md text-white"
      style="height: 150px"
    >
      <div class="bg-transparent">
        <q-avatar
          size="56px"
          class="q-mb-sm"
          :color="auth?.user.hasAvatar ? undefined : 'accent'"
        >
          <img
            v-if="auth?.user?.hasAvatar"
            :src="`${serverUrl}/users/${auth?.user._id}/avatar`"
          />
          <template v-else-if="auth?.user?.firstName">
            <span class="text-grey-7"
              >{{ auth.user.firstName.slice(0, 1)
              }}{{ auth.user.lastName.slice(0, 1) }}</span
            >
          </template>
        </q-avatar>
        <div class="text-weight-bold">
          {{ auth?.user.firstName }} {{ auth?.user.lastName }}
        </div>
        <div>{{ auth?.user.email }}</div>
      </div>
    </div>
  </q-drawer>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useCoreStore } from '../../stores/core-store';
import { computed } from 'vue';
import { ORG_TYPE_TYPE } from '../../@types/organisation-type';
import getServerUrl from 'src/utils/getServerUrl';
import { NOTIFICATION } from '../../@types/notification';

const model = defineModel<boolean>({ default: false, required: true });
const props = defineProps<{
  notifications: NOTIFICATION[] | null;
}>();

const { t } = useI18n();
const route = useRoute();

const coreStore = useCoreStore();
const { logout, auth } = coreStore;
const { userOrgType } = storeToRefs(coreStore);

const serverUrl = getServerUrl();

interface NavItem {
  label: string;
  icon: string;
  link?: string;
  exact?: boolean;
  separator?: boolean;
  action?: () => void;
  badge?: number;
  businessOnly?: boolean;
}

const navItems: NavItem[] = [
  {
    label: t('titles.home'),
    icon: 'fal fa-home',
    link: '/home',
    exact: true,
    separator: false,
  },
  {
    link: '/notifications',
    icon: 'fal fa-bell',
    label: t('notifications.featureName'),
    badge: props.notifications?.filter((x) => !x.dismissed).length,
  },
  {
    label: t('titles.profile'),
    icon: 'fal fa-user',
    link: '/profile',
    separator: false,
  },
  {
    link: '/my-organisation',
    icon: 'fal fa-building',
    label: t('titles.myOrganisation'),
    businessOnly: true,
  },
  {
    link: '/downloaded',
    icon: 'fal fa-download',
    label: t('titles.downloaded'),
  },
  {
    label: 'Logout',
    icon: 'fal fa-sign-out-alt',
    action: logout,
    exact: true,
    separator: true,
  },
];

const isBusinessUser = computed(
  () => userOrgType?.value !== ORG_TYPE_TYPE.Individual
);

const userNavItems = computed(() =>
  navItems.filter((x) => {
    return isBusinessUser.value ? true : !x.businessOnly;
  })
);
</script>
