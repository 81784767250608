<template>
  <q-fab-action
    :disable="!isMobileApp"
    label="Toggle network"
    :icon="isNetworkConnected ? 'fal fa-wifi' : 'fal fa-wifi-slash'"
    color="primary"
    external-label
    label-position="left"
    @click="
      () => {
        isNetworkConnected = !isNetworkConnected;
        DEV_network_override = !DEV_network_override;
      }
    "
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCoreStore } from 'src/stores/core-store';
import checkIsMobileApp from '../../utils/checkIsMobileApp';
import { computed } from 'vue';

const isMobileApp = computed(checkIsMobileApp);

const coreStore = useCoreStore();
const { isNetworkConnected, DEV_network_override } = storeToRefs(coreStore);
</script>
