<template>
  <q-drawer
    v-model="drawerOpen"
    :breakpoint="500"
    side="left"
    bordered
    persistent
    show-if-above
    :width="350"
    class="print-hide print-invisible"
    @input="$emit('toggleSidebar')"
  >
    <q-tree
      id="toc-tree"
      v-model:selected="tocSelection"
      v-model:expanded="tocExpansion"
      :nodes="(filteredToc as any)"
      :default-expand-all="filteredToc.length === 1"
      :no-nodes-label="t('documents.emptyToc')"
      :no-results-label="t('documents.emptyToc')"
      node-key="id"
      :selected-color="activeTextColour"
      color="primary"
      no-selection-unset
      :dense="quasar.screen.lt.sm && !checkIsMobileApp()"
      no-transition
      @update:selected="onSelectTopic"
    >
      <template #default-header="prop">
        <div
          class="col ellipsis"
          style="letter-spacing: -0.02em"
          :class="{ 'text-bold': prop.node.id === tocSelection }"
        >
          {{ prop.node.title }}
          <q-tooltip :delay="600" class="text-body2">{{
            prop.node.title
          }}</q-tooltip>
          <span v-if="hasModelsAffectedMeta(prop)">
            <br />
            <small class="text-caption">{{
              prop.node.metadata['custom-meta-ModelsAffected2']
            }}</small>
          </span>
        </div>
        <div class="col-auto">
          <q-btn
            :color="prop.node.id === tocSelection ? 'white' : 'grey-5'"
            size="5px"
            dense
            flat
            icon="far fa-external-link-square-alt"
            @click.stop="onOpenTopicAsTab(prop.node.id, prop.node.key)"
          >
            <q-tooltip :delay="300">{{
              t('documents.openInNewTab')
            }}</q-tooltip>
          </q-btn>
        </div>
      </template>
    </q-tree>
  </q-drawer>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, inject } from 'vue';
import { useQuasar, QTreeNode, EventBus, QTree } from 'quasar';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useDocumentViewerStore } from 'src/stores/document-viewer';
import { storeToRefs } from 'pinia';
import { useTextColor } from 'src/composables/useTextColor';
import checkIsMobileApp from '../../utils/checkIsMobileApp';
import { watch } from 'vue';

defineEmits(['toggleSidebar']);

const documentViewerStore = useDocumentViewerStore();
const { tocSelection, tocExpansion, filteredToc } =
  storeToRefs(documentViewerStore);

const route = useRoute();
const quasar = useQuasar();
const { t } = useI18n();
const eventBus: EventBus | undefined = inject('event-bus');

const drawerOpen = ref(false);

const toggleDrawer = () => {
  drawerOpen.value = !drawerOpen.value;
};

watch(tocSelection, () => {
  if (drawerOpen.value && checkIsMobileApp()) {
    toggleDrawer();
  }
});

const hasModelsAffectedMeta = (prop: QTreeNode) => {
  return prop.node?.metadata?.['custom-meta-ModelsAffected2'];
};

const activeTextColour = useTextColor('accent');

/* ------------------------------- Navigation ------------------------------- */
const onSelectTopic = (topicId: string) => {
  documentViewerStore.replaceCurrentTab(topicId);
};
const onOpenTopicAsTab = (topicId: string, _topicKey: string) => {
  documentViewerStore.addNewTab(route.params.id.toString(), topicId);
};

/* ----------------------------- Lifecycle Hooks ---------------------------- */
onMounted(async () => {
  eventBus?.on('toggleToc', toggleDrawer);
});

onBeforeUnmount(() => {
  eventBus?.off('toggleToc');
  tocExpansion.value = [];
  tocSelection.value = undefined;
});
</script>

<style>
#toc-tree .q-tree__node--selected {
  background-color: var(--q-accent) !important;
}
</style>
