import { MARKET } from 'src/@types/market';
import { Market } from './entities/market';

const processMarket = (market: MARKET) => {
  const processedMarket: Partial<Market> = {
    ...market,
    subMarkets: JSON.stringify(market.subMarkets),
  };

  return processedMarket;
};

export const upsertOfflineMarkets = async (markets: MARKET[]) => {
  const res = await Market.upsert(markets.map(processMarket), {
    conflictPaths: ['_id'],
  });

  return res;
};

export const upsertOfflineMarket = async (doc: MARKET) => {
  let res = {};

  const upsertDoc: Partial<Market> = {
    ...doc,
    subMarkets: JSON.stringify(doc.subMarkets),
  };

  res = await Market.upsert(upsertDoc, {
    conflictPaths: ['_id'],
  });

  return res;
};

export const getAllofflineMarkets = async (): Promise<MARKET[]> => {
  const res = await Market.find();

  return res.map((entry) => {
    return { ...entry, subMarkets: JSON.parse(entry.subMarkets) };
  });
};
