import { TOPIC } from 'src/@types/topic';
import { Topic } from './entities/topic';

interface IProcessedTopic {
  _id: string;
  id: string;
  parentDocument: string;
  stringifiedContent: string;
}

const processTopic = (topic: TOPIC): IProcessedTopic => {
  const pTopic = {
    _id: topic._id,
    id: topic.id,
    parentDocument: topic.parentDocument,
    stringifiedContent: JSON.stringify(topic),
  };

  return pTopic;
};

const unprocessTopic = (processedTopic: Topic): TOPIC => {
  const topic = JSON.parse(processedTopic.stringifiedContent);
  return { ...topic };
};

export const upsertOfflineTopic = async (doc: TOPIC) => {
  const res = await Topic.upsert(processTopic(doc), {
    conflictPaths: [],
  });

  return res;
};

export const getAllOfflineTopic = async () => {
  const res = await Topic.find();

  return res;
};

export const getOfflineTopicById = async (
  documentId: string,
  topicId: string
) => {
  const res = (await Topic.findOneBy({
    id: topicId,
    parentDocument: documentId,
  })) as Topic;

  return unprocessTopic(res);
};
