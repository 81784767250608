export enum LANGUAGE_CODE {
  ar_sa = 'ar-sa',
  cs_cz = 'cs-cz',
  de_de = 'de-de',
  en_gb = 'en-gb',
  en_us = 'en-us',
  es_es = 'es-es',
  fi_fi = 'fi-fi',
  fr_fr = 'fr-fr',
  it_it = 'it-it',
  ja_jp = 'ja-jp',
  nl_nl = 'nl-nl',
  pt_br = 'pt-br',
  pt_pt = 'pt-pt',
  sv_se = 'sv-se',
  th_th = 'th-th',
  zh_cn = 'zh-cn',
}

export type LANGUAGE_CONTENT_ITEM = Partial<Record<LANGUAGE_CODE, string>>;
