import { defineStore } from 'pinia';
import { rocumentsApi } from 'src/boot/axios';
import { LANGUAGE_CODE } from 'src/@types/languages';
import { NOTIFICATION } from 'src/@types/notification';
import { popToast } from 'src/utils/toasts';
import apiErrorHandler from '../utils/exceptions/api-error-handler';
import { ref, computed } from 'vue';
import { useCoreStore } from './core-store';

export interface NOTIFICATION_CONTROL {
  id: number;
  identifier: string;
  optional: boolean;
  title: string;
  active: boolean;
}

export interface State {
  notifications: NonNullable<NOTIFICATION[]>;
  notificationControls: NonNullable<NOTIFICATION_CONTROL[]>;
}

export const useNotificationsStore = defineStore('notifications', () => {
  const coreStore = useCoreStore();
  const isNetworkConnected = computed(() => coreStore.isNetworkConnected);

  const notifications = ref<NOTIFICATION[]>([]);
  const notificationControls = ref<NOTIFICATION_CONTROL[]>([]);

  const loadNotificationControls = async (
    lang: LANGUAGE_CODE = LANGUAGE_CODE.en_gb
  ) => {
    try {
      if (!isNetworkConnected.value) {
        return;
      }

      const { data } = await rocumentsApi.get(
        `/notifications/config?lang=${lang}`
      );

      notificationControls.value = Object.keys(data).map(
        (item: string, index: number) => {
          return {
            id: index,
            identifier: item,
            optional: data[item].optional,
            title: data[item].subject,
            active: true,
          };
        }
      );
    } catch (err) {
      apiErrorHandler(err);
    }
  };

  const loadUserNotifications = async () => {
    try {
      if (!isNetworkConnected.value) {
        return;
      }

      const { data } = await rocumentsApi.get<NOTIFICATION[]>('/notifications');

      notifications.value = data;
    } catch (err) {
      apiErrorHandler(err);
    }
  };

  const toggleNotificationReadState = async (
    _id: string,
    dismissed: boolean
  ) => {
    try {
      notifications.value = notifications.value.map((n) => ({
        ...n,
        dismissed: n._id === _id ? dismissed : n.dismissed,
      }));

      if (!isNetworkConnected.value) {
        popToast('positive');
      }

      await rocumentsApi.patch(`/notifications/${_id}`, { dismissed });
      await loadUserNotifications();
    } catch (err) {
      apiErrorHandler(err);
    }
  };

  const markAllNotificationsRead = async () => {
    try {
      notifications.value = notifications.value.map((n) => ({
        ...n,
        dismissed: true,
      }));

      if (!isNetworkConnected.value) {
        popToast('positive');
      }

      await rocumentsApi.patch<Partial<NOTIFICATION[]>>('/notifications');
      popToast('positive');
      await loadUserNotifications();
    } catch (err) {
      apiErrorHandler(err);
    }
  };

  const deleteNotification = async (id: string) => {
    try {
      notifications.value = notifications.value.filter((n) => n._id !== id);

      if (!isNetworkConnected.value) {
        popToast('positive');
      }

      await rocumentsApi.delete(`/notifications/${id}`);
      popToast('positive');
      await loadUserNotifications();
    } catch (err) {
      apiErrorHandler(err);
    }
  };

  const deleteAllNotifications = async () => {
    try {
      notifications.value = [];

      if (!isNetworkConnected.value) {
        popToast('positive');
      }

      await rocumentsApi.delete('/notifications');
      popToast('positive');
      await loadUserNotifications();
    } catch (err) {
      apiErrorHandler(err);
    }
  };

  return {
    notifications,
    notificationControls,
    loadNotificationControls,
    loadUserNotifications,
    toggleNotificationReadState,
    markAllNotificationsRead,
    deleteNotification,
    deleteAllNotifications,
  };
});
