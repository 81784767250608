import { defineStore } from 'pinia';
import { rocumentsApi } from 'src/boot/axios';
import { logger } from 'src/boot/logger';
import {
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import Values from 'values.js';
import { colors } from 'quasar';

const flatten: any = (ary: any[]) => {
  return ary.reduce(function (a: string | any[], b: any) {
    if (Array.isArray(b)) {
      return a.concat(flatten(b));
    }
    return a.concat(b);
  }, []);
};

export enum ANALYTICS_CATEGORY {
  Users = 'users',
  Documents = 'documents',
  Feedback = 'feedback',
  Subscriptions = 'subscriptions',
  Products = 'products',
}

export interface State {
  mostPopularProducts: unknown[] | null;
  tab: ANALYTICS_CATEGORY;
  dateRange: NonNullable<{ from: string; to: string }> | string | null;
  subscribers: NonNullable<unknown[]> | null;
}

// interface AnalyticsRequestOptions {
//   from: Data;
//   to: string;
// }

export const useAnalyticsStore = defineStore('analytics', {
  state: (): State => ({
    mostPopularProducts: [],
    tab: ANALYTICS_CATEGORY.Users,
    dateRange: {
      from: format(startOfMonth(new Date()), 'yyyy/MM/dd'),
      to: format(endOfMonth(new Date()), 'yyyy/MM/dd'), //Used for QDate component only. use the rangeAsDates getter to read values formatted correctly.
    },
    subscribers: [],
  }),
  getters: {
    rangeAsDates(state) {
      return state.dateRange
        ? {
            from:
              typeof state.dateRange === 'object'
                ? startOfDay(new Date(state.dateRange.from))
                : startOfDay(new Date(state.dateRange)),
            to:
              typeof state.dateRange === 'object'
                ? endOfDay(new Date(state.dateRange.to))
                : endOfDay(new Date(state.dateRange)),
          }
        : null;
    },
    colorPalette() {
      const brand = colors.getPaletteColor('accent');
      const accent = new Values(brand);
      return accent.shades(8).map((x: any) => `#${x.hex}`);
    },
  },
  actions: {
    getMostPopularProductAnalytics() {
      return rocumentsApi
        .get('/analytics/products/popular')
        .then((response) => {
          const success = response.status === 200;
          if (success) {
            const products = flatten(
              response.data.map((x: any) => x.productHistory)
            ).map((x: any) => ({
              _id: x.product._id,
              name: x.product.modelName,
              code: x.product.modelCode,
              year: x.product.modelYear,
            }));

            const convert = (arr: any[]) => {
              const res: any | { [s: string]: any } | ArrayLike<any> = [];
              arr.forEach((o: { _id: any; name: any; year: any }) => {
                const key = `${o._id}${o.name}${o.year}`;
                if (!res[key]) {
                  res[key] = { ...o, count: 0 };
                }
                res[key].count += 1;
              });
              return Object.values(res);
            };

            this.mostPopularProducts = convert(products).sort(
              (a: any, b: any) => (a.count > b.count ? -1 : 1)
            );

            return convert(products).sort((a: any, b: any) =>
              a.count > b.count ? -1 : 1
            );
          }
        })
        .catch((err) => {
          logger('error', err);
        });
    },

    async getSubscribers() {
      try {
        const users = await rocumentsApi.get('/analytics/users/subscribers');
        if (!users) return 'No users found';
        this.subscribers = users.data;
        return users.data;
      } catch (err) {
        logger('error', err);
      }
    },
  },
});
