import { Entity, Column, BaseEntity, PrimaryColumn } from 'typeorm';

@Entity()
export class Product extends BaseEntity {
  @PrimaryColumn({
    type: 'text',
    unique: true,
    nullable: false,
    primary: true,
  })
  _id: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  productId: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  productDetailId: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  product: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  productDetails: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  documents: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  documentTypes: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  documentTabs: string;
}
