<template>
  <q-layout :view="$q.screen.lt.sm ? 'hHh Lpr fff' : 'hHh Lpr fFf'">
    <q-header elevated class="print-hide" :reveal="isMobileApp">
      <OfflineBanner />
      <DownloadsBanner />
      <HeaderMenuMobile
        v-if="isMobileApp"
        v-model="showSidebar"
        :notifications="notifications"
        @toggle-sidebar="onToggleSideBar"
      />
      <HeaderMenu
        v-else
        :notifications="notifications"
        :is-pdf="isPDF"
        @toggle-sidebar="onToggleSideBar"
      />

      <VerifyHeader
        v-if="auth?.user"
        v-show="!auth?.user?.emailVerified && !isTolUser"
      />
      <SubscriptionBanner
        v-if="userRequiresSub && !validSubscriptions?.length && !isTolUser"
        :user-type="userOrgType"
      />
      <SubscriptionBannerExpires
        v-if="
          !!currentSubscriptionExpiresAt &&
          userRequiresSub &&
          !isTolUser &&
          currentSubscriptionExpiresAt < 800
        "
        :user-type="userOrgType"
        :exp-subsc-time="currentSubscriptionExpiresAt"
      />
      <TopicNav
        v-if="
          currentDocument?.format === 'application/json' &&
          route.name &&
          ['document', 'documentPreview'].includes(route.name?.toString())
        "
      />
      <AnalyticsHeader v-if="route.name === 'analytics'" />
    </q-header>

    <TocSidebar
      v-if="
        currentDocument?.format === 'application/json' &&
        route.name &&
        ['document', 'documentPreview'].includes(route.name?.toString())
      "
      class="print-hide print-invisible"
      @toggle-sidebar="onToggleSideBar"
    />
    <AdminSidebar
      v-if="
        route.path.includes('/admin') &&
        !route.path.includes('/document-preview/')
      "
      @toggle-sidebar="onToggleSideBar"
    />
    <PDFSidebar
      v-if="isPDF && showPDFSidebar"
      :tree="PDFTree"
      @select="onPDFSelect"
    ></PDFSidebar>
    <MobileNavDrawer
      v-if="isMobileApp"
      v-model="showSidebar"
      :notifications="notifications"
    />

    <q-page-container>
      <router-view
        ref="currentPage"
        @toggle-sidebar="onToggleSideBar"
        @pdf-load="onPDFLoad"
      />
    </q-page-container>

    <FooterComponent v-if="!isMobileApp" class="print-hide" />
    <MobileDevFab />
  </q-layout>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  inject,
  onUnmounted,
  ComputedRef,
} from 'vue';
import { EventBus, QTreeNode, useQuasar } from 'quasar';
import HeaderMenu from 'src/components/ui/HeaderMenu.vue';
import VerifyHeader from 'src/components/ui/VerifyHeader.vue';
import SubscriptionBanner from 'src/components/ui/SubscriptionBanner.vue';
import SubscriptionBannerExpires from 'src/components/ui/SubscriptionBannerExpires.vue';
import TopicNav from 'src/components/ui/TopicNav.vue';
import TocSidebar from 'src/components/document/TocSidebar.vue';
import AdminSidebar from 'src/components/admin/AdminSidebar.vue';
import PDFSidebar from 'src/components/document/PDFSidebar.vue';
import FooterComponent from 'src/components/FooterComponent.vue';
import AnalyticsHeader from 'src/components/ui/AnalyticsHeader.vue';
import OfflineBanner from 'src/components/ui/OfflineBanner.vue';
import { useRoute, useRouter } from 'vue-router';
import toggleLoading from 'src/utils/toggleLoading';
import { useDocumentViewerStore } from 'src/stores/document-viewer';
import { storeToRefs } from 'pinia';
import { toValue } from 'vue';
import { AUTH } from 'src/@types/auth';
import { useCoreStore } from 'src/stores/core-store';
import { useNotificationsStore } from 'src/stores/notifications';
import DownloadsBanner from 'src/components/DownloadsBanner.vue';
import { popToast } from 'src/utils/toasts';
import { useI18n } from 'vue-i18n';
import MobileDevFab from '../components/developer-toolbar/MobileDevFab.vue';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
import HeaderMenuMobile from '../components/ui/HeaderMenuMobile.vue';
import MobileNavDrawer from '../components/ui/MobileNavDrawer.vue';
import usePersistentStorage from 'src/composables/usePersistentStorage';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const $q = useQuasar();
const eventBus: EventBus | undefined = inject('event-bus');
const documentViewerStore = useDocumentViewerStore();
const { currentDocument } = storeToRefs(documentViewerStore);
const coreStore = useCoreStore();
const {
  currentSubscriptionExpiresAt,
  userRequiresSub,
  validSubscriptions,
  userOrgType,
  isLoggedIn,
} = storeToRefs(coreStore);
const notificationsStore = useNotificationsStore();

let notificationLoadTimeout: NodeJS.Timeout;
const showSidebar = ref<boolean>(false);
const PDFTree = ref<Array<QTreeNode>>([]);
const currentPage = ref(null);

const { notifications } = storeToRefs(notificationsStore);
const auth = inject<ComputedRef<AUTH | undefined>>('auth');

const isMobileApp = computed(checkIsMobileApp);

const isTolUser = computed(() => !!auth?.value?.user.externalIds?.tol);
const isPDF = computed(
  () => toValue(currentDocument)?.format === 'application/pdf'
);

const showPDFSidebar = computed(
  () => !!PDFTree.value && !!PDFTree.value.length
);

const onToggleSideBar = () => {
  showSidebar.value = !showSidebar.value;
};

const onPDFLoad = (val: any) => {
  PDFTree.value = val;
};

const onPDFSelect = (node: any) => eventBus?.emit('PDFSelect', node);

const notificationLoader = () => {
  const INTERVAL = 1000 * 60 * 5; // 3 minutes

  notificationLoadTimeout = setInterval(async () => {
    if (isLoggedIn.value) {
      await notificationsStore.loadUserNotifications();
    }
  }, INTERVAL);
};

router.afterEach(async (newRoute, oldRoute) => {
  try {
    if (newRoute.fullPath !== oldRoute.fullPath && isLoggedIn.value) {
      await notificationsStore.loadUserNotifications();
      return;
    }
  } catch (e) {
    popToast({
      type: 'negative',
      message: t('errors.failedToGetNotifications'),
    });
  }

  return;
});

onMounted(async () => {
  await toggleLoading(true);
  if (isLoggedIn.value) {
    await Promise.all([
      notificationsStore.loadUserNotifications(),
      () => {
        if (auth?.value?.user) {
          return notificationsStore.loadNotificationControls(
            auth.value.user?.preferences?.uiLang
          );
        }
      },
    ]);
  }
  await toggleLoading(false);

  notificationLoader();
});

onUnmounted(async () => {
  clearTimeout(notificationLoadTimeout);
  await usePersistentStorage(notificationsStore);
});
</script>
