import { defineStore } from 'pinia';
import { DOCUMENT_TYPE } from 'src/@types/document-type';
import { rocumentsApi } from 'src/boot/axios';
import { ref } from 'vue';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';

export const useDocumentTypesStore = defineStore('documentTypes', () => {
  const documentTypes = ref<DOCUMENT_TYPE[]>([]);
  const loadDocumentTypes = async () => {
    try {
      const { data } = await rocumentsApi.get<DOCUMENT_TYPE[]>(
        '/document-types'
      );
      documentTypes.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const createDocumentType = async (payload: Partial<DOCUMENT_TYPE>) => {
    try {
      await rocumentsApi.post<DOCUMENT_TYPE>('/document-types', payload);
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const documentType = ref<DOCUMENT_TYPE>();
  const getDocumentType = async (id: string) => {
    try {
      const { data } = await rocumentsApi.get<DOCUMENT_TYPE>(
        `/document-types/${id}`
      );
      documentType.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const updateDocumentType = async (payload: Partial<DOCUMENT_TYPE>) => {
    const { _id, ...updates } = payload;
    try {
      const { data } = await rocumentsApi.patch(
        `/document-types/${_id}`,
        updates
      );
      documentType.value = data;
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  const deleteDocumentType = async (id: string) => {
    try {
      await rocumentsApi.delete(`/document-types/${id}`);
      await loadDocumentTypes();
    } catch (error) {
      apiErrorHandler(error, false);
    }
  };

  return {
    documentTypes,
    loadDocumentTypes,
    createDocumentType,
    documentType,
    getDocumentType,
    updateDocumentType,
    deleteDocumentType,
  };
});
