import { defineStore } from 'pinia';
import { MARKET } from 'src/@types/market';
import { rocumentsApi } from 'src/boot/axios';
import { ref } from 'vue';
import apiErrorHandler from '../utils/exceptions/api-error-handler';
import { popToast } from 'src/utils/toasts';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import * as offlineMarketActions from 'src/sqlite-database/offline-market-actions';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';

export const useMarketsStore = defineStore('markets', () => {
  const route = useRoute();
  const router = useRouter();
  const markets = ref<MARKET[]>([]);
  const market = ref<MARKET | null>(null);

  const { t } = useI18n();

  const loadAllMarkets = async () => {
    try {
      const { data } = await rocumentsApi.get<MARKET[]>('/markets');

      if (!data) {
        throw new Error('Failed to load markets');
      }

      markets.value = data;

      if (checkIsMobileApp()) {
        await offlineMarketActions.upsertOfflineMarkets(data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status === 401 &&
          route.name &&
          ['fluidsDocViewer', 'tyresDocViewer'].includes(route.name?.toString())
        ) {
          router.push('/401');
        }
      } else {
        apiErrorHandler(
          error,
          true,
          t('errors.failedToLoadArg', [t('entityTypes.markets')])
        );
      }
    }
  };

  const loadAllOfflineMarkets = async () => {
    try {
      markets.value = await offlineMarketActions.getAllofflineMarkets();
    } catch (error) {
      popToast({
        type: 'negative',
        message: t('errors.failedToLoadArg', [t('entityTypes.markets')]),
      });
    }
  };

  const getSingleMarket = async (id: string) => {
    try {
      const { data } = await rocumentsApi.get<MARKET>(`/markets/${id}`);
      market.value = data;
      return data;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.market')])
      );
    }
  };

  const createMarket = async (payload: MARKET) => {
    try {
      const { data } = await rocumentsApi.post('/markets', payload);
      popToast('positive');
      return data;
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.market')])
      );
    }
  };

  const updateMarket = async (payload: Partial<MARKET>) => {
    try {
      await rocumentsApi.patch(`/markets/${payload._id}`, payload);
      popToast('positive');
      await loadAllMarkets();
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.market')])
      );
    }
  };

  const deleteMarket = async (id: string) => {
    try {
      await rocumentsApi.delete(`/markets/${id}`);
      popToast('positive');
      await loadAllMarkets();
    } catch (err) {
      apiErrorHandler(
        err,
        true,
        t('errors.failedToLoadArg', [t('entityTypes.market')])
      );
    }
  };

  return {
    markets,
    market,
    loadAllMarkets,
    loadAllOfflineMarkets,
    getSingleMarket,
    createMarket,
    updateMarket,
    deleteMarket,
  };
});
