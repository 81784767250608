import { Notify, QNotifyCreateOptions } from 'quasar';
import { i18n } from 'src/boot/i18n';

export type POP_TOAST_OPTIONS =
  | Partial<QNotifyCreateOptions>
  | 'positive'
  | 'negative';

export function popToast(payload: POP_TOAST_OPTIONS) {
  if (typeof payload === 'string') {
    Notify.create({
      type: payload,
      message:
        payload === 'positive'
          ? i18n.global.t('success')
          : i18n.global.t('failed'),
      position: 'top',
      timeout: payload === 'positive' ? 1500 : 7000,
    });
  } else {
    Notify.create({
      type: payload.type,
      message: payload.message,
      position: payload.position || 'top',
      timeout: payload.type === 'positive' ? 1500 : 7000,
    });
  }
  // Show a trace of where the toast was called from (only in dev mode)
  if (process.env.DEV && !process.env.NO_TOAST_TRACE) {
    // eslint-disable-next-line no-console
    console.trace('Crumbs');
  }
}
