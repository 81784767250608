import { boot } from 'quasar/wrappers';

const getBgColor = async (style: any) =>
  style.colors?.PREAUTH_BG_COLOR
    ? `bg-${style.colors.PREAUTH_BG_COLOR}`
    : undefined;

export interface Theme {
  style: {
    colors: Record<string, string>;
    styling: Record<string, string>;
    branding: Record<string, string>;
  };
  bgColor: string | undefined;
  // scssVariables: Record<string, string>;
}

// you should always try to use injected style object if possible
// since it's already loaded and processed
export const loadTheme = async () => await import('src/themes/index');

export default boot(async ({ app }) => {
  const theme = await loadTheme();
  app.provide('$theme', {
    style: await theme.getStyle(),
    bgColor: await getBgColor(await theme.getStyle()),
    // scssVariables: await theme.getScssVariables(),
  });
});
