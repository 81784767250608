import { Loading, QSpinnerGears } from 'quasar';
import { loadTheme } from 'src/boot/theme';
import SpinnerComponent from 'src/components/ui/SpinnerComponent.vue';

export default async function toggleLoading(
  loadingState: boolean | { state: boolean; message: string; group?: string }
) {
  const withMessage = typeof loadingState === 'object';
  const action = withMessage ? loadingState.state : loadingState;
  const { styling } = await (await loadTheme()).getStyle();

  action
    ? Loading.show({
        delay: 200,
        html: withMessage,
        group: withMessage ? loadingState.group : undefined,
        message: withMessage
          ? `<span class="text-h6">${loadingState.message}</span>`
          : undefined,
        spinner: styling.CUSTOM_SPINNER ? SpinnerComponent : QSpinnerGears,
        spinnerColor: 'accent',
        spinnerSize: styling.CUSTOM_SPINNER ? 300 : 100,
      })
    : Loading.hide();
}
