<template>
  <!-- TODO make it slightly narrower than a screen, to appear lighter -->
  <q-dialog
    v-model="isDialogOpen"
    seamless
    position="bottom"
    class="shadow-up-3 q-pr-sm"
  >
    <transition
      appear
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div v-if="isExpanded" class="shadow-up-3 bg-grey-3 items-center no-wrap">
        <div v-for="doc of documentsInDownloadQueue" :key="doc.id">
          <transition
            appear
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <q-card>
              <q-card-section class="row items-center no-wrap">
                <q-spinner color="primary" size="1em" class="q-mr-sm" />
                {{ doc.title }}

                <q-space />

                <q-btn
                  v-if="doc.inProgress"
                  color="primary"
                  round
                  :disable="!isNetworkConnected"
                  icon="cancel"
                  @click.stop="onCancelClick(doc)"
                />

                <div v-if="!doc.inProgress" class="q-ml-sm">
                  Finished <q-icon size="1.2rem" name="check" />
                </div>
              </q-card-section>
            </q-card>
          </transition>
        </div>
      </div>
    </transition>

    <q-card class="bg-grey-2">
      <q-card-section class="row items-center no-wrap" @click="toggleExpansion">
        <div>Downloading {{ documentsInDownloadQueue.length }} documents</div>
        <q-space />
        <q-icon v-if="!isExpanded" name="keyboard_arrow_up" size="1.5rem" />
        <q-icon v-if="isExpanded" name="keyboard_arrow_down" size="1.5rem" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useDocumentStore } from 'src/stores/documents';
import { computed, ref, watch } from 'vue';
import { useCoreStore } from 'src/stores/core-store';
import { DOCUMENT } from 'src/@types/document';

const documentStore = useDocumentStore();
const { documentsInDownloadQueue } = storeToRefs(documentStore);
const coreStore = useCoreStore();
const { isNetworkConnected } = storeToRefs(coreStore);

const isExpanded = ref(false);
const toggleExpansion = () => (isExpanded.value = !isExpanded.value);

// TODO this should come from elsewhere, since it's used in DocumentsTable.vue too
interface IExtendedDocument extends DOCUMENT {
  inProgress?: boolean;
  isDownloaded?: boolean;
  cancelRequest: () => void;
}

const onCancelClick = (doc: IExtendedDocument) => {
  if (doc.inProgress && doc.cancelRequest) {
    doc.cancelRequest();
    return;
  }
};

const isDialogOpen = computed<boolean>(
  () => !!documentsInDownloadQueue.value.length
);

watch(
  () => isDialogOpen.value,
  () => {
    if (!isDialogOpen.value) {
      isExpanded.value = false;
    }
  }
);
</script>
