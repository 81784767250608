import { BASE } from './base';
import { CURRENCY_CODE } from './currency-codes';
import { LANGUAGE_CODE } from './languages';
import { POPULATED_ORGANISATION } from './organisation';
import { VIEW_MODE } from './organisation-type';
export interface BOOKMARK {
  createdAt: Date;
  document: string;
  topic: string;
  topicId: string;
  documentTitle: string;
  topicTitle: string;
}

export enum ROLE {
  admin = 'admin',
  document_admin = 'document_admin',
  subscription_admin = 'subscription_admin',
  user_admin = 'user_admin',
  consumer = 'consumer',
}

export interface EXTERNAL_PROFILE {
  id: string;
  fullname: string;
  jobTitle: string;
  dealershipId: string;
  userTypeId: number; //TODO Add enum for TRM/TOL userTypes
  emailAddress: string;
  address: {
    name: string;
    postalAddress: string;
    municipality: string;
    region: string;
    postalCode: string;
    countryId: string; //TODO create enum for TRM/TOL countryIds
  };
  languageId: string; //TODO create enum for TRM/TOL languageIds
}

export interface ProductHistory {
  date: string;
  product: {
    displayName: string;
    modelName: string;
    modelYear: string;
    serialNumber: string;
    _id: string;
  };
}

export interface NOTIFICATION_OPTION {
  id?: number; //TODO Review use - this should not be needed
  identifier: string;
  optional?: boolean;
  title?: string; //TODO Review use - this should not be needed
  active: boolean;
}

export interface USER_NOTIFICATION_PREFERENCES {
  currency?: CURRENCY_CODE;
  detail?: VIEW_MODE;
  documentLang?: LANGUAGE_CODE;
  uiLang?: LANGUAGE_CODE;
  bookmarks?: BOOKMARK[];
  notifications?: NOTIFICATION_OPTION[];
}

export interface USER extends BASE {
  attempts?: number;
  firstName: string;
  lastName: string;
  email: string;
  externalIds?: {
    google: string;
    ms: string;
    tol: string;
    tolDealer: string;
  };
  externalProfile?: EXTERNAL_PROFILE;
  apiKey: string;
  avatar?: string | File | Buffer;
  deleted?: boolean;
  active: boolean;
  stripeCustomerId?: string;
  stripeSessionId?: string;
  role: ROLE;
  organisation: string;
  preferences?: USER_NOTIFICATION_PREFERENCES;
  productHistory: ProductHistory[];
  termsAccepted?: string;
  isLegalRepresentative?: boolean;
  resetToken?: string;
  expireResetToken?: Date;
  // Email Change
  pendingEmail?: string;
  emailToken?: string;
  expireEmailToken?: Date;
  // New User Email Verification
  emailVerificationToken: string;
  emailVerified: boolean;
  accessTokens: unknown[];
  metadata: unknown;
  hasAvatar?: boolean;
  url?: string;
}

export interface POPULATED_USER extends Omit<USER, 'organisation'> {
  organisation: POPULATED_ORGANISATION;
}
