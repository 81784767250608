<template>
  <q-header elevated>
    <OfflineBanner />

    <q-toolbar>
      <q-toolbar-title v-if="!props.hideLogo" shrink>
        <router-link to="/">
          <img
            v-if="headerLogoPath"
            :src="getThemedAssetPath('header-logo.svg')"
            :alt="t('productTitle')"
            :style="$q.screen.gt.xs ? 'height: 35px' : 'height: 32px'"
            class="block"
          />
          <span v-else class="text-white">{{ t('productTitle') }}</span>
        </router-link>
      </q-toolbar-title>

      <q-space />

      <q-btn
        v-if="route.path !== '/'"
        :label="t('buttons.login')"
        to="/"
        flat
        stretch
      />

      <q-btn-dropdown
        icon="fas fa-language"
        flat
        stretch
        :label="getLangTranslation(locale, false)"
      >
        <q-list>
          <q-item
            v-for="lang of uiLangs"
            :key="lang"
            v-close-popup
            clickable
            @click="onSelectLanguage(lang)"
          >
            <q-item-section>
              <q-item-label>{{ getLangTranslation(lang, true) }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-toolbar>
  </q-header>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBrowserLangFormat } from 'src/composables/useBrowserLangFormat';
import { useQuasar } from 'quasar';
import { useRoute } from 'vue-router';
import OfflineBanner from 'src/components/ui/OfflineBanner.vue';
import { getThemedAssetPath } from 'src/themes/index';

const props = withDefaults(defineProps<{ hideLogo: boolean }>(), {
  hideLogo: false,
});

const i18n = useI18n();
const { t, locale } = i18n;
const $q = useQuasar();
const route = useRoute();
const { getLangTranslation, onSelectLanguage } = useBrowserLangFormat();

const uiLangs = computed(() => i18n.availableLocales);
const headerLogoPath = computed(() => getThemedAssetPath('header-logo.svg'));
</script>
