<template>
  <q-btn
    flat
    stretch
    :icon="bookmarked ? 'fad fa-bookmark' : 'far fa-bookmark'"
    :dense="isMobileApp"
    @click="whichCall"
  >
    <q-tooltip>
      {{
        bookmarked
          ? t('profile.preference.bookmarks.removeBookmark')
          : t('profile.preference.bookmarks.bookmark')
      }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { POPULATED_TOPIC } from 'src/@types/topic';
import { DOCUMENT } from 'src/@types/document';
import { useDocumentViewerStore } from 'src/stores/document-viewer';
import { storeToRefs } from 'pinia';
import { toValue } from 'vue';
import { popToast } from 'src/utils/toasts';
import { popDialog } from 'src/utils/dialogs';
import { useBookmarksStore } from 'src/stores/bookmarks';
import checkIsMobileApp from '../../utils/checkIsMobileApp';

const props = defineProps<{
  bookmark: null | IBookmark;
}>();

interface IBookmark {
  document?: DOCUMENT;
  topic?: POPULATED_TOPIC;
  urlStr?: string;
}

const documentViewerStore = useDocumentViewerStore();
const { currentTopic } = storeToRefs(documentViewerStore);
const bookmarksStore = useBookmarksStore();
const { bookmarks } = storeToRefs(bookmarksStore);
const router = useRouter();
const { t } = useI18n();
const isMobileApp = computed(checkIsMobileApp);

const bookmarked = computed(() => {
  return bookmarks.value?.find(
    (item) =>
      item?.document === props.bookmark?.document?._id &&
      item?.topic === props.bookmark?.topic?._id
  );
});

const whichCall = () => (bookmarked.value ? onDelete() : onBookmark());

const onBookmark = async () => {
  const bookmark = props.bookmark;

  if (!bookmark || !bookmark.document || !bookmark.topic) {
    return;
  }

  const payload = {
    document: bookmark.document._id,
    topic: bookmark.topic._id,
    topicId: bookmark.topic.id,
    topicTitle: bookmark.topic.title || '',
    documentTitle: bookmark.document.title,
    urlStr: router.currentRoute.value.path,
  };

  if (await bookmarksStore.updateUserBookmarks(payload)) {
    popToast('positive');
  }
};

const onDelete = async () => {
  const updatedBookmarks = bookmarks.value.filter(
    (item) =>
      item.topic !== toValue(currentTopic)?._id &&
      item.document !== toValue(currentTopic)?.parentDocument?._id
  );

  popDialog({
    title: t('profile.preference.bookmarks.dialog.title'),
    message: t('profile.preference.bookmarks.dialog.message'),
    submit: t('buttons.ok'),
    cancel: t('buttons.cancel'),
  }).onOk(async () => {
    if (await bookmarksStore.deleteUserBookmark(updatedBookmarks)) {
      popToast('positive');
    }
  });
};

onBeforeMount(async () => {
  await bookmarksStore.getAllUserBookmarks();
});
</script>
