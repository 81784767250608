<template>
  <q-page-sticky v-if="isMobileApp" position="bottom-right" :offset="[18, 18]">
    <q-fab
      color="accent"
      icon="keyboard_arrow_up"
      direction="up"
      label="Dev tools"
    >
      <NetworkConnectedToggle />
      <SqliteActions />
    </q-fab>
  </q-page-sticky>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import checkIsMobileApp from '../../utils/checkIsMobileApp';
import NetworkConnectedToggle from './NetworkConnectedToggle.vue';
import SqliteActions from './SqliteActions.vue';

const isMobileApp = computed(checkIsMobileApp);
</script>
