import { defineStore } from 'pinia';
import { rocumentsApi } from 'src/boot/axios';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { i18n } from 'src/boot/i18n';
import { BOOKMARK } from 'src/@types/user';

const { t } = i18n.global;

export interface State {
  bookmarks: BOOKMARK[];
}

export const useBookmarksStore = defineStore('bookmarks', {
  state: (): State => ({
    bookmarks: [],
  }),
  actions: {
    async updateUserBookmarks(payload: {
      document: string;
      topic: string;
      topicId: string;
      topicTitle: string;
      documentTitle: string;
      urlStr: string;
    }): Promise<boolean | undefined> {
      try {
        await rocumentsApi.patch('/users/current/bookmarks', payload);
        await this.getAllUserBookmarks();
        return true;
      } catch (err) {
        apiErrorHandler(
          err,
          true,
          t('errors.failedToUpdateArg', [t('entityTypes.bookmarks')])
        );
      }
    },

    async getAllUserBookmarks() {
      try {
        const { data } = await rocumentsApi.get('/users/current/bookmarks');
        this.bookmarks = data;
      } catch (err) {
        apiErrorHandler(
          err,
          true,
          t('errors.failedToLoadArg', [t('entityTypes.bookmarks')])
        );
      }
    },

    async deleteUserBookmark(
      bookmarks: BOOKMARK[]
    ): Promise<boolean | undefined> {
      try {
        await rocumentsApi.patch('/users/remove/bookmarks', bookmarks);
        await this.getAllUserBookmarks();
        return true;
      } catch (err) {
        apiErrorHandler(
          err,
          true,
          t('errors.failedToDeleteArg', [t('entityTypes.bookmark')])
        );
      }
    },
  },
});
