import { BASE } from './base';

export enum VIEW_MODE {
  Standard = 'standard',
  Detailed = 'detailed',
  Overview = 'overview',
}

export type VIEW_MODE_OPTIONS = Record<VIEW_MODE, boolean>;

export enum ORG_TYPE_TYPE {
  Individual = 'individual',
  IO = 'independent_operator',
  AuthorisedRepairer = 'authorised_repairer',
  Distributor = 'distributor',
  Manufacturer = 'manufacturer',
}

export interface ORGANISATION_TYPE extends BASE {
  name: string;
  _type: ORG_TYPE_TYPE;
  requiresSub: boolean;
  diagnostics: boolean;
  // TODO Make this prop generic - it is only required for the TOL integration
  canAddService: boolean;
  enabledViewModes: VIEW_MODE_OPTIONS;
}
