import { useCoreStore } from 'src/stores/core-store';
import { Router } from 'vue-router';
import { storeToRefs } from 'pinia';
import { Preferences } from '@capacitor/preferences';
import { AUTH } from 'src/@types/auth';
import { rocumentsApi } from 'src/boot/axios';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import checkIsMobileApp from '../utils/checkIsMobileApp';

export default function useLocalLogin() {
  const coreStore = useCoreStore();
  const { isNetworkConnected, currentUserEmail } = storeToRefs(coreStore);

  const login = async (
    credentials: { email: string; password: string; remember: boolean },
    redirect: any,
    router: Router
  ) => {
    try {
      let response;

      if (checkIsMobileApp()) {
        if (isNetworkConnected.value) {
          response = await handleOnlineLogin(credentials);

          // if response fails dont store local
          if (response) {
            await updateLocalUsers(
              credentials.email,
              credentials.password,
              response.data
            );
          }
        } else {
          response = await handleOfflineLogin(credentials);
        }
      } else {
        response = await handleOnlineLogin(credentials);
      }

      router.push(redirect || '/home');
    } catch (err) {
      apiErrorHandler(err);
      return { error: err.message || 'An error occurred during login.' };
    }
  };

  async function getLocalUsers() {
    const localUsersString = (await Preferences.get({ key: 'localUsers' }))
      .value;
    return localUsersString ? JSON.parse(localUsersString) : {};
  }

  async function updateLocalUsers(email: string, password: string, user: any) {
    const localUsers = await getLocalUsers();
    localUsers[email] = { ...localUsers[email], password, user }; // Assuming encryption of password will be handled
    await Preferences.set({
      key: 'localUsers',
      value: JSON.stringify(localUsers),
    });
  }

  async function updateLocalUserAuth(email: string, auth: any) {
    const localUsers = await getLocalUsers();
    localUsers[email] = { ...localUsers[email], auth: auth };

    await Preferences.set({
      key: 'localUsers',
      value: JSON.stringify(localUsers),
    });
  }

  async function handleUserAuth() {
    if (!checkIsMobileApp()) {
      const response = await rocumentsApi.get<AUTH>('/auth');
      return response.data;
    } else {
      if (isNetworkConnected.value) {
        const response = await rocumentsApi.get<AUTH>('/auth');
        await updateLocalUserAuth(currentUserEmail.value, response.data);
        return response.data;
      }

      const localUsers = (await Preferences.get({ key: 'localUsers' })).value;
      let localUsersRet: any = {};

      if (localUsers && !isNetworkConnected.value) {
        localUsersRet = { ...JSON.parse(localUsers) } || {};
        return localUsersRet[currentUserEmail.value]?.auth;
      }
    }
  }

  async function handleOnlineLogin(credentials: any) {
    const response = await rocumentsApi.post('/users/login', credentials);
    currentUserEmail.value = credentials.email;
    return response;
  }

  async function handleOfflineLogin(credentials: any) {
    const localUsers = await getLocalUsers();
    if (localUsers[credentials.email]?.password !== credentials.password) {
      throw new Error('Password incorrect or user not found.');
    }
    currentUserEmail.value = credentials.email;
    return localUsers[credentials.email].user;
  }

  return {
    login,
    handleUserAuth,
  };
}
