import { boot } from 'quasar/wrappers';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { Preferences } from '@capacitor/preferences';
import { useCoreStore } from 'src/stores/core-store';
import { storeToRefs } from 'pinia';
import syncWhitelist from 'src/utils/syncWhitelist';
import getServerUrl from 'src/utils/getServerUrl';
import checkIsMobileApp from '../utils/checkIsMobileApp';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

const rocumentsApi = axios.create({
  baseURL: getServerUrl(),
  withCredentials: true,
});

const isSyncWhitelisted = (
  type: string | undefined,
  endpoint: string | undefined
): boolean => {
  if (type && endpoint) {
    // Extract the first chunk of the provided endpoint argument
    const baseEndpoint = `/${endpoint.split('/')[1]}`;

    return syncWhitelist.some(
      (item) => item.type === type && item.endpoint.startsWith(baseEndpoint)
    );
  }

  return false;
};

const setEndpointCache = async (
  method: string | undefined,
  url: string | undefined,
  payload: any | undefined
) => {
  const cachedEndpoints = await getCachedEndpoints();
  cachedEndpoints.push({
    method: method,
    url: url,
    payload: payload,
  });

  Preferences.set({
    key: 'cachedEndpoints',
    value: JSON.stringify(cachedEndpoints),
  });
};

async function getCachedEndpoints() {
  const endpointString = (await Preferences.get({ key: 'cachedEndpoints' }))
    .value;
  return endpointString ? JSON.parse(endpointString) : [];
}

if (checkIsMobileApp()) {
  rocumentsApi.interceptors.request.use(
    function (config) {
      const coreStore = useCoreStore();
      const { isNetworkConnected } = storeToRefs(coreStore);

      // ignore network tester used for determing service status
      if ('/test-network' === config.url) {
        return config;
      }

      if (!isNetworkConnected.value) {
        if (isSyncWhitelisted(config.method, config.url)) {
          setEndpointCache(config.method, config.url, config.data);
        }

        return Promise.reject(
          new AxiosError(
            'Network Not Available On Endpoint: ' + config.url,
            '503'
          )
        );
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
}

export default boot(async ({ app }) => {
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$rocumentsApi = rocumentsApi;
});

export { rocumentsApi };
