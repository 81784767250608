import { BaseEntity, Column, Entity, PrimaryColumn } from 'typeorm';

@Entity()
export class Market extends BaseEntity {
  @PrimaryColumn({
    type: 'text',
    unique: true,
    nullable: false,
  })
  _id: string;

  @Column('text', { nullable: true })
  name: string;

  @Column('text', { nullable: true })
  subMarkets: string;

  @Column({ type: 'text', nullable: true })
  createdBy: string;

  @Column({ type: 'text', nullable: true })
  createdAt: string;

  @Column({ type: 'text', nullable: true })
  updatedBy: string;

  @Column({ type: 'text', nullable: true })
  updatedAt?: string;

  @Column({ type: 'text', nullable: true })
  deletedBy?: string;

  @Column({ type: 'text', nullable: true })
  deletedAt?: string;

  @Column({ type: 'text', nullable: false })
  __v: string;
}
