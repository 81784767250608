<template>
  <q-slide-transition>
    <div
      v-show="!isNetworkConnected"
      class="bg-amber-8 text-white text-weight-bold q-pa-xs q-ma-auto row no-wrap justify-center"
    >
      <q-icon name="cloud_off" class="q-pr-xs" size="1.4em" />
      {{ t('errors.networkUnavailableBanner') }}
    </div>
  </q-slide-transition>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useCoreStore } from 'src/stores/core-store';

const { t } = useI18n();
const coreStore = useCoreStore();
const { isNetworkConnected } = storeToRefs(coreStore);
</script>
