<template>
  <q-toolbar>
    <q-btn
      v-if="
        ($q.screen.lt.sm &&
          route.path.includes('/admin') &&
          !route.path.includes('/document-preview')) ||
        (isPdf && $q.screen.lt.sm)
      "
      icon="fal fa-bars"
      flat
      @click="onToggleSidebar"
    />
    <q-toolbar-title class="text-center">
      <router-link
        :class="{ 'justify-center': $q.screen.lt.sm }"
        to="/home"
        class="full-width row"
      >
        <img
          v-if="headerLogoPath"
          :src="headerLogoPath"
          :alt="t('productTitle')"
          :style="$q.screen.gt.xs ? 'height: 35px' : 'height: 32px'"
          class="block"
        />
        <span v-else class="text-white">{{ t('productTitle') }}</span>
      </router-link>
    </q-toolbar-title>
    <q-input
      v-if="$q.screen.gt.sm && isNetworkConnected"
      v-model="query"
      :placeholder="t('buttons.search')"
      type="search"
      standout
      dark
      square
      dense
      :outlined="false"
      class="q-mx-md"
      @keypress.enter="onSearch"
    >
      <template #prepend>
        <q-icon name="fad fa-search" size="0.8em" />
      </template>
    </q-input>
    <q-btn v-if="$q.screen.lt.sm" flat icon="fal fa-ellipsis-v">
      <q-menu>
        <q-list style="min-width: 100px" separator>
          <q-item
            v-for="item of userNavItems.filter((x) => x.link === '/home')"
            :key="item.label"
            v-close-popup
            :to="item.link ? item.link : ''"
            clickable
            @click="item.action ? item.action() : null"
          >
            <q-item-section>
              {{ item.label }}
            </q-item-section>
            <q-badge
              v-if="item.badge"
              :label="item.badge"
              color="red"
              text-color="white"
            />
          </q-item>
          <q-item v-if="isLibraryEnabled" clickable>
            <q-item-section>{{ t('library.title') }}</q-item-section>
            <q-item-section side>
              <q-icon name="keyboard_arrow_right" />
            </q-item-section>

            <q-menu anchor="top end" self="top start">
              <q-list>
                <q-item
                  v-for="item of activeLibraryItems"
                  :key="item.link"
                  :to="item.link"
                  clickable
                >
                  <q-item-section>{{ item.label }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-item>
          <q-item
            v-for="item of userNavItems.filter((x) => x.link !== '/home')"
            :key="item.label"
            v-close-popup
            :to="item.link ? item.link : ''"
            clickable
            @click="item.action ? item.action() : null"
          >
            <q-item-section>
              {{ item.label }}
            </q-item-section>
            <q-badge
              v-if="item.badge"
              :label="item.badge"
              color="red"
              text-color="white"
            />
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
    <q-tabs v-else shrink stretch align="right" content-class="menu-tabs">
      <q-route-tab
        v-for="item of userNavItems.filter((x) => x.link === '/home')"
        :key="item.label"
        :to="item.link ? item.link : null"
        :label="item.label"
        :exact="item.exact"
        @click="item.action ? item.action() : null"
      >
        <q-badge
          v-if="item.badge"
          :label="item.badge"
          color="red"
          floating
          text-color="white"
        />
      </q-route-tab>
      <q-btn-dropdown
        v-if="isLibraryEnabled || userHasKBAccess"
        :label="t('library.title')"
        auto-close
        stretch
        flat
      >
        <q-list separator>
          <q-item
            v-for="item of activeLibraryItems"
            :key="item.link"
            :to="item.link"
            clickable
          >
            <q-item-section>
              {{ item.label }}
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <q-route-tab
        v-for="item of userNavItems.filter((x) => x.link !== '/home')"
        :key="item.label"
        :to="item.link ? item.link : null"
        :label="item.label"
        :exact="item.exact"
        @click="item.action ? item.action() : null"
      >
        <q-badge
          v-if="item.badge"
          :label="item.badge"
          color="red"
          floating
          text-color="white"
        />
      </q-route-tab>
    </q-tabs>
  </q-toolbar>
</template>

<script setup lang="ts">
import { ref, computed, inject, ComputedRef } from 'vue';
import { EventBus, useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { getThemedAssetPath } from 'src/themes/index';
import { AUTH } from 'src/@types/auth';
import { useCoreStore } from 'src/stores/core-store';
import { storeToRefs } from 'pinia';
import { ORG_TYPE_TYPE } from 'src/@types/organisation-type';
import { NOTIFICATION } from 'src/@types/notification';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
import { useKnowledgeBaseStore } from 'src/stores/customer/TRM/knowledge-base';
import { onBeforeMount } from 'vue';

const props = defineProps<{
  notifications: NOTIFICATION[] | null;
  isPdf: boolean;
}>();

const coreStore = useCoreStore();
const { logout } = coreStore;
const { isLibraryEnabled, userOrgType, userIsAdmin, isNetworkConnected } =
  storeToRefs(coreStore);
const i18n = useI18n();
const { t } = i18n;
const $q = useQuasar();
const router = useRouter();
const route = useRoute();
const eventBus: EventBus | undefined = inject('event-bus');

const query = ref('');
const libraryItems = ref([
  {
    link: '/service-bulletins',
    label: i18n.t('products.tabPanels.documents.sb'),
    userGroups: ['manufacturer', 'distributor', 'authorised_repairer'],
  },
  {
    link: '/technical-bulletins',
    label: i18n.t('products.tabPanels.documents.tb'),
    userGroups: ['manufacturer', 'distributor', 'authorised_repairer'],
  },
  {
    link: '/knowledge-base',
    label: i18n.t('knowledgeBase.featureTitle'),
    // TODO filter out this item if the user does not have KB permissions
    perms: ['knowledge-base'],
  },
]);

const auth = inject<ComputedRef<AUTH | undefined>>('auth');

const kbStore = useKnowledgeBaseStore();
const { userHasKBAccess } = storeToRefs(kbStore);
const { getAccessPermissions } = kbStore;
const activeLibraryItems = computed(() => {
  return libraryItems.value
    .filter(
      (x) =>
        x.userGroups?.includes(auth?.value?.organisationType._type || '') ||
        !x.userGroups
    )
    .filter((x) => {
      if (x.perms?.includes('knowledge-base')) {
        return userHasKBAccess.value;
      }
      return true;
    });
});

const badgeValue = computed(
  () => props.notifications?.filter((x) => !x.dismissed).length
);
const isBusinessUser = computed(
  () => userOrgType?.value !== ORG_TYPE_TYPE.Individual
);
const headerLogoPath = computed(() => getThemedAssetPath('header-logo.svg'));

const navItems = computed(() => [
  {
    link: '/home',
    label: i18n.t('titles.home'),
  },
  {
    link: '/notifications',
    label: i18n.t('notifications.featureName'),
    badge: badgeValue.value,
  },
  {
    link: '/profile',
    label: i18n.t('titles.profile'),
  },
  {
    link: '/my-organisation',
    label: i18n.t('titles.myOrganisation'),
    businessOnly: true,
  },
  checkIsMobileApp()
    ? {
        link: '/downloaded',
        label: i18n.t('titles.downloaded'),
      }
    : {
        link: '/admin',
        label: i18n.t('titles.admin'),
        adminOnly: true,
      },
  {
    label: i18n.t('titles.logout'),
    action: logout,
    exact: true,
  },
]);

const userNavItems = computed(() =>
  navItems.value
    .filter((x) => {
      return userIsAdmin.value ? true : !x.adminOnly;
    })
    .filter((x) => {
      return isBusinessUser.value ? true : !x.businessOnly;
    })
);

const onToggleSidebar = () => {
  eventBus?.emit('toggleAdminSideBar');
};

const onSearch = () => {
  if (query.value.trim() !== '') {
    router.push(`/search-results?query=${query.value}`);
    query.value = '';
  }
};

onBeforeMount(async () => {
  await getAccessPermissions();
});
</script>

<style lang="scss">
@media (min-width: 1440px) {
  .menu-tabs {
    .q-tab__content {
      min-width: 40px;
    }
  }
}
</style>
