<template>
  <q-drawer
    v-model="drawerOpen"
    :breakpoint="500"
    :mini="!wide"
    side="left"
    bordered
    show-if-above
    persistent
  >
    <q-list padding>
      <q-item
        dense
        to="/admin"
        clickable
        active-class="bg-primary text-white"
        exact
      >
        <q-item-section avatar>
          <q-icon name="fad fa-home" size="1.4em" />
        </q-item-section>
        <q-item-section> {{ t('admin.home') }} </q-item-section>
        <q-tooltip
          v-if="!wide"
          :offset="[10, 10]"
          anchor="center right"
          self="center left"
        >
          {{ t('admin.home') }}
        </q-tooltip>
      </q-item>
      <q-item
        v-for="(item, index) of userNavItems"
        :key="index"
        dense
        :to="item.link"
        clickable
        active-class="bg-primary text-white"
      >
        <q-item-section avatar>
          <q-icon :name="item.icon" size="1.4em" />
        </q-item-section>
        <q-item-section> {{ item.label }} </q-item-section>
        <q-tooltip
          v-if="!wide"
          :offset="[10, 10]"
          anchor="center right"
          self="center left"
        >
          {{ item.label }}
        </q-tooltip>
      </q-item>
      <q-item
        dense
        :side="false"
        clickable
        active-class="bg-primary text-white"
        class="row justify-end bg-grey-4"
        @click="wide = !wide"
      >
        <q-item-section />
        <q-item-section avatar>
          <q-icon
            :name="`fad fa-chevron-${wide ? 'left' : 'right'}`"
            size="1.4em"
          />
        </q-item-section>
      </q-item>
      <q-item-label header>
        {{ t('admin.client') }} {{ version }}
      </q-item-label>
      <q-item-label header>
        {{ t('admin.server') }} {{ serverVersion }}
      </q-item-label>
    </q-list>
  </q-drawer>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { EventBus } from 'quasar';
import { version } from '../../../package.json';
import { useCoreStore } from 'src/stores/core-store';
import { ComputedRef } from 'vue';
import { AUTH } from 'src/@types/auth';
import { storeToRefs } from 'pinia';

const { t } = useI18n();
const eventBus: EventBus | undefined = inject('event-bus');

const drawerOpen = ref(false);
const wide = ref(false);
const navItems = ref([
  {
    link: '/admin/documents',
    label: t('documents.featureName'),
    icon: 'fad fa-books',
    roles: ['document_admin', 'admin'],
  },
  {
    link: '/admin/document-types',
    label: t('documents.documentTypes'),
    icon: 'fad fa-swatchbook',
    roles: ['admin'],
  },
  {
    link: '/admin/diagnostics',
    label: t('diagnostics.featureName'),
    icon: 'fad fa-stethoscope',
    roles: ['document_admin', 'admin'],
  },
  {
    link: '/admin/products',
    label: t('products.featureName'),
    icon: 'fad fa-database',
    roles: ['admin'],
  },
  {
    link: '/admin/product-details',
    label: t('productDetails.featureName'),
    icon: 'fad fa-motorcycle',
    roles: ['admin'],
  },
  {
    link: '/admin/users',
    label: t('users.featureName'),
    icon: 'fad fa-users',
    roles: ['user_admin', 'admin'],
  },

  {
    link: '/admin/subscriptions',
    label: t('subscriptions.featureName'),
    icon: 'fad fa-hourglass-half',
    roles: ['user_admin', 'admin'],
  },
  {
    link: '/admin/subscription-types',
    label: t('subscriptionTypes.featureName'),
    icon: 'fad fa-file-contract',
    roles: ['admin'],
  },

  {
    link: '/admin/organisations',
    label: t('organisations.featureName'),
    icon: 'fad fa-sitemap',
    roles: ['user_admin', 'admin'],
  },
  {
    link: '/admin/organisation-types',
    label: t('orgTypes.featureName'),
    icon: 'fad fa-building',
    roles: ['admin'],
  },
  {
    link: '/admin/feedback?state=new',
    label: t('feedback.featureName'),
    icon: 'fad fa-star-half',
    roles: ['document_admin', 'admin'],
  },

  {
    link: '/admin/announcements',
    label: t('announcements.featureName'),
    icon: 'fad fa-bullhorn',
    roles: ['document_admin', 'user_admin', 'admin'],
  },

  {
    link: '/admin/brand-setup',
    label: t('brandSetup.featureName'),
    icon: 'fad fa-filter',
    roles: ['admin'],
  },

  {
    link: '/admin/analytics',
    label: t('analytics.title'),
    icon: 'fad fa-analytics',
    roles: ['admin', 'user_admin', 'document_admin'],
  },

  {
    link: '/admin/markets',
    label: t('markets.featureName'),
    icon: 'fad fa-map-marked-alt',
    roles: ['admin', 'document-admin'],
  },
  {
    link: '/admin/settings',
    label: t('adminSettings.settings'),
    icon: 'fad fa-cog',
    roles: ['admin'],
  },
  {
    link: '/admin/metadata',
    label: t('metadata.featureTitle'),
    icon: 'fad fa-shapes',
    roles: ['admin', 'document_admin'],
  },
  {
    link: '/admin/faq',
    label: t('faq.featureTitle'),
    icon: 'fad fa-question',
    roles: ['admin', 'document_admin'],
  },
  {
    link: '/admin/pdi',
    customer: ['TRM', 'TRM-UAT'],
    label: t('pdi.featureTitle'),
    icon: 'fad fa-box-alt',
    roles: ['admin', 'document_admin'],
  },
  {
    link: '/admin/knowledge-base',
    customer: ['TRM', 'TRM-UAT'],
    label: t('knowledgeBase.featureTitle'),
    icon: 'fad fa-folder-tree',
    roles: ['admin', 'document_admin'],
  },
]);

const auth = inject<ComputedRef<AUTH | undefined>>('auth');
const coreStore = useCoreStore();
const { serverVersion } = storeToRefs(coreStore);

const userNavItems = computed(() =>
  navItems.value
    .filter((navItem) => {
      return navItem.roles.includes(auth?.value?.role || '');
    })
    .filter((navItem) => {
      const customerId = process.env.CUSTOMER_ID || '';

      return (
        navItem.customer?.includes(customerId) ||
        ['LOCAL', 'DEV'].includes(customerId) ||
        !navItem.customer
      );
    })
);

const toggleDrawer = () => {
  drawerOpen.value = !drawerOpen.value;
};

onMounted(() => {
  eventBus?.on('toggleAdminSideBar', toggleDrawer);
});

onBeforeUnmount(() => {
  eventBus?.off('toggleAdminSideBar');
});
</script>
