import { BASE } from './base';
import { ORGANISATION } from './organisation';
import { SUBSCRIPTION_TYPE } from './subscription-type';
import { USER } from './user';

export enum SUBSCRIPTION_STATE {
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
}
export interface SubscriptionSerialRestriction {
  serial: string;
  updatedAt: string | Date;
}

export interface SubscriptionRestrictions {
  model: string;
  serial: SubscriptionSerialRestriction[];
  durationType?: any;
  type?: string;
}
export interface SUBSCRIPTION extends BASE {
  currency: string;
  cost: number;
  organisation: string;
  assignedTo: string[];
  type: string;
  validFrom: string;
  validTo?: string;
  paymentId?: string;
  paymentMethod?: string;
  paymentReceipt?: string;
  paymentIntentId?: string;
  stripeSubId?: string;
  checkoutSession?: string;
  customerId?: string;
  refund?: boolean;
  state: SUBSCRIPTION_STATE;
  restrictions: SubscriptionRestrictions;
  serialUpdated?: string;
  stripeObject: any;
  history?: SUBSCRIPTION[];
}

export interface POPULATED_SUBSCRIPTION
  extends Omit<SUBSCRIPTION, 'type' | 'organisation' | 'createdBy'> {
  type: SUBSCRIPTION_TYPE;
  organisation: Partial<ORGANISATION>;
  createdBy: USER;
}
