<template>
  <q-toolbar>
    <q-toolbar-title shrink>
      <router-link
        :class="{ 'justify-center': quasar.screen.lt.sm }"
        :to="`${$route.matched[0].path}`"
      >
        <img
          v-if="headerLogoPath"
          :src="getThemedAssetPath('header-logo.svg')"
          :alt="t('productTitle')"
          :style="quasar.screen.gt.xs ? 'height: 35px' : 'height: 32px'"
          class="block"
          style="max-width: 100%"
        />
        <span v-else class="text-white">{{ t('productTitle') }}</span>
      </router-link>
    </q-toolbar-title>
    <q-space />
    <q-btn-dropdown icon="fas fa-language" flat stretch>
      <q-list>
        <q-item
          v-for="(lang, index) in uiLangs"
          :key="index"
          v-close-popup
          clickable
          @click="onSelectLanguage(lang)"
        >
          <q-item-section>
            <q-item-label>{{ t(`languages.${lang}`) }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </q-toolbar>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useQuasar } from 'quasar';
import { computed } from 'vue';
import { useBrowserLangFormat } from 'src/composables/useBrowserLangFormat';
import { getThemedAssetPath } from 'src/themes/index';

const quasar = useQuasar();
const i18n = useI18n();
const { t } = i18n;
const { onSelectLanguage } = useBrowserLangFormat();

const uiLangs = computed(() => i18n.availableLocales);
const headerLogoPath = computed(() => getThemedAssetPath('header-logo.svg'));
</script>
