import { RouteRecordRaw } from 'vue-router';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
import MainLayout from 'src/layouts/MainLayout.vue';
import PreAuthLayout from 'src/layouts/PreAuthLayout.vue';
import FluidsLayout from 'src/layouts/FluidsLayout.vue';
import HandbooksLayout from 'src/layouts/HandbooksLayout.vue';

// IMPORTANT
// Order of routes is important and shouldn't be change
// otherwise it might break <url>/ and <url>/home routing

const routes: RouteRecordRaw[] = [];
const MAIN_LAYOUT_ROUTE_NAME = 'MainLayout/';

if (true) {
  routes.push({
    path: '/handbooks',
    component: HandbooksLayout,
    meta: { protected: false, action: 'readAny', resource: 'document' },
    children: [
      {
        path: '',
        component: () => import('src/pages/handbooks/HandbooksHomePage.vue'),
        meta: { protected: false },
      },
      {
        path: ':id',
        component: () => import('src/pages/handbooks/ResultsPage.vue'),
        meta: { protected: false },
      },
      {
        path: ':search/:id',
        component: () => import('src/pages/handbooks/ResultsPage.vue'),
        meta: { protected: false },
      },
    ],
  });

  routes.push({
    path: '/fluids',
    component: FluidsLayout,
    meta: { protected: false, action: 'readAny', resource: 'document' },
    children: [
      {
        path: '',
        component: () => import('src/pages/fluids/FluidsHomePage.vue'),
        meta: { protected: false },
      },
      {
        path: 'document/:id/:topic?',
        component: () => import('src/pages/documents/DocumentPage.vue'),
        meta: { protected: false },
        name: 'fluidsDocViewer',
      },

      {
        path: ':id',
        component: () => import('src/pages/fluids/ResultsPage.vue'),
        meta: { protected: false },
      },
      {
        path: ':search/:id',
        component: () => import('src/pages/fluids/ResultsPage.vue'),
        meta: { protected: false },
      },
    ],
  });

  routes.push({
    path: '/tyres',
    component: FluidsLayout,
    meta: { protected: false, action: 'readAny', resource: 'document' },
    children: [
      {
        path: '',
        component: () => import('src/pages/tyres/TyresHomePage.vue'),
        meta: { protected: false },
      },
      {
        path: 'document/:id/:topic?',
        component: () => import('src/pages/documents/DocumentPage.vue'),
        meta: { protected: false },
        name: 'tyresDocViewer',
      },

      {
        path: ':id',
        component: () => import('src/pages/tyres/ResultsPage.vue'),
        meta: { protected: false },
      },
      {
        path: ':search/:id',
        component: () => import('src/pages/tyres/ResultsPage.vue'),
        meta: { protected: false },
      },
    ],
  });
}

routes.push({
  path: '/',
  component: PreAuthLayout,
  children: [
    {
      path: '',
      component: () => import('src/pages/IndexPage.vue'),
    },
    {
      path: '/login',
      component: () => import('src/pages/IndexPage.vue'),
    },
    {
      path: '/maintenance',
      component: () => import('src/pages/MaintenancePage.vue'),
    },
    {
      path: '/reset-password',
      component: () => import('src/pages/auth/ResetPasswordPage.vue'),
    },
    {
      path: '/reset-password/:token',
      component: () => import('src/pages/auth/NewPasswordPage.vue'),
    },
    {
      path: '/change-email/:token',
      component: () => import('src/pages/auth/ChangeEmailPage.vue'),
    },
    {
      path: '/register',
      component: () => import('src/pages/auth/RegisterPage.vue'),
    },
    {
      path: '/verify-email/:token',
      component: () => import('src/pages/auth/EmailVerificationPage.vue'),
    },
    {
      path: '/terms',
      component: () => import('src/pages/site-info/TermsPage.vue'),
    },
    {
      path: '/contact',
      component: () => import('src/pages/site-info/ContactPage.vue'),
    },
    {
      path: '/privacy-policy',
      component: () => import('src/pages/site-info/PrivacyPolicyPage.vue'),
    },
    {
      path: '/cookie-policy',
      component: () => import('src/pages/site-info/CookiePolicyPage.vue'),
    },
    {
      path: '/faq',
      component: () => import('src/pages/site-info/FaqPage.vue'),
    },
  ],
});

routes.push({
  path: '/',
  component: MainLayout,
  name: MAIN_LAYOUT_ROUTE_NAME,
  children: [
    {
      path: '/payments/success',
      component: () => import('src/pages/payments/PaymentSuccessPage.vue'),
      meta: { protected: true },
    },
    {
      path: '/payments/failed',
      component: () => import('src/pages/payments/PaymentFailedPage.vue'),
      meta: { protected: true },
    },
    {
      path: '/payments',
      component: () => import('src/pages/payments/PaymentsPage.vue'),
      meta: { protected: true },
    },
    {
      path: '/home',
      component: () => import('src/pages/HomePage.vue'),
      meta: { protected: true },
    },
    {
      path: '/notifications',
      component: () => import('src/pages/user/NotificationsPage.vue'),
      meta: { protected: true },
    },
    {
      path: '/search-results',
      component: () => import('src/pages/search/SearchResults.vue'),
      meta: { protected: true },
    },
    {
      path: '/products/:search?/:id',
      component: () => import('src/pages/products/ProductPage.vue'),
      meta: {
        protected: true,
        action: 'readAny',
        resource: 'product',
      },
    },
    {
      path: '/profile',
      component: () => import('src/pages/user/ProfilePage.vue'),
      meta: { protected: true },
    },
    {
      path: '/my-organisation',
      component: () => import('src/pages/user/MyOrganisationPage.vue'),
      meta: { protected: true, action: 'readOwn', resource: 'organisation' },
    },
    {
      path: '/service-bulletins',
      component: () => import('src/pages/custom/ServiceBulletinsPage.vue'),
      meta: { protected: true, perms: ['library'] },
    },
    {
      path: '/technical-bulletins',
      component: () => import('src/pages/custom/TechnicalBulletinsPage.vue'),
      meta: { protected: true, perms: ['library'] },
    },
    {
      path: '/knowledge-base',
      component: () => import('src/pages/custom/KnowledgeBasePage.vue'),
      meta: {
        protected: true,
        customer: ['TRM', 'TRM-UAT'],
        action: 'readAny',
        resource: 'knowledgeBase',
      },
    },
    {
      path: 'knowledge-base/new',
      component: () =>
        import('src/pages/admin/custom/TRM/KB-Editor-AdminPage.vue'),
      meta: {
        protected: true,
        customer: ['TRM', 'TRM-UAT'],
        action: 'readAny',
        resource: 'knowledgeBase',
      },
    },
    {
      path: 'knowledge-base/:id',
      component: () =>
        import('src/pages/admin/custom/TRM/KB-Editor-AdminPage.vue'),
      name: 'knowledgeBaseEditUser',
      meta: {
        protected: true,
        customer: ['TRM', 'TRM-UAT'],
        action: 'readAny',
        resource: 'knowledgeBase',
      },
    },

    {
      path: '/document/:id',
      component: () => import('src/pages/documents/DocumentPage.vue'),
      meta: { protected: true },
      name: 'document',
    },
    {
      path: '/downloaded',
      component: () =>
        import('src/pages/documents/OfflineDocumentsListPage.vue'),
      meta: { protected: true, action: 'readOwn', resource: 'document' },
    },
    {
      path: '/downloaded/view/:id',
      component: () => import('src/pages/documents/DocumentPage.vue'),
      meta: { protected: true, action: 'readAny', resource: 'document' },
    },
    {
      path: '/profile/feedback',
      component: () => import('pages/UserFeedbackPage.vue'),
      meta: { protected: true },
      name: 'userFeedback',
    },
    {
      path: '/profile/feedback/:_id',
      component: () => import('pages/FeedbackDetailPage.vue'),
      meta: {
        protected: true,
      },
      name: 'userFeedbackDetail',
    },
  ],
});

if (!checkIsMobileApp()) {
  routes.find((route) => {
    if (route.name === MAIN_LAYOUT_ROUTE_NAME) {
      route?.children?.push({
        path: '/admin',
        component: () => import('src/pages/AdminPage.vue'),
        meta: { protected: true, action: 'readAny', resource: 'admin' },
        children: [
          {
            path: '',
            component: () => import('src/pages/admin/Index-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'admin',
            },
          },
          {
            path: 'announcements',
            component: () =>
              import(
                'src/pages/admin/announcements/Announcements-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'announcement',
            },
          },
          {
            path: 'announcements/new',
            component: () =>
              import(
                'src/pages/admin/announcements/Announcements-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'announcement',
            },
          },
          {
            path: 'announcements/:id',
            component: () =>
              import(
                'src/pages/admin/announcements/Announcements-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'announcement',
            },
          },

          {
            path: 'markets',
            component: () =>
              import('src/pages/admin/markets/Markets-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'market',
            },
          },
          {
            path: 'markets/new',
            component: () =>
              import('src/pages/admin/markets/Markets-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'market',
            },
          },
          {
            path: 'markets/:_id',
            component: () =>
              import('src/pages/admin/markets/Markets-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'market',
            },
          },
          {
            path: 'documents',
            component: () =>
              import('src/pages/admin/documents/Documents-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'document',
            },
          },
          {
            path: 'document-types',
            component: () =>
              import(
                'src/pages/admin/document-types/DocumentTypes-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'document',
            },
          },
          {
            path: 'document-types/tabs',
            component: () =>
              import(
                'src/pages/admin/document-types/DocumentTypeTabs-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'document',
            },
          },
          {
            path: 'document-types/:id',
            component: () =>
              import(
                'src/pages/admin/document-types/DocumentTypes-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'document',
            },
          },
          {
            path: 'documents/bulk',
            component: () =>
              import(
                'src/pages/admin/documents/Documents-BulkEditor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'document',
            },
          },
          {
            path: 'documents/:id',
            component: () =>
              import(
                'src/pages/admin/documents/Documents-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'document',
            },
            name: 'adminDocument',
          },
          {
            path: 'documents/:_id/views',
            component: () =>
              import('src/pages/admin/documents/DocumentViews-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'document',
            },
          },
          {
            path: 'diagnostics',
            component: () =>
              import('src/pages/admin/diagnostics/Diagnostics-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'document',
            },
          },
          {
            path: 'diagnostics/:_id',
            component: () =>
              import(
                'src/pages/admin/diagnostics/DiagnosticsEditor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'document',
            },
          },
          {
            path: 'document-preview/:id',
            name: 'documentPreview',
            component: () => import('src/pages/documents/DocumentPage.vue'),
            meta: { protected: true, action: 'readAny', resource: 'document' },
          },

          {
            path: 'organisation-types',
            component: () =>
              import(
                'src/pages/admin/organisation-types/OrganisationTypes-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'organisation',
            },
          },
          {
            path: 'organisation-types/new',
            component: () =>
              import(
                'src/pages/admin/organisation-types/OrganisationTypes-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'announcement',
            },
          },
          {
            path: 'organisation-types/:id',
            component: () =>
              import(
                'src/pages/admin/organisation-types/OrganisationTypes-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'announcement',
            },
          },
          {
            path: 'settings',
            component: () =>
              import('src/pages/admin/settings/Settings-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'announcement',
            },
          },
          {
            path: 'products',
            component: () =>
              import('src/pages/admin/products/Products-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'product',
            },
          },
          {
            path: 'products/:id',
            component: () =>
              import('src/pages/admin/products/Products-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'product',
            },
            name: 'adminProduct',
          },
          {
            path: 'product-details',
            component: () =>
              import(
                'src/pages/admin/product-details/ProductDetails-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'product',
            },
          },
          {
            path: 'product-detail-conflicts',
            component: () =>
              import(
                'src/pages/admin/product-details/ProductDetails-Conflicts-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'product',
            },
          },
          {
            path: 'product-details-bulk',
            component: () =>
              import(
                'src/pages/admin/product-details/ProductDetails-BulkEditor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'product',
            },
          },
          {
            path: 'product-details/:id',
            component: () =>
              import(
                'src/pages/admin/product-details/ProductDetails-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'product',
            },
            name: 'adminModel',
          },
          {
            path: 'brand-setup/',
            component: () =>
              import(
                'src/pages/admin/brands-categories/Brands-Categories-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'product',
            },
          },

          {
            path: 'subscriptions',
            component: () =>
              import(
                'src/pages/admin/subscriptions/Subscriptions-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'subscription',
            },
          },

          {
            path: 'subscriptions/:id',
            component: () =>
              import(
                'src/pages/admin/subscriptions/Subscriptions-Editor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'subscription',
            },
          },

          {
            path: 'analytics',
            name: 'analytics',
            component: () =>
              import('src/pages/admin/analytics/Analytics-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'userAnalytics',
            },
          },
          {
            path: 'feedback',
            component: () =>
              import('src/pages/admin/feedback/Feedback-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'feedback/:_id',
            component: () =>
              import('src/pages/admin/feedback/Feedback-Viewer-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'feedback/bulk',
            component: () =>
              import(
                'src/pages/admin/feedback/Feedback-BulkEditor-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'feedback-categories',
            component: () =>
              import(
                'src/pages/admin/feedback/FeedbackCategories-AdminPage.vue'
              ),
            name: 'feedbackCategories',
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'organisations',
            component: () =>
              import(
                'src/pages/admin/organisations/Organisations-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'organisation',
            },
          },
          {
            path: 'organisations/:id',
            component: () =>
              import(
                'src/pages/admin/organisations/Organisations-Editor-AdminPage.vue'
              ),
          },
          {
            path: 'organisations/:id/important-docs',
            component: () =>
              import(
                'src/pages/admin/organisations/Organisation-RefDocsViews-AdminPage.vue'
              ),
          },

          {
            path: 'subscription-types',
            component: () =>
              import(
                'src/pages/admin/subscription-types/SubscriptionTypes-AdminPage.vue'
              ),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'subscription',
            },
          },
          {
            path: 'subscription-types/new',
            component: () =>
              import(
                'src/pages/admin/subscription-types/SubscriptionTypes-Editor-AdminPage.vue'
              ),
          },
          {
            path: 'subscription-types/:id',
            component: () =>
              import(
                'src/pages/admin/subscription-types/SubscriptionTypes-Editor-AdminPage.vue'
              ),
          },

          {
            path: 'users',
            component: () =>
              import('src/pages/admin/users/Users-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'user',
            },
          },
          {
            path: 'users/new',
            component: () =>
              import('src/pages/admin/users/Users-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'user',
            },
          },
          {
            path: 'users/:id',
            component: () =>
              import('src/pages/admin/users/Users-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'user',
            },
          },
          {
            path: 'users/:_id/transactions',
            component: () =>
              import('src/pages/admin/users/Users-Subscriptions-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'readAny',
              resource: 'subscription',
            },
          },
          {
            path: 'users/:_id/document-history',
            component: () =>
              import('src/pages/admin/users/Users-DocumentViews-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'updateAny',
              resource: 'user',
            },
          },
          {
            path: 'metadata',
            component: () =>
              import('src/pages/admin/metadata/Metadata-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'metadata/:id',
            component: () =>
              import('src/pages/admin/metadata/Metadata-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'faq',
            component: () => import('src/pages/admin/faqs/FAQs-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'faq/:id',
            component: () =>
              import('src/pages/admin/faqs/FAQs-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              action: 'createAny',
              resource: 'document',
            },
          },
          // Custom
          {
            path: 'pdi',
            component: () =>
              import('src/pages/admin/custom/TRM/PDI-AdminPage.vue'),
            meta: {
              protected: true,
              customer: ['TRM', 'TRM-UAT'],
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'pdi/new',
            component: () =>
              import('src/pages/admin/custom/TRM/PDI-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              customer: ['TRM', 'TRM-UAT'],
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'pdi/:id',
            component: () =>
              import('src/pages/admin/custom/TRM/PDI-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              customer: ['TRM', 'TRM-UAT'],
              action: 'createAny',
              resource: 'document',
            },
          },
          {
            path: 'knowledge-base',
            component: () =>
              import('src/pages/admin/custom/TRM/KB-AdminPage.vue'),
            meta: {
              protected: true,
              customer: ['TRM', 'TRM-UAT'],
              action: 'createAny',
              resource: 'knowledgeBase',
            },
          },
          {
            path: 'knowledge-base/settings',
            component: () =>
              import('src/pages/admin/custom/TRM/KB-SettingsPage.vue'),
            meta: {
              protected: true,
              customer: ['TRM', 'TRM-UAT'],
              action: 'createAny',
              resource: 'knowledgeBase',
            },
          },
          {
            path: 'knowledge-base/new',
            component: () =>
              import('src/pages/admin/custom/TRM/KB-Editor-AdminPage.vue'),
            meta: {
              protected: true,
              customer: ['TRM', 'TRM-UAT'],
              action: 'createAny',
              resource: 'knowledgeBase',
            },
          },
          {
            path: 'knowledge-base/:id',
            component: () =>
              import('src/pages/admin/custom/TRM/KB-Editor-AdminPage.vue'),
            name: 'knowledgeBaseEdit',
            meta: {
              protected: true,
              customer: ['TRM', 'TRM-UAT'],
              action: 'updateAny',
              resource: 'knowledgeBase',
            },
          },
        ],
      });
    }
  });
}

routes.push(
  {
    path: '/401',
    component: () => import('src/pages/errors/Error401Page.vue'),
  },

  {
    path: '/403',
    component: () => import('src/pages/errors/Error403Page.vue'),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('src/pages/errors/Error404Page.vue'),
  }
);

export default routes;
