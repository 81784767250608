<template>
  <q-toolbar>
    <div class="col">
      <q-btn icon="menu" flat dense @click="model = !model" />
    </div>
    <div class="col-auto text-center">
      <q-toolbar-title class="text-center">
        <router-link
          :class="{ 'justify-center': $q.screen.lt.sm }"
          to="/home"
          class="full-width row"
        >
          <img
            v-if="headerLogoPath"
            :src="headerLogoPath"
            :alt="t('productTitle')"
            :style="$q.screen.gt.xs ? 'height: 35px' : 'height: 32px'"
            class="block"
          />
          <span v-else class="text-white">{{ t('productTitle') }}</span>
        </router-link>
      </q-toolbar-title>
    </div>
    <div class="col text-right"></div>
  </q-toolbar>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getThemedAssetPath } from '../../themes';
import { useI18n } from 'vue-i18n';
const i18n = useI18n();
const { t } = i18n;

const headerLogoPath = computed(() => getThemedAssetPath('header-logo.svg'));

const model = defineModel<boolean>({ default: false, required: true });
</script>
