import { BaseEntity, Column, Entity, PrimaryColumn } from 'typeorm';

@Entity()
export class DocumentImage extends BaseEntity {
  @PrimaryColumn({
    type: 'text',
    unique: true,
    nullable: false,
    primary: true,
  })
  _id: string;

  @Column('text')
  parentDocument: string;

  @Column('text')
  stringifiedContent: string;
}
