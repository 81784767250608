import { boot } from 'quasar/wrappers';
import { createI18n } from 'vue-i18n';

import messages from 'src/themes/default/i18n';
import { LANGUAGE_CODE } from 'src/@types/languages';

export type MessageLanguages = keyof typeof messages;
// Type-define 'en-gb' as the master schema for the resource
export type MessageSchema = (typeof messages)[LANGUAGE_CODE.en_gb];

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}

  // define the datetime format schema
  export interface DefineDateTimeFormat {}

  // define the number format schema
  export interface DefineNumberFormat {}
}
/* eslint-enable @typescript-eslint/no-empty-interface */

export const i18n = createI18n({
  locale: LANGUAGE_CODE.en_gb,
  fallbackLocale: LANGUAGE_CODE.en_gb,
  legacy: false,
  globalInjection: true,
  messages,
});

const supportedLangs = Object.values(LANGUAGE_CODE);

export default boot(async ({ app }) => {
  if (process.env.THEME) {
    for await (const lang of supportedLangs) {
      const customerStrings = await import(
        `../themes/${process.env.THEME}/i18n/${lang}/${lang}.json`
      );

      i18n.global.mergeLocaleMessage(lang, customerStrings.default);
    }
  }

  app.use(i18n);
});
