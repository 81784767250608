import { Preferences } from '@capacitor/preferences';
import { storeToRefs } from 'pinia';
import { useCoreStore } from 'src/stores/core-store';
import checkIsMobileApp from '../utils/checkIsMobileApp';

export default async function usePersistentStorage(originalStore: any) {
  if (!checkIsMobileApp()) return;
  const coreStore = useCoreStore();
  const { isNetworkConnected } = storeToRefs(coreStore);

  if (!isNetworkConnected.value) {
    await retrieveStoredStates(originalStore);
  }

  originalStore.$subscribe(async (mutation: any, state: any) => {
    await Preferences.set({
      key: originalStore.$id + '-' + mutation.storeId,
      value: JSON.stringify(state),
    });
  });

  async function retrieveStoredStates(origStore: any) {
    const storedPrefKeys = await Preferences.keys();
    storedPrefKeys.keys.forEach(async (key) => {
      const idPrefix = key.split('-')[0];
      const idSuffix = key.split('-')[1];

      if (origStore.$id == idPrefix && origStore[idSuffix]) {
        const val = await Preferences.get({ key: key });
        if (val.value != null) {
          origStore[idSuffix].value = JSON.parse(val.value);
        }
      }
    });
  }
}
