<template>
  <q-dialog
    :model-value="props.open"
    :maximized="$q.screen.lt.sm"
    persistent
    @hide="$emit('close')"
  >
    <q-card
      class="column full-height"
      style="max-height: 75vh; max-width: 80vw; width: 100%"
    >
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title>
          {{ t('documents.searchResults') }}
        </q-toolbar-title>
        <q-btn v-close-popup flat round dense icon="close" class="q-mr-xs" />
      </q-toolbar>
      <q-list class="col justify-center scroll" separator>
        <q-item v-if="!filteredResults.length">
          {{ t('documents.noSearchResults') }}
        </q-item>
        <q-item
          v-for="hit of filteredResults"
          :key="hit.hit.id"
          clickable
          @click="onClick(hit.hit.id)"
        >
          <q-item-section>
            <q-item-label class="text-subtitle1 text-bold">
              {{ hit.hit.title }}
            </q-item-label>

            <template v-for="(snippet, i) in hit.highlight.search" :key="i">
              <q-item-label>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="snippet" />
              </q-item-label>
            </template>
          </q-item-section>
          <q-item-section side>
            <q-btn
              flat
              dens
              icon="far fa-external-link-square-alt"
              color="grey-5"
              padding="3px"
              role="link"
              @click="addNewTab(route.params.id.toString(), hit.hit.id)"
            >
              <q-tooltip :delay="300">{{
                $t('documents.openInNewTab')
              }}</q-tooltip>
            </q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';
import { useDocumentViewerStore } from 'src/stores/document-viewer';
import { storeToRefs } from 'pinia';
import { toValue } from 'vue';
import { useSearchStore } from 'src/stores/search';

const props = defineProps<{
  open: boolean;
}>();

const documentViewerStore = useDocumentViewerStore();
const { productContext } = storeToRefs(documentViewerStore);
const { replaceCurrentTab, addNewTab } = documentViewerStore;

const searchStore = useSearchStore();
const { documentHits } = storeToRefs(searchStore);

const route = useRoute();
const $q = useQuasar();
const { t } = useI18n();
const emit = defineEmits(['close']);

// TODO filter results using useTocFilter
// TODO filter search results on BE (send product context in search query)
// TODO filter search result content for metatadata
const filteredResults = computed(() => {
  return documentHits.value
    .filter((x) => {
      if (
        !x.hit.metadata ||
        route.path.includes('document-preview') ||
        !toValue(productContext)
      ) {
        return true;
      }

      let returnCondition = true;

      // Markets
      if (x.hit.metadata.markets) {
        const market = toValue(productContext)?.warrantyLocale;
        const marketsArray = x.hit.metadata.markets;

        if (market && !marketsArray.includes(market)) {
          returnCondition = false;
        }
        if (marketsArray.includes('All')) {
          returnCondition = true;
        }
        if (!market) {
          returnCondition = true;
        }
        if (!marketsArray.length) {
          returnCondition = true;
        }
      }

      //Model Code
      if (x.hit.metadata.products) {
        const model = toValue(productContext)?.modelCode;
        const modelsArray = x.hit.metadata.products
          .split(';')
          .map((metaProduct: string) => metaProduct.trim());

        if (model && !modelsArray.includes(model)) {
          returnCondition = false;
        }
      }

      // VIN
      const vin = toValue(productContext)?.serialNumberStub;
      // Start VIN
      const startVin = x.hit.metadata.vinStart;
      if (vin && !!startVin && vin < startVin) {
        returnCondition = false;
      }
      // End VIN
      const endVin = x.hit.metadata.vinEnd;
      if (vin && !!endVin && vin > endVin) {
        returnCondition = false;
      }

      //Engine No
      const engine = toValue(productContext)?.engineNo;
      // Engine Start
      const startEngine = x.hit.metadata.engineStart;
      if (engine && !!startEngine && engine < startEngine) {
        returnCondition = false;
      }
      // Engine End
      const endEngine = x.hit.metadata.engineEnd;

      if (engine && !!endEngine && engine > endEngine) {
        returnCondition = false;
      }

      return returnCondition;
    })
    .sort((a, b): number => {
      const aScore = a.score as number;
      const bScore = b.score as number;

      if (aScore < bScore) {
        return 1;
      }
      if (bScore < aScore) {
        return -1;
      }
      if (aScore === bScore) {
        return 0;
      }

      return 0;
    });
});

const onClick = async (id: string) => {
  await replaceCurrentTab(id);
  emit('close');
};
</script>

<style lang="scss">
mark {
  font-weight: 600 !important;
}
</style>
