<template>
  <q-banner class="bg-accent text-white">
    {{
      props.userType === 'individual'
        ? t('subscriptions.bannerActivateSubscription.content')
        : t('subscriptions.bannerActivateSubscription.askManager')
    }}
  </q-banner>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
import { ORG_TYPE_TYPE } from 'src/@types/organisation-type';

const props = withDefaults(
  defineProps<{
    userType: ORG_TYPE_TYPE | null;
  }>(),
  {
    userType: ORG_TYPE_TYPE.Individual,
  }
);
</script>
