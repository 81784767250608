import { defineStore, storeToRefs } from 'pinia';
import { AUTH } from 'src/@types/auth';
import { ORG_TYPE_TYPE } from 'src/@types/organisation-type';
import { SUBSCRIPTION_STATE } from 'src/@types/subscription';
import { rocumentsApi } from 'src/boot/axios';
import { addDays, addYears, endOfDay, isWithinInterval } from 'date-fns';
import { ROLE } from 'src/@types/user';
import { useDocumentViewerStore } from './document-viewer';
import apiErrorHandler from 'src/utils/exceptions/api-error-handler';
import { LANGUAGE_CODE } from 'src/@types/languages';
import { popDialog } from 'src/utils/dialogs';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { Network } from '@capacitor/network';
import { getNetworkStatus } from 'src/utils/networkStatus';
import useLocalLogin from 'src/composables/useLocalLogin';
import { watch } from 'vue';
import { Preferences } from '@capacitor/preferences';
import checkIsMobileApp from 'src/utils/checkIsMobileApp';
import { SYNC_ENDPOINT_METHOD_TYPES } from 'src/utils/syncWhitelist';
import { useKnowledgeBaseStore } from './customer/TRM/knowledge-base';

enum NETWORK_CONNECTION_TYPES {
  WIFI = 'wifi',
  CELLULAR = 'cellular',
  NONE = 'none',
  UNKNOWN = 'unknown',
}

interface NETWORK_STATUS {
  connected: boolean;
  connectionType: NETWORK_CONNECTION_TYPES[keyof NETWORK_CONNECTION_TYPES];
}

export interface State {
  serverVersion: string;
  auth: AUTH | undefined;
  isNetworkConnected: boolean;
  currentUserEmail: string;
  isMobileNetwork: boolean;
  networkStatus: NETWORK_STATUS;
}

export const useCoreStore = defineStore('core', () => {
  const documentViewerStore = useDocumentViewerStore();
  const { productContext, productContextType } =
    storeToRefs(documentViewerStore);

  const knowledgeBaseStore = useKnowledgeBaseStore();
  const { userHasKBAccess } = storeToRefs(knowledgeBaseStore);

  const { t } = useI18n();
  const router = useRouter();

  const serverVersion = ref<string>('');
  const auth = ref<AUTH | undefined>(undefined);
  const isNetworkConnected = ref<boolean>(true);
  const currentUserEmail = ref<string>();
  const isMobileNetwork = ref<boolean>();
  const networkCheckIntervalId = ref<NodeJS.Timeout | null>(null);
  const DEV_network_override = ref<boolean>(false);

  const updateNetworkState = async () => {
    const status = await getNetworkStatus();
    // TODO figure out why this is not working for iOS
    // isNetworkConnected.value = status.isNetworkConnected;
    isMobileNetwork.value = status.isMobileNetwork;
  };

  const setupNetworkListeners = async () => {
    if (process.env.mode === 'capacitor') {
      // mobile app
      Network.addListener('networkStatusChange', updateNetworkState);
    } else {
      // browser
      window.addEventListener('online', updateNetworkState);
      window.addEventListener('offline', updateNetworkState);
    }
  };

  const startNetworkCheckPoll = (intervalSeconds: number) => {
    const fetchNetworkStatus = async () => {
      if (
        DEV_network_override.value &&
        process.env.NODE_ENV === 'development'
      ) {
        return;
      }

      try {
        await rocumentsApi.get('/test-network');

        if (isNetworkConnected.value !== true) {
          isNetworkConnected.value = true;
        }
      } catch (error) {
        // this prevents from value resetting and thus updating
        // slideable components such as offline header
        // which reacts every time this value is changed
        if (isNetworkConnected.value !== false) {
          isNetworkConnected.value = false;
        }
      }
    };

    fetchNetworkStatus();
    networkCheckIntervalId.value = setInterval(
      fetchNetworkStatus,
      intervalSeconds * 1000
    );
  };

  if (checkIsMobileApp()) {
    updateNetworkState();
    setupNetworkListeners();
    startNetworkCheckPoll(parseInt(process.env.NETWORK_CHECK_INTERVAL || '5'));
  }

  const getVersion = async () => {
    try {
      const response = await rocumentsApi.get('/system/version');
      serverVersion.value = response.data;
    } catch (err) {
      apiErrorHandler(err);
    }
  };

  const getAuth = async (): Promise<AUTH | undefined> => {
    try {
      auth.value = await useLocalLogin().handleUserAuth();
      return auth.value;
    } catch (error) {
      console.error(error);
      auth.value = undefined;
      apiErrorHandler(error);
    }
  };

  const logout = async (showPrompt = true) => {
    const logoutHandler = async () => {
      try {
        if (isNetworkConnected.value) {
          await rocumentsApi.get('/users/logout');
        }

        auth.value = undefined;
        router.push('/');
      } catch (error) {
        apiErrorHandler(error);
      }
    };

    if (showPrompt) {
      popDialog({
        title: t('auth.logoutTitle'),
        message: t('auth.promptMessage'),
        submit: t('buttons.ok'),
        cancel: t('buttons.cancel'),
      }).onOk(logoutHandler);
    } else {
      logoutHandler();
    }
  };

  // Getters
  const isLoggedIn = computed(() => !!auth.value);
  const userRole = computed(() => auth.value?.role);
  const userOrgType = computed(() => auth.value?.organisationType._type);
  const userIsOrgAdmin = computed(
    () => auth.value?.organisation.orgAdmin === auth.value?.user._id
  );
  const userIsAdmin = computed(
    () =>
      !!(
        auth.value?.role &&
        [ROLE.admin, ROLE.document_admin, ROLE.user_admin].includes(
          auth.value.role
        )
      )
  );
  const isLibraryEnabled = computed(() => {
    const libraryEnvironments = [
      'TRM',
      'TRM-UAT',
      'LOCAL',
      'DEV_AJ_LOCAL',
      'DEV_PL_LOCAL',
      'DEV',
    ];
    const libraryOrgTypes = [
      ORG_TYPE_TYPE.AuthorisedRepairer,
      ORG_TYPE_TYPE.Distributor,
      ORG_TYPE_TYPE.Manufacturer,
    ];
    if (
      auth.value &&
      libraryEnvironments.includes(process.env.CUSTOMER_ID) &&
      libraryOrgTypes.includes(
        auth.value.organisationType._type || userHasKBAccess.value
      )
    ) {
      return true;
    }
    return false;
  });
  const userDocLang = computed<LANGUAGE_CODE>(() => {
    return auth.value?.user?.preferences?.documentLang
      ? (auth.value?.user.preferences.documentLang.replace(
          /(.*-)(.*)/,
          (match, p1, p2) => `${p1}${p2.toUpperCase()}`
        ) as LANGUAGE_CODE)
      : LANGUAGE_CODE.en_gb;
  });
  const showSubscriptionHomeCard = computed(() => {
    return (
      auth.value?.organisationType.requiresSub &&
      auth.value.organisationType._type === ORG_TYPE_TYPE.Individual &&
      auth.value.organisationSubscriptions
        .filter((sub) =>
          [
            SUBSCRIPTION_STATE.Active,
            SUBSCRIPTION_STATE.PastDue,
            SUBSCRIPTION_STATE.Trialing,
            SUBSCRIPTION_STATE.Canceled,
          ].includes(sub.state)
        )
        .filter(
          (sub) =>
            !!sub.validTo &&
            endOfDay(new Date(sub.validTo)) >= endOfDay(new Date())
        ).length
    );
  });
  const userRequiresSub = computed(
    () => auth.value?.organisationType.requiresSub
  );
  const validSubscriptions = computed(() => {
    return (
      auth.value?.organisationSubscriptions
        .filter((sub) => {
          const start = new Date(sub.validFrom);
          const end = sub.validTo
            ? endOfDay(new Date(sub.validTo))
            : endOfDay(addYears(new Date(), 1));
          return isWithinInterval(new Date(), { start, end });
        })
        .filter((sub) => {
          if (userIsOrgAdmin.value || userOrgType.value !== ORG_TYPE_TYPE.IO) {
            return true;
          } else {
            return auth.value && sub.assignedTo.includes(auth.value?.user._id);
          }
        }) || []
    );
  });

  // TODO Move this to the BE and return context about subscription in the document list
  /**
   * Checks if the user has a valid subscription for the current product context and document.
   * @returns A boolean indicating if the user has a valid subscription.
   */
  const hasValidProductRelevantSubscription = computed(() => {
    if (!userRequiresSub.value) return true;

    // TODO does this need to take the docs list into account?
    // Get the list of documents that have been listed against the product
    // const documentMeta = currentDocument.value?.metadata;
    // Gets the users' current subscription list

    // filter the subscriptions based on the product context & the subscription restrictions
    const validProductSubscriptions = validSubscriptions.value?.filter(
      (subscription) => {
        const subTypeRestrictions = subscription.type.restrictions;
        // Handle subtype first
        if (
          !subTypeRestrictions.isSubscriptionTypeSerialRestricted &&
          !subTypeRestrictions.singleSerial &&
          !subTypeRestrictions.singleModelName
        ) {
          return true;
        }

        // Not restricted by serial
        if (!subscription.restrictions) {
          return true;
        }
        if (
          !subscription.restrictions.model &&
          (!subscription.restrictions.serial ||
            !subscription.restrictions.serial?.length)
        ) {
          return true;
        }

        // Subscription is restricted by serial
        if (
          subTypeRestrictions.isSubscriptionTypeSerialRestricted &&
          subscription.restrictions.serial?.length
        ) {
          if (
            subscription.restrictions.serial.every((restriction) =>
              [null, ''].includes(restriction.serial?.toUpperCase())
            )
          ) {
            // HACK Also not restricted - handling bad data
            return true;
          }
          if (
            subscription.restrictions.serial
              .map((serialRestrictions) =>
                serialRestrictions.serial?.toUpperCase()
              )
              .some((serial) =>
                [
                  productContext.value?.serialNumber?.toUpperCase(),
                  productContext.value?.serialNumberStub?.toUpperCase(),
                ].includes(serial)
              )
          ) {
            return true;
          } else {
            return false;
          }
        }
        // Subscription is restricted by model
        if (
          productContextType.value === 'model' &&
          !!subscription.restrictions.model
        ) {
          return subscription.restrictions.model === productContext.value?._id;
        }
      }
    );

    return !!validProductSubscriptions?.length;
  });

  const currentSubscriptionExpiresAt = computed(() => {
    const isIO = userOrgType.value !== ORG_TYPE_TYPE.IO;
    if (!userRequiresSub.value || !isIO) {
      return 0;
    }
    const counter =
      validSubscriptions.value &&
      validSubscriptions.value.map((sub) => {
        const subEnds = sub.validTo
          ? endOfDay(new Date(sub.validTo))
          : endOfDay(addYears(new Date(), 1));
        const subEndsTime = subEnds.getTime();
        const now = new Date();
        const nowTime = now.getTime();
        const day = 1000 * 60 * 60 * 24;
        const hour = 1000 * 60 * 60;
        if (addDays(now, 7) < subEnds) {
          return 800;
        }
        if (Math.floor((subEndsTime - nowTime) / day) === 7) {
          return 700;
        } else if (Math.floor((subEndsTime - nowTime) / day) === 6) {
          return 600;
        } else if (Math.floor((subEndsTime - nowTime) / day) === 5) {
          return 500;
        } else if (Math.floor((subEndsTime - nowTime) / day) === 4) {
          return 400;
        } else if (Math.floor((subEndsTime - nowTime) / day) === 3) {
          return 300;
        } else if (Math.floor((subEndsTime - nowTime) / day) === 2) {
          return 200;
        } else if (Math.floor((subEndsTime - nowTime) / day) === 1) {
          return 100;
        } else {
          return Math.floor((subEndsTime - nowTime) % hour) / hour;
        }
      });
    if (counter) {
      return isIO && counter[0] > 0 ? counter[0] : 0;
    }
    return 0;
  });

  interface CACHED_ENDPOINT {
    method: SYNC_ENDPOINT_METHOD_TYPES;
    url: string;
    payload: any;
    retryCount: number;
    isPermantlyFailed: boolean;
    isSuccessful: boolean;
  }

  const RETRY_COUNT = 10;
  const SYNC_DELAY = 200;
  const SYNC_BATCH_SIZE = 10;

  const apiCallHandler = async (
    endpt: CACHED_ENDPOINT,
    fn: () => Promise<void>
  ) => {
    const delay = (ms: any) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    await delay(SYNC_DELAY);

    const isNetworkConnectedAtCall = isNetworkConnected.value;

    try {
      if (isNetworkConnectedAtCall) {
        await fn();
        endpt.isSuccessful = true;
      }
    } catch (err) {
      if (endpt.retryCount > RETRY_COUNT) {
        endpt.isPermantlyFailed = true;
      }

      if (isNetworkConnectedAtCall) {
        endpt.retryCount++;
      }
    }
  };

  const sync = async () => {
    if (!checkIsMobileApp()) return;
    const endpointString = (await Preferences.get({ key: 'cachedEndpoints' }))
      .value;
    let doAnotherBatch = false;

    if (!endpointString) {
      return;
    }

    const endpointArray = JSON.parse(endpointString) as CACHED_ENDPOINT[];

    for (let index = 0; index < endpointArray.length; index++) {
      const endpoint = endpointArray[index];

      if (!isNetworkConnected.value) {
        // don't iterate over the rest of possibly large array if network is not connected
        break;
      }

      // if there are still left to sync
      if (index >= SYNC_BATCH_SIZE && endpointArray.length > SYNC_BATCH_SIZE) {
        doAnotherBatch = true;
        break;
      }

      switch (endpoint.method) {
        case 'post': {
          await apiCallHandler(endpoint, () =>
            rocumentsApi.post(endpoint.url, endpoint.payload)
          );
          break;
        }
        case 'patch': {
          await apiCallHandler(endpoint, () =>
            rocumentsApi.patch(endpoint.url, endpoint.payload)
          );
          break;
        }
        case 'delete': {
          await apiCallHandler(endpoint, () =>
            rocumentsApi.delete(endpoint.url, endpoint.payload)
          );
          break;
        }
      }
    }

    const permanentelyFailedEndpoints = [];
    const endpointTodoArray = endpointArray.filter((e) => {
      if (e.isPermantlyFailed) {
        permanentelyFailedEndpoints.push();
      }

      return !e.isPermantlyFailed && !e.isSuccessful;
    });

    // Update cached endpoints
    await Preferences.set({
      key: 'cachedEndpoints',
      value: JSON.stringify(endpointTodoArray),
    });

    if (
      (isNetworkConnected.value && endpointTodoArray.length) ||
      doAnotherBatch
    ) {
      await sync();
    }
  };

  watch(isNetworkConnected, (newValue) => {
    if (newValue == true) {
      sync();
    }
  });

  onMounted(() => {
    if (isNetworkConnected.value) {
      sync();
    }
  });

  return {
    isNetworkConnected,
    DEV_network_override,
    currentUserEmail,
    isMobileNetwork,
    serverVersion,
    auth,
    getVersion,
    getAuth,
    logout,
    isLoggedIn,
    userRole,
    userOrgType,
    userIsOrgAdmin,
    userIsAdmin,
    isLibraryEnabled,
    userDocLang,
    showSubscriptionHomeCard,
    userRequiresSub,
    validSubscriptions,
    hasValidProductRelevantSubscription,
    currentSubscriptionExpiresAt,
  };
});
